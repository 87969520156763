export default {
  superscription: 'Aktywność',
  title: 'Moje ogłoszenia',
  description: 'Przeglądaj wszystkie swoje ogłoszenia.',
  editAdsBtn: 'Edytuj',
  subheading: {
    subtitle: 'Lista ogłoszeń',
    descriptions: 'Twoje aktualne ogłoszenia.',
    noActiveAds: 'Brak ogłoszeń '
  },
  toasts: {
    delete: {
      success: {
        title: 'Sukces!',
        msg: 'Ogłoszenie zostało usunięte'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Ogłoszenie nie zostało usunięte'
      }
    }
  },
  addInfo: {
    title: 'Dodaj ogłoszenie',
    maxTitle: 'Masz 3 aktywne ogłoszenia',
    descriptions: 'Pierwszy krok do tego by otrzymać ofertę kontaktu od niani.',
    maxDescriptions: 'Zanim dodasz kolejne musisz usunąć jedno z obecnych',
    btnAdd: 'Dodaj ogłoszenie'
  },
  adsInfo: {
    announcement: 'Ogłoszenie',
    active: 'aktywne',
    inactive: 'nieaktywne',
    created: ', utworzono'
  },
  noAds: {
    title: 'Ojej ;(',
    description: 'Nie masz jeszcze dodanych ogłoszeń.',
    btnAds: 'Szukaj ogłoszenia'
  },
  update: {
    noPermissions: 'Nie masz uprawnień do edycji tego ogłoszenia.',
    description:
      'Zmiana statusu ogłoszenia na "opublikowane" powoduje, że ogłoszenie widoczne jest dla niań i mogą one wysyłać Ci zaproszenie do kontaktu w sprawie współpracy. Możesz również usunąć ogłoszenie z listy opublikowanych.',
    status: 'Status ogłoszenia',
    attrs: 'Cechy przypisane do ogłoszenia',
    kids: 'Profile dzieci przypisane do ogłoszenia',
    forms: {
      data: 'Dane podstawowe ogłoszenia',
      title: 'Tytuł ogłoszenia',
      description: 'Opis ogłoszenia',
      btn: {
        status: 'Zapisz status ogłoszenia',
        data: 'Zapisz dane ogłoszenia',
        attr: 'Zapisz cechy ogłoszenia',
        kids: 'Zapisz informacje o dzieciach'
      }
    },
    toasts: {
      status: {
        success: {
          title: 'Sukces!',
          msg: 'Status został pomyślnie zmieniony!'
        },
        fail: {
          title: 'Coś poszło nie tak',
          msg: 'Status nie został zmieniony'
        }
      },
      data: {
        success: {
          title: 'Sukces!',
          msg: 'Dane zostały pomyślnie zmienione!'
        },
        fail: {
          title: 'Coś poszło nie tak',
          msg: 'Dane nie zostały zmienione!'
        }
      },
      kids: {
        success: {
          title: 'Sukces!',
          msg: 'Dzieci zostały pomyślnie przypisane!'
        },
        fail: {
          title: 'Coś poszło nie tak',
          msg: 'Dzieci nie zostały przypisane!'
        }
      },
      ismorethen3: {
        title: 'Ups, za dużo ogłoszeń!',
        msg: 'Możesz mieć maksymalnie trzy aktywne ogłoszenia'
      },
      isfree: {
        title: 'Rozszerz możliwości swojego konta',
        msg: 'Tylko użytkownicy premium mogą publikować ogłoszenia'
      },
      premium: 'Przejdź na premium!',
      close: 'Zamknij'
    }
  },
  add: {
    title: 'Ogłoszenie',
    description:
      'Utworzenie nowego ogłoszenia i ustawienie mu statusu "aktywne" powoduje, że nianie mogę przyszyłać Ci oferty kontaktu w sprawie współpracy dla tego ogłoszenia.',
    superscription: 'Moje ogłoszenia',
    adTitle: 'Tytuł ogłoszenia',
    addAdBtn: 'Dodaj ogłoszenie i przejdź do dodania szczegółów',
    toastSuccess: {
      title: 'Sukces!',
      msg: 'Ogłoszenie zostało utworzone. W tej chwili jest nieopublikowane'
    },
    toastFail: {
      title: 'Coś poszło nie tak',
      msg: 'Ogłoszenie nie zostało utworzone'
    },
    adTitleRequired: 'Tytuł ogłoszenia jest wymagany'
  }
}
