import AbstractDB from '../AbstractDB'

export class Notifications extends AbstractDB {
  constructor() {
    super('notifications')
  }

  public readAll(limit = 10, page = 1) {
    return this.get(`?limit=${limit}&page=${page}`)
  }

  public readAllNew(limit = 10, page = 1) {
    return this.get(`all/new?limit=${limit}&page=${page}`)
  }

  public removeNotification(id: number) {
    return this.delete(`${id}`)
  }

  public readNotification(id: number) {
    return this.patch(`${id}`, {})
  }

  public readNewNotifications() {
    return this.get('count/new')
  }
}

const apiNotifications = new Notifications()
export default apiNotifications
