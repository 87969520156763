<template>
  <div class="min-w-[220px] pb-1">
    <div
      v-for="(section, ids) in sections"
      :key="ids"
      class="text-secondary-700"
    >
      <portal-menu-label>{{ section.title }}</portal-menu-label>
      <portal-menu-item
        v-for="(item, idx) in section.arr"
        :key="idx"
        size="sm"
        :item="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAuthStore } from '@stores/auth'
import PortalMenuItem from '@portal/PortalMenuItem'
import PortalMenuLabel from '@portal/PortalMenuLabel'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    PortalMenuItem,
    PortalMenuLabel
  },
  setup() {
    const { t } = useI18n()
    const useAuth = useAuthStore()
    const isLogged = useAuth.isAuthenticated
    const isParent = useAuth.isParent
    const username = useAuth.getUserFullname
    let list = []

    if (isParent) {
      list = [
        { title: t('navbar.profil'), route: { name: 'profil' }, icon: 'user' },
        {
          title: t('navbar.nannies'),
          route: { name: 'nannies' },
          icon: 'searchSolid'
        },
        {
          title: t('navbar.ads'),
          route: { name: 'announcments' },
          icon: 'mapMarkedAltSolid'
        }
      ]
    } else {
      list = [
        { title: t('navbar.profil'), route: { name: 'profil' }, icon: 'user' },
        {
          title: t('navbar.jobs'),
          route: { name: 'jobs' },
          icon: 'searchSolid'
        },
        {
          title: t('navbar.deals'),
          route: { name: 'deals' },
          icon: 'handshakeSolid'
        }
      ]
    }

    const sections = [{ title: t('sidebar.sections.main'), arr: list }]

    let activity = []

    activity = [
      {
        title: t('navbar.logout.title'),
        route: { name: 'authLogout' },
        icon: 'signOutAltSolid'
      }
    ]

    sections.push({ title: t('navbar.logout.label'), arr: activity })
    return {
      sections,
      isParent,
      isLogged,
      username
    }
  }
})
</script>
