<template>
  <div
    class="transit my-0.5 text-secondary-700"
    :class="{
      [`bg-gradient-to-r from-white to-${color}-500`]:
        (value === date.start && hov > date.start && !date.end) ||
        (date.start && date.end && value === date.start) ||
        (value === hov && hov < date.start),
      [`bg-gradient-to-l from-white to-${color}-500`]:
        value === date.end ||
        (hov < date.start && value === date.start && !date.end && hov) ||
        (value === hov && hov > date.start)
    }"
  >
    <div
      class="cursor-pointer justify-center py-1 px-1 text-center text-sm font-medium"
      :class="{
        'hover:rounded-full': value < date.start || value > date.end,
        'hover:bg-secondary-200':
          (value !== pickDate && !range) ||
          (value < date.start && range && hov !== value) ||
          (value > date.end && range && date.end),
        'rounded-full': value === hov,
        [`bg-${color}-600 text-${color}-100 rounded-full hover:bg-${color}-700`]:
          (value === pickDate && !range) ||
          (value === date.start && date.end) ||
          value === date.end,
        [`bg-${color}-600 text-${color}-100 rounded-full hover:bg-${color}-500`]:
          value === date.start && !date.end,
        [`bg-${color}-500 text-${color}-100 hover:rounded-none hover:bg-${color}-600`]:
          (value >= hov && value < date.start && hov) ||
          (value <= hov && value > date.start) ||
          (date.start < value && value < date.end)
      }"
      @click="$emit('pickDate', value)"
      @mouseenter="hover()"
    >
      <span class="focus:outline-none">
        {{ i }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormDateItemAtom',
  props: {
    i: {
      type: Number,
      required: true
    },
    value: {
      type: String,
      default: '',
      required: true
    },
    color: {
      type: String,
      default: 'primary',
      validator: (v: string) =>
        [
          'primary',
          'secondary',
          'success',
          'danger',
          'info',
          'warning'
        ].includes(v)
    },
    range: {
      type: Boolean
    },
    pickDate: {
      type: String,
      default: ''
    },
    date: {
      type: Object,
      default: () => ({})
    },
    hov: {
      type: String,
      default: ''
    }
  },
  emits: ['hover', 'pickDate'],
  setup(props, { emit }) {
    const hover = function () {
      const { date, value } = props
      const { start, end } = date
      if (start && !end) {
        emit('hover', value)
      }
    }
    return {
      hover
    }
  }
})
</script>
