<template>
  <div
    class="toasts pointer-events-none fixed inset-0 right-80 z-20 flex px-4 py-6 sm:p-6"
    :class="{
      ['flex-col']: vertical === 'top',
      ['flex-col-reverse']: vertical === 'bottom',
      ['items-start justify-start sm:items-start sm:justify-start']:
        horizontal === 'right',
      ['items-center justify-start sm:items-center sm:justify-start']:
        horizontal === 'center',
      ['items-end justify-start sm:items-end sm:justify-start']:
        horizontal === 'left'
    }"
  >
    <div class="flex w-full flex-col items-center sm:items-end">
      <toast
        v-for="t in toastsRows"
        :id="t.id"
        :key="t.id"
        :template="t.template"
        :title="t.title"
        :msg="t.msg"
        :duration="t.duration"
        :type="t.type"
        :avatar="t.avatar"
        :icon="t.icon"
        :accept="t.accept"
        :decline="t.decline"
        :position="vertical"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useToastStore } from '@stores/toast'
import Toast from '@molecules/Toast'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ToastsGroupMolecule',
  components: {
    Toast
  },
  props: {
    vertical: {
      type: String,
      default: 'top',
      validator: (v: string) => ['top', 'bottom'].includes(v)
    },
    horizontal: {
      type: String,
      default: 'left',
      validator: (v: string) => ['right', 'center', 'left'].includes(v)
    }
  },
  setup() {
    const toastStore = useToastStore()
    const toastsRows = computed(() => toastStore.all)
    return {
      toastsRows
    }
  }
})
</script>

<style lang="postcss" scoped>
@media (max-width: 1024px) {
  .toasts {
    left: 312px;
  }
}
</style>
