<template>
  <div class="w-full">Twoje konto premium wygasa za 7 dni.</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotificationAccount',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
})
</script>
