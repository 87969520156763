<template>
  <span
    data-test="badge-wrapper"
    class="inline-flex items-center font-medium"
    :class="{
      'rounded-full': rounded,
      'rounded-md': !rounded,
      [`bg-${color}-100 text-${color}-800`]: color && !outline,
      [`bg-white text-${color}-800 border-${color}-800 border`]: outline,
      [`bg-${colorBg}`]: colorBg,
      [`text-${colorText}`]: colorText,
      'px-1.5 py-0 text-xs': size === 'xs' && !buttonClose,
      'px-2.5 py-0.5 text-xs': size === 'sm' && !buttonClose,
      'px-3 py-0.5 text-sm': size === 'md' && !buttonClose,
      'px-3.5 py-1 text-sm': size === 'lg' && !buttonClose,
      'px-4 py-1 text-base': size === 'xl' && !buttonClose,
      'py-0 pr-1 pl-1.5 text-xs': size === 'xs' && buttonClose,
      'py-0.5 pr-1 pl-2 text-xs': size === 'sm' && buttonClose,
      'py-0.5 pr-1 pl-2.5 text-sm': size === 'md' && buttonClose,
      'py-0.5 pr-1.5 pl-3 text-base': size === 'lg' && buttonClose,
      'py-1 pr-2 pl-3.5 text-base': size === 'xl' && buttonClose
    }"
  >
    <svg
      v-if="dot || colorDot"
      class="-ml-0.5 mr-1.5 h-2 w-2"
      :class="{
        [`text-${color}-400`]: color,
        [`text-${colorDot}`]: colorDot
      }"
      fill="currentColor"
      viewBox="0 0 8 8"
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
    <span>
      <slot />
    </span>
    <button
      v-if="buttonClose"
      class="ml-1 inline-flex flex-shrink-0 items-center justify-center rounded-full focus:text-white focus:outline-none"
      :class="{
        [`text-${color}-500 hover:bg-${color}-200 hover:text-${color}600 focus:bg-${color}-500`]:
          color,
        'h-3 w-3': size === 'xs',
        'h-4 w-4': size === 'sm' || size === 'md' || size === 'lg',
        'h-5 w-5': size === 'xl'
      }"
      @click="closeBadge"
    >
      <icon name="timesCircleSolid" />
    </button>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'BadgeAtom',
  components: { Icon },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    colorBg: {
      type: String,
      default: ''
    },
    colorText: {
      type: String,
      default: ''
    },
    colorDot: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'sm',
      validator: (value: string) => {
        return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
      }
    },
    rounded: {
      type: Boolean
    },
    dot: {
      type: Boolean
    },
    outline: {
      type: Boolean
    },
    buttonClose: {
      type: Boolean
    }
  },
  emits: ['close'],
  setup(props, context) {
    const closeBadge = () => {
      context.emit('close')
    }
    return {
      closeBadge
    }
  }
})
</script>
