<template>
  <div
    class="focus-within:border-1 flex w-full appearance-none rounded-md border shadow-sm focus-within:rounded-md focus-within:outline-none focus-within:ring-1"
    :class="{
      'border-secondary-300 focus-within:border-primary-500 focus-within:ring-primary-500':
        !v.$error,
      'border border-danger-500 focus-within:border-danger-500 focus-within:ring-red-500':
        v.$error
    }"
  >
    <slot name="left" />
    <input
      ref="inputRef"
      type="text"
      :name="fieldName"
      :disabled="disabled"
      :required="required"
      :value="formattedValue"
      class="w-full rounded-md border-none placeholder-secondary-400 focus:border-transparent focus:outline-none focus:ring-0"
      :class="{
        'px-1 py-1 text-xs': size === 'xs' || fieldSize === 'xs',
        'px-2 py-1 text-sm': size === 'sm' || fieldSize === 'sm',
        'text-md px-3 py-2': size === 'md' || fieldSize === 'md',
        'px-4 py-3 text-lg': size === 'lg' || fieldSize === 'lg'
      }"
      @blur="v.$touch"
    />
    <slot name="right" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Validation from '@quarks/Validation'
import { useCurrencyInput, CurrencyInputOptions } from 'vue-currency-input'

export default defineComponent({
  name: 'AtomFormCurrency',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['xs', 'sm', 'md', 'lg'].includes(v)
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({
        locale: 'en',
        currency: 'USD',
        valueAsInteger: false,
        distractionFree: true,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      })
    }
  },
  setup(props) {
    const { formattedValue, inputRef } = useCurrencyInput(
      props.options as CurrencyInputOptions
    )
    const { v, fieldSize, fieldName } = Validation()

    return {
      v,
      fieldSize,
      fieldName,
      formattedValue,
      inputRef
    }
  }
})
</script>
