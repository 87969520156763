import axios from 'axios'

export class Axios {
  public async getHttp() {
    const httpApi = axios.create({
      baseURL: `${import.meta.env.VITE_API_SERVER}`
    })
    const token = localStorage.getItem('token')
    if (token) {
      httpApi.defaults.headers.common['x-auth-token'] = token
    }
    const refresh = localStorage.getItem('refresh')
    if (refresh) {
      httpApi.defaults.headers.common['x-refresh-token'] = refresh
    }
    httpApi.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded, multipart/form-data'
    httpApi.defaults.withCredentials = false
    httpApi.interceptors.request.use(
      (config) => {
        if (
          config.headers !== undefined &&
          token !== localStorage.getItem('token')
        ) {
          config.headers['x-auth-token'] = localStorage.getItem(
            'token'
          ) as string
          config.headers['x-refresh-token'] = localStorage.getItem(
            'refresh'
          ) as string
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    httpApi.interceptors.response.use(
      (res) => {
        return Promise.resolve(res)
      },
      async (error) => {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
          return false
        }
        return Promise.reject(error)
      }
    )
    return httpApi
  }
}
