<template>
  <div
    class="col-span-4 rounded-t-md border-x border-t border-gray-200 bg-white"
  >
    <div
      v-if="!notifications"
      class="border-b border-solid border-gray-200 px-4 py-2.5 text-[10px] font-light uppercase"
    >
      {{ $t('rooms.title') }}
    </div>
    <div>
      <template v-if="!!rooms">
        <portal-chat-item
          v-for="(i, index) of rooms"
          :key="`${index}${refresh}`"
          :item="i"
          :is-active="i.id === active"
          :is-owner="i.userid === owner"
          @pic-room="picRoom"
        />
      </template>
      <template v-else>
        Brak konwersacji
        <!-- todo style -->
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import PortalChatItem from '@portal/PortalChatItem'

import { Room } from '@db/Chats/interfaces'

export default defineComponent({
  components: {
    PortalChatItem
  },
  props: {
    active: {
      type: Number,
      required: true
    },
    owner: {
      type: Number,
      required: true
    },
    rooms: {
      type: Object,
      default: {} as Room[]
    },
    notifications: {
      type: Boolean,
      default: false
    }
  },
  emits: ['picRoom'],
  setup(props, { emit }) {
    const router = useRouter()
    const refresh = ref(0)
    const activeId = ref(props.active)
    const picRoom = (id: number) => {
      if (props.notifications) {
        router.push(`/inbox/${id}`)
      } else {
        activeId.value = id
        emit('picRoom', id)
      }
    }

    watch(
      () => ({ ...props.rooms }),
      () => {
        refresh.value++
      }
    )

    return {
      picRoom,
      refresh
    }
  }
})
</script>
