export default {
  superscription: 'Ustawienia',
  title: 'Filtry wyszukiwania',
  description: {
    parent:
      'Ustawienie filtrów wyszukiwania powoduje, że zapisujemy jakie parmetry wyszukiwania niani są dla Ciebie ważne. W każdej chwili możesz je zmodyfikiwać, lub zmienić podczas wyszukiwnia.',
    nanny:
      'Ustawienie filtrów wyszukiwania powoduje, że zapisujemy jakie parmetry wyszukiwania ogłoszeń są dla Ciebie ważne. W każdej chwili możesz je zmodyfikiwać, lub zmienić podczas wyszukiwnia.'
  },
  subtitle: 'Zdefiniuj filtry wyszukiwania',
  save: 'Zapisz filtry',
  update: {
    success: {
      title: 'Sukces!',
      msg: 'Filtry zostały zaktualizowane'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'Filtry nie zostały zaktualizowane'
    }
  }
}
