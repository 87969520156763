import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'

const routes = {
  path: '/blog',
  component: LayoutDefault,
  meta: {
    forLogged: false,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Blog'
      },
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'blog',
          meta: {
            forLogged: false,
            forNanny: true,
            forParent: true,
            title: 'Lista wpisów'
          },
          component: () => import('../views/blog/BlogIndex.vue')
        },
        {
          path: 'category/:id',
          name: 'category',
          component: () => import('../views/blog/BlogCategory.vue'),
          meta: {
            title: 'Lista wpisów w kategorii'
          }
        },
        {
          path: 'tag/:id',
          name: 'tag',
          component: () => import('../views/blog/BlogTag.vue'),
          meta: {
            title: 'Lista wpisów z tagiem'
          }
        },
        {
          path: ':id',
          name: 'post',
          component: () => import('../views/blog/BlogPost.vue'),
          meta: {
            title: 'BlogPost'
          }
        },
        {
          path: 'author/:id',
          name: 'author',
          component: () => import('../views/blog/BlogAuthor.vue'),
          meta: {
            title: 'Lista wpisów autora'
          }
        }
      ]
    }
  ]
}

export default routes
