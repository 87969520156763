<template>
  <div
    class="mb-6 flex flex-col rounded-lg border border-solid border-gray-200 bg-white"
  >
    <div
      class="w-full items-center justify-between py-2 px-4 md:inline-flex md:flex-row md:py-4"
    >
      <div class="flex flex-row p-0">
        <div class="mr-2 mb-0">
          <slot />
        </div>
        <div class="flex flex-col">
          <h5 class="mb-2 mt-1 text-sm font-bold uppercase text-secondary-700">
            {{ title }}
          </h5>
          <p class="mt-0 mb-4 text-sm font-normal text-gray-700">
            {{ text }}
          </p>
          <div>
            <router-link
              :to="link"
              class="inline-flex whitespace-nowrap rounded border-2 border-solid border-primary-600 bg-primary-600 py-2 px-5 text-center text-xs font-medium uppercase text-white no-underline hover:border-primary-500 hover:bg-primary-500 hover:text-white"
            >
              {{ linkText }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card3Cols',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    buttonColor: {
      type: String,
      default: 'secondary'
    }
  }
})
</script>
