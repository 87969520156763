<template>
  <div
    class="focus-within:border-1 flex w-full appearance-none rounded-md border shadow-sm focus-within:rounded-md focus-within:outline-none focus-within:ring-1"
    :class="{
      'border-secondary-300 focus-within:border-primary-500 focus-within:ring-primary-500':
        !v.$error && type !== 'color',
      'border border-danger-500 focus-within:border-danger-500 focus-within:ring-red-500':
        v.$error && type !== 'color'
    }"
  >
    <slot name="left" />
    <input
      v-model="model"
      v-bind="$attrs"
      :autocomplete="autocomplete"
      :name="fieldName"
      :id="fieldName"
      :type="type"
      :disabled="disabled"
      class="rounded-md border-none placeholder-secondary-400 focus:border-transparent focus:outline-none focus:ring-0"
      :class="{
        'w-full': type !== 'color',
        'px-1 py-1 text-xs':
          (size === 'xs' && type !== 'color') ||
          (fieldSize === 'xs' && type !== 'color'),
        'px-2 py-1 text-sm':
          (size === 'sm' && type !== 'color') ||
          (fieldSize === 'sm' && type !== 'color'),
        'text-md px-3 py-2':
          (size === 'md' && type !== 'color') ||
          (fieldSize === 'md' && type !== 'color'),
        'px-4 py-3 text-lg':
          (size === 'lg' && type !== 'color') ||
          (fieldSize === 'lg' && type !== 'color'),
        'h-4 w-4':
          (size === 'xs' && type === 'color' && !fieldSize) ||
          (fieldSize === 'xs' && type === 'color'),
        'h-5 w-5':
          (size === 'sm' && type === 'color' && !fieldSize) ||
          (fieldSize === 'sm' && type === 'color'),
        'h-6 w-6':
          (size === 'md' && type === 'color' && !fieldSize) ||
          (fieldSize === 'md' && type === 'color'),
        'h-7 w-7':
          (size === 'lg' && type === 'color' && !fieldSize) ||
          (fieldSize === 'lg' && type === 'color')
      }"
      @blur="v.$touch"
      @keypress="checkInputPatternMatch"
    />
    <slot name="right" />
  </div>
</template>

<script lang="ts">
import Validation from '@quarks/Validation'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FormTextAtom',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'off',
      validator: (v: string) => ['on', 'off'].includes(v)
    },
    type: {
      type: String,
      default: 'text'
    },
    pattern: {
      type: RegExp,
      default: () => /./
    },
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v)
    },
    disabled: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { v, fieldSize, fieldName, useValidation } = Validation()
    const model = useValidation(props, emit)

    const checkInputPatternMatch = (event: KeyboardEvent) => {
      const { pattern } = props
      const regexp = new RegExp(pattern)
      const keyPressed = event.key
      const isMatch = regexp.test(keyPressed)
      if (!isMatch) {
        event.preventDefault()
      }
    }

    return {
      model,
      v,
      fieldSize,
      fieldName,
      checkInputPatternMatch
    }
  }
})
</script>
<style lang="postcss" scoped>
input[type='color']::-webkit-color-swatch,
input[type='color']::-webkit-color-swatch-wrapper {
  @apply rounded-md border-none p-0;
}
</style>
