export default {
  hello: 'Dzień dobry,',
  description: {
    title: 'Kilka słów o sobie',
    placeholder:
      'Napisz kilka słów o sobie. W Znanej Niani eksponujemy wyjątkowość, aby najlepiej łączyć Rodziców z Nianiami!',
    instruction:
      'Treść opisu zostanie zapisana po wypełnieniu pola oraz kliknięciu poza obszar zapisu',
    warning: 'Opis może zawierać maksymalnie 3000 znaków'
  },
  toast: {
    tag: {
      success: {
        title: 'Sukces!',
        msg: 'Lista tagów została zaktualizowana.'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Lista tagów nie została zaktualizowana.'
      },
      exist: {
        title: 'Nie można dodać',
        msg: 'Tag jest już na liście.'
      }
    },
    description: {
      success: {
        title: 'Sukces!',
        msg: 'Opis został zaktualizowany.'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Opis nie został zaktualizowany.'
      }
    }
  },
  hashtag: {
    titleNanny: 'Moje aktywności',
    titleParent: 'Szukane aktywności niani',
    descNanny: 'Wybierz aktywności, które najlepiej Cię opisują',
    descParent: 'Wybierz aktywności, których szukasz u niani',
    btn: 'Wybierz',
    more: 'więcej'
  },
  profileCards: {
    settings: {
      title: 'Ustawienia konta',
      description:
        'Zarządzaj ustawieniami swojego konta, danymi osbowymi i informacjami profilowymi',
      link: 'Przejdź do ustawień konta'
    },
    kids: {
      title: 'Moje dzieci',
      description: 'Zarządzaj informacjami o swoich dzieciach',
      link: 'Przejdź do listy dzieci'
    },
    ads: {
      title: 'Moje ogłoszenia',
      description:
        'Zarządzaj ogłoszeniami i przeglądaj informacje powiązane z nimi',
      link: 'Przejdź do ustawień ogłoszeń'
    },
    deals: {
      title: 'Znane nianie',
      description: 'Przeglądaj i zarządzaj listą rozpoczętych współprac',
      link: 'Przejdź do znanych niań'
    },
    attrs: {
      title: 'Cechy i umiejętności',
      description: 'Zarządzaj informacjami o moich umiejętnościach i cechach',
      link: 'Przejdź do ustawień cech profilu'
    },
    worktime: {
      title: 'Moja dostępność',
      description: 'Przeglądaj i zarządzaj swoją dostępnością',
      link: 'Przejdź do dostępności'
    },
    cooperations: {
      title: 'Moje współprace',
      description: 'Przeglądaj i zarządzaj listą rozpoczętych współprac',
      link: 'Przejdź do listy współprac'
    }
  },
  card: {
    title: 'Potrzebujesz pomocy?',
    description:
      'Jesteśmy do Twojej dyspozycji i pomożemy Ci jeśli potrzebujesz dodatkowych informacji, lub masz problemy z obsługą.',
    btn: 'Skontaktuj się'
  }
}
