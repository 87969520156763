export default {
  step1: {
    title: 'Twoje pierwsze logowanie',
    description:
      'Dziękujemy że jesteś z Nami. Wybierz rodzaj konta i zupełnij wszystkie dane niezbędne do korzystania z naszego serwisu.',
    account: 'Rodzaj konta',
    gender: 'Płeć',
    save: 'Zapisz i przejdź dalej',
    nanny: 'Niania',
    parent: 'Rodzic',
    woman: 'Kobieta',
    man: 'Mężczyzna',
    firstName: 'Imię',
    lastName: 'Nazwisko'
  },
  step2: {
    parent: {
      title: 'Konto rodzica',
      description:
        'Wypełnij dane kontaktowe i zacznij korzystać z naszego serwisu. Prosimy o podanie samej nazwy ulicy (bez skrótów, np. "ul.", "os.").'
    },
    nanny: {
      title: 'Konto niani',
      description:
        'Wypełnij dane kontaktowe i datę urodzenia, a następnie zacznij korzystać z naszego serwisu'
    },
    save: 'Zapisz',
    street: 'Ulica',
    house: 'Nr domu',
    local: 'Nr lokalu',
    city: 'Miejscowość (poczta)',
    zipCode: 'Kod pocztowy',
    telephone: 'Nr telefonu',
    birth: 'Data urodzenia'
  },
  validation: {
    required: 'To pole jest wymagane',
    minlength: 'To pole musi mieć minimalanie {length} znaki',
    maxlength: 'To pole może mieć maksymalnie {length} znaki'
  }
}
