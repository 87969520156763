<template>
  <div>
    <form-date-header
      v-if="!displayMonthCalendar"
      :text="`${state.now.locale(locale).format('MMM YYYY')}`"
      outline
      @previous="changeMonth(-1)"
      @next="changeMonth(1)"
      @date="displayMonthCalendar = !displayMonthCalendar"
    />
    <div v-if="displayMonthCalendar" class="w-48 rounded-md pb-1">
      <form-date-header
        :text="
          monthCalendar
            ? `${state.now.year()}`
            : `${state.now.year() - 5} - ${state.now.year() + 6}`
        "
        outline
        :locale="locale"
        @previous="changeYear(-1)"
        @next="changeYear(1)"
        @date="monthCalendar = !monthCalendar"
      />
      <div
        v-if="monthCalendar"
        class="grid select-none grid-cols-3 text-center"
        data-test="monthCalendar"
      >
        <div v-for="(i, index) in 12" :key="'months' + i">
          <button
            class="mb-2 rounded-md py-1 px-2 text-sm font-medium text-secondary-800 hover:bg-secondary-200"
            :class="{
              'bg-secondary-300': i === state.now.month
            }"
            @click="getMonth(i), (displayMonthCalendar = !displayMonthCalendar)"
          >
            {{ setDate(index, 'months') }}
          </button>
        </div>
      </div>
      <div
        v-else
        class="grid select-none grid-cols-3 text-center"
        data-test="yearCalendar"
      >
        <div
          v-for="i in yearCalendar(state.now.year() - 5, state.now.year() + 7)"
          :key="'years' + i"
        >
          <button
            class="mb-2 rounded-md py-1 px-2 text-sm font-medium text-secondary-800 hover:bg-secondary-200"
            data-test="year"
            :class="{
              'bg-secondary-300': i === state.now.year
            }"
            @click="setDate(i, 'years'), (monthCalendar = !monthCalendar)"
          >
            {{ i }}
          </button>
        </div>
      </div>
    </div>
    <div v-else class="grid w-48 grid-cols-7 pb-1 transition-all duration-100">
      <div
        v-for="(i, index) in 7"
        :key="'dayName' + i"
        class="justify-center text-center text-sm text-secondary-400"
        data-test="dayName"
      >
        {{ setDate(index, 'daysNames') }}
      </div>
      <div
        v-for="(i, index) in state.now.daysInMonth()"
        :key="index"
        :class="{
          [`col-start-${state.firstDay}`]: index === 0
        }"
      >
        <form-date-item
          :i="i"
          :range="range"
          :color="color"
          :pick-date="state.pickDate"
          :date="{ start: state.start, end: state.end }"
          :value="setDate(index, 'numberOfDays')"
          :hov="state.hov"
          data-test="day"
          @pick-date="getDate($event)"
          @hover="state.hov = $event"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, reactive, ref, inject } from 'vue'
import FormDateItem from '@atoms/Form/FormDateItem'
import FormDateHeader from '@molecules/Form/FormDateHeader'
import 'dayjs/locale/pl.js'

export default defineComponent({
  name: 'FormDateOrganism',
  components: {
    FormDateHeader,
    FormDateItem
  },
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (v: string) =>
        [
          'primary',
          'secondary',
          'success',
          'danger',
          'info',
          'warning'
        ].includes(v)
    },
    range: {
      type: Boolean
    },
    locale: {
      type: String,
      default: 'en'
    }
  },
  emits: ['start', 'end', 'date'],
  setup(props, { emit }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dayJS = inject('dayjs') as any
    const displayMonthCalendar = ref(false)
    const monthCalendar = ref(true)
    const state = reactive({
      start: '',
      end: '',
      hov: '',
      now: dayJS().locale(props.locale),
      firstDay: '',
      pickDate: ''
    })

    watch(
      () => state.start,
      (val) => emit('start', val)
    )
    watch(
      () => state.end,
      (val) => emit('end', val)
    )
    // wybranie daty lub zakresu
    const getDate = function (value: string) {
      if (props.range) {
        if (state.start !== value && !state.start) {
          state.start = value
        } else if (state.start === value && !state.end) {
          state.start = ''
          state.hov = ''
        } else if (state.start > value && !state.end) {
          state.end = state.start
          state.start = value
        } else if (state.start > value && state.end) {
          state.hov = ''
          state.start = value
        } else if (state.start < value && value > state.end) {
          state.end = value
          state.hov = ''
          value = ''
        } else if (value === state.start && state.end) {
          state.hov = state.start
          state.start = state.end
          state.end = ''
        } else if (value === state.end) {
          state.hov = state.end
          state.end = ''
        } else if (state.start < value && value < state.end) {
          state.start = value
          state.end = ''
          state.hov = ''
        }
      } else {
        if (state.pickDate !== value) {
          state.pickDate = value
          state.now = dayJS(`${value}`)
          emit('date', state.now.format('YYYY-MM-DD'))
        } else {
          state.pickDate = ''
        }
      }
    }
    // ustawienie pierwszego dnia miesiąca
    const firstDayOfMonth = function () {
      const firstDay = dayJS(`${state.now}`).startOf('month').format('d')
      firstDay === '0' ? (state.firstDay = '7') : (state.firstDay = firstDay)
    }
    // zmiana miesiąca
    const changeMonth = function (quantity: number) {
      state.now = state.now.add(quantity, 'month')
      firstDayOfMonth()
    }
    // zmiana roku
    const changeYear = function (quantity: number) {
      if (!monthCalendar.value) {
        quantity = quantity * 10
      }
      state.now = state.now.add(quantity, 'year')
    }
    // wybranie miesiąca
    const getMonth = function (i: number) {
      const monthValue = i < 10 ? `0${i}` : `${i}`
      state.now = dayJS(
        `${state.now.year()}-${monthValue}-${state.now.format('DD')}`
      ).locale(props.locale)
      firstDayOfMonth()
    }
    // ustalenie daty
    const setDate = function (i: number, type: string) {
      switch (type) {
        case 'daysNames':
          return dayJS('1900-01-01')
            .locale(props.locale)
            .add(i, 'day')
            .format('dd')
        case 'numberOfDays':
          return dayJS(`${state.now.startOf('month')}`)
            .add(i, 'day')
            .format('YYYY-MM-DD')
        case 'months':
          return dayJS(`${state.now.startOf('year')}`)
            .locale(props.locale)
            .add(i, 'month')
            .format('MMM')
        case 'years':
          state.now = dayJS(`${i}-${state.now.format('MM-DD')}`)
      }
    }
    // pobranie dekady
    const yearCalendar = function (x: number, y: number) {
      const numbers = []
      for (let i = x; i < y; i++) {
        numbers.push(i)
      }
      return numbers
    }
    firstDayOfMonth()

    return {
      displayMonthCalendar,
      monthCalendar,
      state,
      getDate,
      getMonth,
      changeMonth,
      changeYear,
      setDate,
      yearCalendar
    }
  }
})
</script>

<style scoped>
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
</style>
