export default {
  main: {
    slogan: 'to innowacyjna platforma dla rodziców i opiekunek dla dzieci',
    fb: 'Śledź nas na Facebook',
    end: 'i',
    insta: 'Instagramie',
    andread: 'oraz czytaj nasz',
    blog: 'blog'
  },
  aboutapp: {
    title: 'Znana Niania',
    link1: 'O nas',
    link2: 'Współpraca',
    link3: 'Kontakt',
    link4: 'FAQ'
  },
  fornanny: {
    title: 'Dla Niani',
    link1: 'Jak to działa',
    link2: 'Członkostwo',
    link3: 'Blog'
  },
  forparent: {
    title: 'Dla Rodzica',
    link1: 'Jak to działa',
    link2: 'Członkostwo',
    link3: 'Blog'
  },
  needinfo: 'Potrzebujesz informacji? Zadzwoń',
  bottommenu: {
    link1: 'Blog',
    link2: 'Polityka prywatności',
    link3: 'Regulamin'
  },
  copy: 'Wszystkie prawa zastrzeżone'
}
