export default {
  superscription: 'Ustawienia',
  title: 'Dostępność',
  description: 'Określ, kiedy możesz pracować',
  myhours: 'Moje godziny dostępności',
  addhours: 'Dodaj godziny pracy',
  nohours: 'Nie masz jeszcze dodanych żadnych godzin pracy',
  expiration: {
    title: 'Data wygaśnięcia',
    description:
      'Podaj datę do której aktualne będą Twoje ustawienie godzin dostępności. Obecnie jest to {date}.',
    descriptionNoDate: 'Obecnie nie ma ustawionej daty wygaśnięcia.'
  },
  save: 'Zapisz',
  monday: 'Poniedziałek',
  tuesday: 'Wtorek',
  wednesday: 'Środa',
  thursday: 'Czwartek',
  friday: 'Piątek',
  saturday: 'Sobota',
  sunday: 'Niedziela',
  pickDay: 'Wybierz dzień:',
  pickHourStart: 'Od godziny:',
  pickHourEnd: 'Do godziny:',
  toast: {
    addhours: {
      title: 'Zmieniono czas pracy',
      msg: 'Godziny pracy zostały dodane'
    },
    endbeforestart: {
      title: 'Uwaga',
      msg: 'Godzina rozpoczęcia musi być wcześniejsza, niż godzina zakończenia'
    },
    remove: {
      title: 'Zmieniono czas pracy',
      msg: 'Godziny pracy zostały usunięte'
    },
    samehours: {
      title: 'Uwaga',
      msg: 'Te godziny pracy pokrywają się z dodanymi wcześniej'
    },
    adddate: {
      title: 'Sukces!',
      msg: 'Data wygaśnięcia została zmieniona.'
    },
    noadddate: {
      title: 'Uwaga',
      msg: 'Najpierw należy uzupełnić godziny pracy.'
    },
    invalidDate: {
      title: 'Uwaga',
      msg: 'Nie można ustawić daty, która już minęła.'
    }
  }
}
