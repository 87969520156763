import LayoutDefault from '../layouts/LayoutBase.vue'
import OnboardingIndex from '../views/onboarding/OnboardingIndex.vue'
const routes = {
  path: '/onboarding',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: false,
    forParent: false
  },
  children: [
    {
      path: '',
      name: 'onboarding',
      meta: {
        title: 'Pierwsze logowanie'
      },
      component: OnboardingIndex
    }
  ]
}

export default routes
