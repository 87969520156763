const routes = {
  path: '/auth/logout',
  component: () => import('../layouts/LayoutLoading.vue'),
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      name: 'authLogout',
      component: () => import('../views/auth/AuthLogout.vue')
    }
  ]
}

export default routes
