<template>
  <div class="w-full">
    Twoje ogłoszenie
    <span class="font-semibold">{{ data.notification_item_title }}</span>
    już wygasło. Zmień status ogłoszenia aby znów było aktywne.
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotificationAds',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
})
</script>
