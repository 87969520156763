<template>
  <div
    class="fixed z-20 h-16 w-screen border-b border-gray-200 bg-white lg:h-20"
  >
    <div class="mx-auto h-full px-4 lg:max-w-7xl lg:px-6">
      <div
        class="flex h-16 w-full justify-between lg:grid lg:h-20 lg:grid-cols-12 lg:gap-x-3"
      >
        <div class="flex items-center lg:col-span-3">
          <button
            class="focus:ring-none mr-2 inline-flex h-12 w-12 items-center justify-center rounded-full p-2 text-secondary-400 hover:bg-gray-100 hover:text-secondary-500 focus:outline-none lg:hidden"
            aria-expanded="false"
            @click="sidenav.change()"
          >
            <icon name="bars" size="7" />
          </button>
          <router-link to="/">
            <logo size="md" :slogan="true" />
          </router-link>
        </div>
        <div
          class="hidden lg:col-span-6 lg:flex lg:items-center lg:justify-around"
        >
          <div class="mt-2 hidden grid-flow-col md:gap-x-4 lg:grid">
            <router-link
              v-for="n in list"
              :key="n.title"
              :to="`/${n.dest}`"
              class="inline-flex overflow-hidden text-sm font-bold uppercase text-secondary-500 hover:text-secondary-800"
            >
              <icon
                v-if="n.icon"
                class="mr-1 mt-0.5"
                :name="`${n.icon}`"
                size="4"
              />{{ n.title }}
            </router-link>
          </div>
        </div>
        <div
          v-if="!isLogged"
          class="col-span-5 flex items-center justify-end lg:col-span-3"
        >
          <router-link to="/auth/register">
            <btn
              color="secondary"
              :text="$t('navbar.nologged.register')"
              size="sm"
              class="mr-2"
              outline
            />
          </router-link>
          <router-link to="/auth">
            <btn
              color="secondary"
              :text="$t('navbar.nologged.login')"
              size="sm"
            />
          </router-link>
        </div>
        <div v-else class="flex items-center justify-end lg:col-span-3">
          <dropdown
            v-if="inboxCounter > 0"
            :key="inboxCounter"
            :display="state.showInbox"
            placement="bottom"
          >
            <portal-navbar-btn
              ref="menuInboxRef"
              :key="inboxCounter"
              :counter="inboxCounter"
              icon="comments"
              @click="clickInbox()"
            />
            <template #content>
              <portal-navbar-inbox
                :key="inboxCounter"
                :show="state.showInbox"
              />
            </template>
          </dropdown>
          <router-link v-else :to="roomId ? `/inbox/${roomId}` : '/inbox'">
            <portal-navbar-btn :counter="navbar.getInbox" icon="comments" />
          </router-link>
          <div :key="navbar.getNotifications">
            <dropdown
              v-if="navbar.getNotifications > 0"
              :display="state.showNotifications"
              placement="bottom"
            >
              <portal-navbar-btn
                ref="menuNotificationsRef"
                :counter="navbar.getNotifications"
                icon="bell"
                class="ml-1 mr-2"
                @click="clickNotifications()"
              />
              <template #content>
                <portal-navbar-notifications
                  :show="state.showNotifications"
                  @read="readNotification"
                />
              </template>
            </dropdown>
            <router-link v-else to="/notifications">
              <portal-navbar-btn
                :counter="notificationsCounter"
                icon="bell"
                class="ml-1 mr-2"
              />
            </router-link>
          </div>
          <dropdown :display="state.showProfilMenu" placement="bottom">
            <avatar
              :key="useAuth.getAvatar"
              ref="menuProfilRef"
              bg="primary"
              class="cursor-pointer"
              :path="useAuth.getAvatar"
              :alt="useAuth.getUserFullname"
              @click="clickMenu()"
            />
            <template #content>
              <portal-navbar-profilmenu />
            </template>
          </dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  computed
} from 'vue'
import Logo from '@atoms/Logo'
import Btn from '@atoms/Btn'
import Icon from '@atoms/Icon'
import Avatar from '@atoms/Avatar'
import Dropdown from '@molecules/Dropdown'
import PortalNavbarBtn from '@portal/PortalNavbarBtn'
import PortalNavbarProfilmenu from '@portal/PortalNavbarProfilmenu'
import PortalNavbarInbox from '@portal/PortalNavbarInbox'
import PortalNavbarNotifications from '@portal/PortalNavbarNotifications'
import { useAuthStore } from '@stores/auth'
import { useSidenavStore } from '@stores/sidenav'
import { useNavbarStore } from '@stores/navbar'
import { useI18n } from 'vue-i18n'
import { onClickOutside } from '@vueuse/core'
import apiChats from '@db/Chats'
import { Room } from '@db/Chats/interfaces'
import apiNotifications from '@db/Notifications'

export default defineComponent({
  components: {
    Logo,
    Btn,
    Icon,
    Avatar,
    PortalNavbarBtn,
    PortalNavbarProfilmenu,
    PortalNavbarInbox,
    PortalNavbarNotifications,
    Dropdown
  },
  setup() {
    const { t } = useI18n()
    const useAuth = useAuthStore()
    const isLogged = useAuth.isAuthenticated
    const isParent = useAuth.isParent
    const isNanny = useAuth.isNanny
    const ownerId = useAuth.getUser.id
    const isProfiled = isNanny || isParent
    const roomId = ref('')
    const sidenav = useSidenavStore()
    const navbar = useNavbarStore()
    const inboxCounter = computed(() => navbar.getInbox)
    const notificationsCounter = computed(() => navbar.getNotifications)
    const menuProfilRef = ref(null)
    const menuInboxRef = ref(null)
    const menuNotificationsRef = ref(null)
    const state = reactive({
      showProfilMenu: false,
      showInbox: false,
      showNotifications: false
    })
    let list = []
    if (isLogged) {
      if (isParent) {
        list = [
          { title: t('navbar.profil'), icon: 'user', dest: 'profil' },
          { title: t('navbar.nannies'), icon: 'searchSolid', dest: 'nannies' },
          {
            title: t('navbar.ads'),
            icon: 'mapMarkedAltSolid',
            dest: 'announcments'
          }
        ]
      } else {
        list = [
          { title: t('navbar.profil'), icon: 'user', dest: 'profil' },
          { title: t('navbar.jobs'), icon: 'searchSolid', dest: 'jobs' },
          { title: t('navbar.deals'), icon: 'handshakeSolid', dest: 'deals' }
        ]
      }
    } else {
      list = [
        {
          title: t('navbar.nologged.forparent'),
          icon: false,
          dest: 'for-parent'
        },
        {
          title: t('navbar.nologged.fornanny'),
          icon: false,
          dest: 'for-nanny'
        }
      ]
    }

    const clickMenu = () => {
      state.showProfilMenu = !state.showProfilMenu
    }
    const clickInbox = () => {
      state.showInbox = !state.showInbox
    }
    const clickNotifications = () => {
      state.showNotifications = !state.showNotifications
    }
    onClickOutside(menuProfilRef, () => (state.showProfilMenu = false))
    onClickOutside(menuInboxRef, () => (state.showInbox = false))
    onClickOutside(
      menuNotificationsRef,
      () => (state.showNotifications = false)
    )
    const getUnreadNotifications = async () => {
      const count = await apiNotifications.readNewNotifications()
      navbar.setNotifications(count.data.count)
    }
    const getUnreadConversations = async () => {
      const res = await getConversations()
      const data = res?.data.rows as Room[]
      navbar.setInbox(
        data?.filter((x) => x.sender_id !== ownerId && x.message_is_read === 2)
          .length
      )
    }
    const getConversations = async () => {
      try {
        const res = await apiChats.readAll()
        roomId.value = res.data.rows[0]?.id ?? ''
        return res
      } catch {
        console.log('error getConversations')
      }
    }

    const connection = ref(null as unknown as WebSocket)
    const runWebsocketServer = () => {
      connection.value = new WebSocket(import.meta.env.VITE_WEBSOCKET_SERVER)

      connection.value.onmessage = async () => {
        await getConversations()
        await getUnreadConversations()
        await getUnreadNotifications()
      }
      connection.value.onclose = () => {
        setTimeout(runWebsocketServer, 100)
      }
    }
    if (isLogged) {
      runWebsocketServer()
    }

    const readNotification = () => {
      const d = navbar.getNotifications
      navbar.setNotifications(d > 0 ? d - 1 : d)
    }

    onMounted(async () => {
      if (isLogged && isProfiled) {
        navbar.$state.connection = connection.value
        await getConversations()
        await getUnreadConversations()
        await getUnreadNotifications()
      }
    })
    onBeforeUnmount(() => {
      navbar.setNotifications(0)
      navbar.setInbox(0)
    })

    return {
      useAuth,
      sidenav,
      isLogged,
      list,
      state,
      clickMenu,
      clickInbox,
      clickNotifications,
      menuProfilRef,
      menuInboxRef,
      menuNotificationsRef,
      navbar,
      roomId,
      inboxCounter,
      notificationsCounter,
      getUnreadConversations,
      readNotification
    }
  }
})
</script>
