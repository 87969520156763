import AbstractDB from '../AbstractDB'

export class Chats extends AbstractDB {
  constructor() {
    super('rooms')
  }

  public readAll(limit = 10, page = 1) {
    return this.get(`all?limit=${limit}&page=${page}`)
  }

  public readAllNew(limit = 5, page = 1) {
    return this.get(`all/new?limit=${limit}&page=${page}`)
  }

  public readConversation(query: string) {
    return this.get(`${query}`)
  }

  public archiveRoom(id: number) {
    return this.patch(`archive/${id}`, {})
  }

  public sendMsg(id: number, data: Record<string, unknown>) {
    return this.post(`${id}`, data)
  }

  public readMessages(id: number) {
    return this.patch(`${id}`, {})
  }

  public createRoom(id: number, data: Record<string, unknown>) {
    return this.post(`create/${id}`, data)
  }
}

const apiChats = new Chats()
export default apiChats
