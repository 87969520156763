import { defineStore } from 'pinia'

export const useOverlayStore = defineStore('overlay', {
  state: () => ({
    isOverlay: false,
    showTemplate: false,
    template: 'default'
  }),
  getters: {
    getIsOverlay(state) {
      return state.isOverlay
    },
    getShowTemplate(state) {
      return state.showTemplate
    },
    getTemplate(state) {
      return state.template
    }
  },
  actions: {
    show(val: string) {
      this.template = val
      this.showTemplate = true
      this.isOverlay = true
    },
    updateShow(val: boolean) {
      this.isOverlay = val
    },
    updateShowTemplate(val: boolean) {
      this.showTemplate = val
    },
    updateTemplate(val: string) {
      this.template = val
    },
    close() {
      this.showTemplate = false
      this.isOverlay = false
    }
  }
})
