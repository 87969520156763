<template>
  <div class="flex h-screen overflow-x-hidden">
    <div class="relative flex flex-1 flex-col overflow-x-hidden bg-gray-100">
      <portal-navbar />
      <div
        class="scrollarea mt-16 flex h-full grow flex-col overflow-y-auto overflow-x-hidden lg:mt-20"
      >
        <div id="top" class="grow">
          <router-view />
        </div>
        <portal-footer class="z-0" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onUpdated } from 'vue'
import PortalNavbar from '@portal/PortalNavbar'
import PortalFooter from '@portal/PortalFooter'

export default defineComponent({
  components: {
    PortalNavbar,
    PortalFooter
  },
  setup() {
    onUpdated(() => {
      const d = document.getElementById('top')
      d?.scrollIntoView()
    })
  }
})
</script>

<style scoped>
.scrollarea::-webkit-scrollbar {
  position: absolute;
  width: 6px;
}
.scrollarea::-webkit-scrollbar-thumb {
  background-color: rgb(214, 211, 209, 1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
</style>
