<template>
  <div class="mt-5 flex">
    <div class="flex-none justify-items-center">
      <slot />
    </div>
    <div
      class="flex flex-grow"
      :class="{
        'flex-row justify-end': template === 'right',
        'flex-row-reverse justify-end': template === 'left',
        'sm:grid sm:grid-flow-row-dense sm:grid-cols-1': template === 'single',
        'sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-2':
          template === 'double'
      }"
    >
      <btn
        v-if="decline.length > 0"
        :disabled="declineDisabled"
        :outline="true"
        :color="type"
        :text="decline"
        :size="size"
        class="mr-3"
        :class="{
          'justify-center': template === 'double' || template === 'single',
          'ml-3': template === 'left'
        }"
        @click="$emit('decline')"
      />
      <btn
        :disabled="acceptDisabled"
        :text="accept"
        :size="size"
        :color="type"
        :class="{
          'justify-center': template === 'double' || template === 'single'
        }"
        @click="$emit('accept')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Btn from '@atoms/Btn'

export default defineComponent({
  name: 'ModalFooterMolecule',
  components: {
    Btn
  },
  props: {
    template: {
      type: String,
      default: 'right',
      validator: (value: string) => {
        return ['single', 'double', 'right', 'left'].indexOf(value) !== -1
      }
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value: string) => {
        return (
          ['primary', 'info', 'danger', 'warning', 'success'].indexOf(value) !==
          -1
        )
      }
    },
    accept: {
      type: String,
      required: true
    },
    acceptDisabled: {
      type: Boolean,
      default: false
    },
    declineDisabled: {
      type: Boolean,
      default: false
    },
    decline: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator: (value: string) => {
        return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
      }
    }
  },
  emits: ['accept', 'decline']
})
</script>
