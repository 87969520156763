<template>
  <div class="mb-4">
    <h5 v-if="$slots.infoText" class="mb-2.5 font-semibold text-gray-400">
      <slot name="infoText" />
    </h5>
    <h2 class="mb-4 text-3xl text-secondary-800">
      {{ title }}
    </h2>
    <span class="mb-4 text-secondary-800">
      {{ description }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ThreeLineHeading',
  props: {
    title: {
      type: String,
      default: 'Tytuł',
      reguired: true
    },
    description: {
      type: String,
      default: ''
    }
  }
})
</script>
