<template>
  <div class="mb-2 w-full md:mb-6">
    <div
      class="flex h-max flex-col border bg-white hover:cursor-pointer hover:border-success-400 hover:bg-success-100 md:rounded-lg"
    >
      <div class="flex px-3 py-2 md:px-6 md:py-4">
        <div class="mr-4 flex items-start md:mr-6">
          <icon
            v-if="icon.length > 0"
            :name="icon"
            size="custom"
            class="h-16 w-16 text-primary-500 md:h-24 md:w-24"
          />
        </div>
        <div>
          <h5
            class="mb-2.5 mt-1.5 text-lg font-bold uppercase text-secondary-600 md:text-xl"
          >
            <slot name="title" />
          </h5>
          <p class="text-sm font-normal text-gray-500">
            <slot name="description" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'ProfileIntroduceCard',
  components: {
    Icon
  },
  props: {
    icon: {
      type: String,
      default: 'user',
      required: true
    }
  }
})
</script>
