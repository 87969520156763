<template>
  <div class="w-full overflow-x-auto overflow-y-hidden md:overflow-hidden">
    <div class="mb-5 flex flex-row gap-2">
      <span class="w-[80px] flex-none md:w-[120px]"></span>
      <div class="grid w-full grid-flow-col grid-rows-1 gap-x-2">
        <div
          class="flex justify-center"
          v-for="day in ['Pon', 'Wto', 'Śro', 'Czw', 'Pt', 'Sob', 'Ndz']"
          :key="day"
        >
          <span
            class="w-8 flex-none whitespace-nowrap text-center text-sm md:w-16 md:text-base lg:w-auto"
            v-text="day"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-2">
      <div
        class="flex w-[80px] flex-none flex-col gap-y-4 text-center md:w-[120px]"
      >
        <div
          v-for="time in times"
          :key="time.range"
          class="flex h-8 flex-none flex-col items-start justify-center md:h-12 lg:h-16"
        >
          <span
            class="text-sm font-semibold text-secondary-600 md:text-base lg:text-lg"
            >{{ time.time }}</span
          >
          <span class="text-xs text-secondary-500 md:text-sm">{{
            time.range
          }}</span>
        </div>
      </div>
      <div class="grid w-full grid-flow-col grid-rows-4 gap-y-4 gap-x-2">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProfileIntroduceTime',
  setup() {
    const times = [
      { range: '06:00-12:00', time: 'Rano' },
      { range: '12:00-18:00', time: 'Popołudnie' },
      { range: '18:00-00:00', time: 'Wieczór' },
      { range: '00:00-06:00', time: 'Noc' }
    ]
    return {
      times
    }
  }
})
</script>
