<template>
  <div class="relative min-w-[260px] pb-1">
    <div v-if="isLoading" class="flex justify-center py-4">
      <spinner size="xs" />
    </div>

    <portal-chat-sidebar
      :active="-1"
      :owner="userID"
      :rooms="rooms"
      notifications
      @click="loadRooms"
    />
    <router-link
      :to="`/inbox/${roomID}`"
      class="relative flex items-center justify-center border-t border-gray-200 px-3.5 py-2.5 text-sm font-bold text-secondary-700 hover:bg-gray-100 hover:text-warning-600"
    >
      <icon name="comments" size="4" class="mr-2.5" />
      {{ $t('navbar.allinbox') }}
    </router-link>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import Icon from '@atoms/Icon'
import Spinner from '@atoms/Spinner'
import PortalChatSidebar from '@portal/PortalChatSidebar'
import { useAuthStore } from '@stores/auth'

import apiChats from '@db/Chats'
import { Room } from '@db/Chats/interfaces'

export default defineComponent({
  components: {
    Icon,
    Spinner,
    PortalChatSidebar
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const useAuth = useAuthStore()
    const userID = useAuth.getUser.id
    const rooms = ref([] as Room[])
    const isLoading = ref(true)
    const roomID = computed(() => rooms.value[0]?.id || 0)

    const loadRooms = async () => {
      const d = await apiChats.readAllNew(5, 1)
      rooms.value = d.data.rows
      isLoading.value = false
    }

    onMounted(async () => {
      await loadRooms()
    })

    return {
      userID,
      rooms,
      isLoading,
      roomID,
      loadRooms
    }
  }
})
</script>
