<template>
  <div
    class="aside-menu relative flex h-screen w-[320px] flex-col overflow-y-auto bg-white px-0"
  >
    <div
      @click="hide"
      class="flex cursor-pointer flex-row p-4 text-base font-bold"
    >
      <icon name="angleLeft" />
      <span class="pl-2">Wróć do przeglądania</span>
    </div>

    <div class="flex overflow-y-auto overflow-x-hidden">
      <div class="w-[320px] transition-all">
        <portal-filters-menu sidebar @close="hide" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useSidenavStore } from '@stores/sidenav'
import PortalFiltersMenu from '@portal/PortalFiltersMenu'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'PortalMobileFitters',
  components: {
    PortalFiltersMenu,
    Icon
  },
  setup() {
    const sidenav = useSidenavStore()

    const hide = () => {
      sidenav.setHorizontal()
      sidenav.close()
    }

    return {
      hide
    }
  }
})
</script>

<style scoped>
.aside-menu::-webkit-scrollbar {
  position: absolute;
  width: 6px;
}
.aside-menu::-webkit-scrollbar-thumb {
  background-color: rgb(214, 211, 209, 1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
</style>
