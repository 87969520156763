<template>
  <div
    class="flex cursor-pointer py-2 px-4 hover:bg-gray-100"
    :class="{
      'bg-gray-100': isActive,
      'bg-gray-50 text-danger-700': isUnread
    }"
    @click="read"
  >
    <avatar
      bg="secondary"
      class="my-auto flex-shrink-0 cursor-pointer"
      :alt="name"
      :path="isParent ? item.nanny_avatarpath : item.parent_avatarpath"
    />
    <div class="ml-3">
      <div class="font-bold">
        {{ name }}
      </div>
      <div class="text-sm">
        <span class="line-clamp-1">
          {{ item.message_text || '...' }}
        </span>
        <router-link
          v-if="item.ad_title"
          class="mr-2 text-xs text-primary"
          :to="`/jobs/announcment/${item.ad_id}`"
        >
          {{ item.ad_title }} </router-link
        ><span class="text-xs text-gray-400"> {{ convertDate }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed, ref, onMounted } from 'vue'
import { useAuthStore } from '@stores/auth'
import { useLangsStore } from '@stores/langs'

import Avatar from '@atoms/Avatar'
import { Room } from '@db/Chats/interfaces'

export default defineComponent({
  components: {
    Avatar
  },
  props: {
    isOwner: {
      required: true,
      type: Boolean
    },
    isActive: {
      required: true,
      type: Boolean
    },
    item: {
      type: Object,
      default: {} as Room
    }
  },
  emits: ['picRoom'],
  setup(props, { emit }) {
    const useAuth = useAuthStore()
    const useLangs = useLangsStore()
    const user = useAuth.getUser
    const isParent = useAuth.isParent
    const isUnread = ref(
      props.item.message_is_read === 2 && props.item.sender_id !== user.id
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dayJS = inject('dayjs') as any
    dayJS.locale(useLangs.getLang)
    const convertDate = computed(() =>
      dayJS().to(dayJS(props.item.room_updated))
    )

    const name =
      user.id === props.item.parent_id
        ? props.item.nanny_name
        : props.item.parent_name

    const read = () => {
      emit('picRoom', props.item.id), (isUnread.value = false)
    }

    onMounted(() => {
      if (props.isActive) isUnread.value = false
    })

    return {
      user,
      convertDate,
      name,
      read,
      isUnread,
      isParent
    }
  }
})
</script>
