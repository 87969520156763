import { inject, computed } from 'vue'

interface Props {
  modelValue: string | boolean | number
}
interface Emit {
  (name: string, value: string | boolean | number): void
}

export default function () {
  const v = inject('v', {
    $invalid: false,
    $error: false,
    $errors: [{ $validator: '' }],
    $dirty: false,
    $touch: () => {
      return false
    }
  })
  const fieldSize = inject('size', null)
  const fieldName = inject('name', '')

  const useValidation = (props: Props, emit: Emit) =>
    computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

  return {
    v,
    fieldSize,
    fieldName,
    useValidation
  }
}
