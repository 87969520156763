<template>
  <div class="mx-auto py-6 px-4 lg:max-w-7xl lg:px-6">
    <div class="grid grid-cols-3 gap-x-8 lg:grid-cols-4">
      <div class="hidden lg:block">
        <side-menu :is-parent="isParent" />
      </div>
      <div class="col-span-3">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useAuthStore } from '@stores/auth'
import SideMenu from '@portal/SideMenu'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    SideMenu
  },
  setup() {
    const useAuth = useAuthStore()
    const isParent = useAuth.isParent
    const route = useRoute()
    const refresh = ref(0)
    watch(
      () => route.name,
      () => {
        refresh.value++
      }
    )
    return {
      isParent,
      refresh
    }
  }
})
</script>
