import LayoutDefault from '../layouts/LayoutBase.vue'

const routes = {
  path: 'inbox',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Chat'
      },
      children: [
        {
          path: '',
          name: 'inbox',
          component: () => import('../views/inbox/InboxIndex.vue')
        },
        {
          path: ':roomID',
          name: 'inboxItem',
          component: () => import('../views/inbox/InboxIndex.vue')
        },

        {
          path: '',
          name: 'inboxItemArchive',
          children: [
            {
              path: ':roomID/archive',
              name: 'inboxItemArchive',

              component: () => import('../views/inbox/RoomArchive.vue')
            }
          ]
        }
      ]
    }
  ]
}

export default routes
