<template>
  <div
    class="relative"
    :class="{
      'h-8 w-8': size === 'xs',
      'h-10 w-10': size === 'sm',
      'h-12 w-12': size === 'md',
      'h-14 w-14': size === 'lg',
      'h-18 w-18': size === 'xl'
    }"
  >
    <div
      class="inline-flex items-center justify-center"
      data-test="heroWrapper"
      :class="{
        'rounded-full': isRounded,
        'rounded-md': !isRounded,
        'bg-transparent': isTransparent,
        border: isBorder,
        'bg-secondary-100': color === 'secondary' && !isTransparent,
        'border-secondary-200': color === 'secondary' && isBorder,
        [`bg-${color}-200 opacity-20`]: color !== 'secondary' && !isTransparent,
        [`border-${color}-300`]: color !== 'secondary' && isBorder,
        'h-8 w-8': size === 'xs',
        'h-10 w-10': size === 'sm',
        'h-12 w-12': size === 'md',
        'h-14 w-14': size === 'lg',
        'h-18 w-18': size === 'xl'
      }"
    />
    <icon
      v-if="isBorder || !isTransparent"
      :name="path"
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform"
      :class="{
        'h-5 w-5': size === 'xs',
        'h-6 w-6': size === 'sm' || size === 'md',
        'h-7 w-7': size === 'lg',
        'h-8 w-8': size === 'xl'
      }"
      :color="!isOriginal ? `${color}-500` : `secondary-800`"
    />
    <icon
      v-else
      :name="path"
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform"
      :class="{
        'h-6 w-6': size === 'xs',
        'h-7 w-7': size === 'sm' || size === 'md',
        'h-8 w-8': size === 'lg',
        'h-9 w-9': size === 'xl'
      }"
      :color="!isOriginal ? `${color}-500` : 'secondary-800'"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'HeroIconAtom',
  components: { Icon },
  props: {
    path: {
      type: String,
      default: '',
      required: true
    },
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v)
    },
    color: {
      type: String,
      default: 'primary',
      validator: (v: string) =>
        [
          'primary',
          'secondary',
          'success',
          'danger',
          'info',
          'warning'
        ].includes(v)
    },
    isTransparent: {
      type: Boolean
    },
    isBorder: {
      type: Boolean
    },
    isRounded: {
      type: Boolean,
      default: true
    },
    isOriginal: {
      type: Boolean,
      default: true
    }
  }
})
</script>
