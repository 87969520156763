<template>
  <div>
    <div v-for="item in data.options" :key="item.id" class="gap-3 py-1">
      <div class="flex items-center space-x-4">
        <input
          :id="item.id"
          v-model="payload"
          :name="item.id"
          type="checkbox"
          :value="item.attribute_value"
          class="flex h-5 w-5 items-center rounded border-secondary-300 text-secondary-400 focus:ring-secondary-400"
        />
        <label
          :for="item.id"
          class="block cursor-pointer text-base font-medium text-secondary-700"
          >{{ item.attribute_option }}</label
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
type Payload = string | number
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    attrType: {
      type: String,
      required: true,
      validator: (v: string) => ['nanny', 'baby', 'ad'].includes(v)
    }
  },
  emits: ['changeValue'],
  setup(props, context) {
    const payload = ref([] as Payload[])
    watch(
      () => [...payload.value],
      () => {
        const toSend = {
          values: payload.value,
          attribute_field_type: props.data.attribute_field_type
        }
        context.emit('changeValue', toSend)
      }
    )

    const setInitialPayload = () => {
      if (props.data.values) {
        if (
          props.data.attribute_field_type === 'multiselect' ||
          props.data.attribute_field_type === 'checkbox' ||
          props.data.attribute_field_type === 'select' ||
          props.data.attribute_field_type === 'radio'
        ) {
          const values = props.data.values.split(',')
          for (let i = 0; i < props.data.options.length; i++) {
            if (values.includes(props.data.options[i].attribute_option)) {
              payload.value.push(props.data.options[i].attribute_value)
            }
          }
        } else {
          payload.value[0] = props.data.values
        }
      } else payload.value = []
    }

    setInitialPayload()

    const isDisabled = computed(() => props.data.attribute_edit === 2)

    return {
      payload,
      isDisabled
    }
  }
})
</script>
