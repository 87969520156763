import auth from './auth'
import sidebar from './sidebar'
import navbar from './navbar'
import footer from './footer'
import carousel from './carousel'
import contact from './contact'
import validations from './validations'
import profile from './profile'
import account from './account'
import deals from './deals'
import kids from './kids'
import offers from './offers'
import ads from './ads'
import notifications from './notifications'
import worktime from './worktime'
import rooms from './rooms'
import pagination from './pagination'
import attributes from './attributes'
import jobs from './jobs'
import filters from './filters'
import onboarding from './onboarding'
import nannies from './nannies'
import likes from './likes'
import products from './products'
import chat from './chat'
import payments from './payments'
import posts from './posts'

export default {
  auth,
  sidebar,
  navbar,
  footer,
  carousel,
  contact,
  validations,
  profile,
  account,
  deals,
  kids,
  offers,
  ads,
  worktime,
  rooms,
  pagination,
  attributes,
  jobs,
  notifications,
  filters,
  onboarding,
  nannies,
  likes,
  products,
  chat,
  payments,
  posts,
  title: 'Znana Niania',
  formFile: {
    select: 'Wybierz plik',
    drag: 'lub przeciągnij i upuść'
  }
}
