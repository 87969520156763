<template>
  <div class="mb-2 md:mb-6">
    <div class="flex h-max flex-col border bg-white md:rounded-lg">
      <div class="flex px-3 pt-5 pb-4 md:px-6 md:pb-6 md:pt-6">
        <div class="mr-4 flex items-start md:mr-6">
          <icon
            name="userEditSolid"
            size="custom"
            class="h-12 w-12 text-primary-500 md:h-20 md:w-20"
          />
        </div>
        <div class="w-full">
          <h5
            class="mb-4 mt-1 text-sm font-bold uppercase text-secondary-700 md:whitespace-nowrap md:text-base"
          >
            {{ $t('profile.description.title') }}
          </h5>
          <p class="mb-4 text-sm font-normal text-gray-500">
            {{ $t('profile.description.placeholder') }}
          </p>
          <div class="w-full">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'ProfileAbout',
  components: {
    Icon
  }
})
</script>
