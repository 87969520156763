import { defineStore } from 'pinia'

export const useNavbarStore = defineStore('navabar', {
  state: () => ({
    inbox: 0,
    notifications: 0,
    connection: null as unknown as WebSocket
  }),
  getters: {
    getInbox(state) {
      return state.inbox
    },
    getNotifications(state) {
      return state.notifications
    }
  },
  actions: {
    setInbox(messages: number) {
      this.inbox = messages
    },
    setNotifications(notifications: number) {
      this.notifications = notifications
    },
    async sendNotify() {
      await this.connection.send(JSON.stringify(''))
    }
  }
})
