export default {
  slides: {
    slide1: {
      title: 'Title jakiś 1',
      description: 'To jest jakiś opis dla 1',
      btn: 'Kliknij tutaj',
      link: ''
    },
    slide2: {
      title: 'Title jakiś 2',
      description: 'To jest jakiś opis dla 2',
      btn: 'Kliknij tutaj',
      link: ''
    },
    slide3: {
      title: 'Title jakiś 3',
      description: 'To jest jakiś opis dla 3',
      btn: 'Kliknij tutaj',
      link: ''
    }
  },
  next: 'Następny',
  prev: 'Poprzedni'
}
