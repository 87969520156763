<template>
  <div
    class="flex flex-col-reverse gap-4 pt-8 pb-4 md:flex-row md:pt-12 md:pb-16"
  >
    <div class="basis-full text-center text-secondary-600">
      <span class="text-base font-medium uppercase"> Dlaczego warto </span>
      <h3 class="mt-3 mb-6 text-3xl font-semibold xl:text-4xl">
        Właśnie dlatego warto do nas dołączyć
      </h3>
      <p class="text-lg font-normal leading-7">
        Praesent eleifend justo sit amet tincidunt interdum. Sed porttitor, odio
        quis ultricies aliquet, magna nunc pulvinar turpis, id congue lorem arcu
        sit amet libero.
      </p>
    </div>
  </div>
  <div class="hidden items-center py-2 md:flex">
    <div class="flex-1" />
    <div class="flex-none px-4 text-secondary-600">
      <icon name="folderPlusSolid" size="12" />
    </div>
    <div class="h-[1px] w-full flex-1 border-b border-secondary-600/25" />
    <div class="h-[1px] w-full flex-1 border-b border-secondary-600/25" />
    <div class="flex-none px-4 text-secondary-600">
      <icon name="folderPlusSolid" size="12" />
    </div>
    <div class="h-[1px] w-full flex-1 border-b border-secondary-600/25" />
    <div class="h-[1px] w-full flex-1 border-b border-secondary-600/25" />
    <div class="flex-none px-4 text-secondary-600">
      <icon name="folderPlusSolid" size="12" />
    </div>
    <div class="flex-1" />
  </div>
  <div
    class="mb-12 flex flex-col gap-4 divide-y divide-secondary-600/25 py-2 md:flex-row md:divide-y-0"
  >
    <div class="basis-full py-4 text-center md:basis-4/12 md:py-0">
      <div
        class="mb-4 flex flex-none justify-center text-secondary-600 md:hidden"
      >
        <icon name="folderPlusSolid" size="12" />
      </div>
      <h3 class="mb-2 text-xl font-bold text-secondary-600">
        Powód numer jeden
      </h3>
      <p class="text-base font-normal text-secondary-600">
        In consequat molestie odio, semper pharetra nulla condimentum ac. In
        feugiat porta rhoncus. Nunc luctus consequat porttitor.
      </p>
    </div>
    <div class="basis-full py-4 text-center md:basis-4/12 md:py-0">
      <div
        class="mb-4 flex flex-none justify-center text-secondary-600 md:hidden"
      >
        <icon name="folderPlusSolid" size="12" />
      </div>
      <h3 class="mb-2 text-xl font-bold text-secondary-600">Powód numer dwa</h3>
      <p class="text-base font-normal text-secondary-600">
        In consequat molestie odio, semper pharetra nulla condimentum ac. In
        feugiat porta rhoncus. Nunc luctus consequat porttitor.
      </p>
    </div>
    <div class="basis-full py-4 text-center md:basis-4/12 md:py-0">
      <div
        class="mb-4 flex flex-none justify-center text-secondary-600 md:hidden"
      >
        <icon name="folderPlusSolid" size="12" />
      </div>
      <h3 class="mb-2 text-xl font-bold text-secondary-600">
        Powód numer trzy
      </h3>
      <p class="text-base font-normal text-secondary-600">
        In consequat molestie odio, semper pharetra nulla condimentum ac. In
        feugiat porta rhoncus. Nunc luctus consequat porttitor.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  components: {
    Icon
  },
  setup() {
    // setup
  }
})
</script>
