const routes = {
  path: '/auth/oauth',
  component: () => import('../layouts/LayoutLoading.vue'),
  meta: {
    forLogged: false,
    forNanny: false,
    forParent: false
  },
  children: [
    {
      path: ':provider',
      name: 'authOauth',
      component: () => import('../views/auth/AuthOauth.vue')
    }
  ]
}

export default routes
