<template>
  <div class="w-full">
    <span> Masz nową wiadomość od </span>
    <span class="pl-1 font-semibold">
      {{ data.notification_user_username }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotificationMessages',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
})
</script>
