<template>
  <div class="relative flex">
    <div
      class="flex flex-grow"
      :class="{
        'flex-col': template !== 'horizontal',
        'items-center': template === 'action'
      }"
    >
      <hero-icon
        v-if="icon.length > 0"
        class="flex-none"
        :path="icon"
        :size="template !== 'action' ? 'md' : 'lg'"
        :color="type"
        :is-original="false"
        is-border
      />
      <div
        class="mt-0"
        :class="{
          'text-left': template !== 'action',
          'ml-4': icon.length > 0 && template === 'horizontal',
          'mt-4': template !== 'horizontal',
          'text-center': template === 'action'
        }"
      >
        <h3
          class="font-medium leading-6 text-secondary-900"
          :class="template !== 'action' ? 'text-md' : 'text-lg'"
        >
          {{ title }}
        </h3>
        <div
          v-if="msg.length > 0"
          :class="template !== 'action' ? 'mt-1' : 'mt-2'"
        >
          <p class="text-sm text-secondary-500">
            {{ msg }}
          </p>
        </div>
      </div>
    </div>
    <div class="h-6 w-6 flex-none">
      <close
        v-if="isClose && template !== 'action'"
        data-test="close"
        @click="closeModal()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useOverlayStore } from '@stores/overlay'
import Close from '@atoms/Close'
import HeroIcon from '@atoms/HeroIcon'

export default defineComponent({
  name: 'ModalHeaderMolecule',
  components: {
    HeroIcon,
    Close
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    },
    template: {
      type: String,
      default: 'action',
      validator: (value: string) => {
        return ['action', 'horizontal', 'vertical'].indexOf(value) !== -1
      }
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value: string) => {
        return (
          [
            'primary',
            'info',
            'danger',
            'warning',
            'success',
            'secondary'
          ].indexOf(value) !== -1
        )
      }
    },
    icon: {
      type: String,
      default: ''
    },
    isClose: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const overlay = useOverlayStore()
    const closeModal = () => {
      overlay.updateShowTemplate(false)
      overlay.updateShow(false)
    }
    return {
      overlay,
      closeModal
    }
  }
})
</script>
