<template>
  <div
    class="flex flex-col-reverse justify-between gap-4 pt-12 md:flex-row md:pt-8"
  >
    <div class="mt-6 basis-full md:mt-0 md:basis-6/12">
      <h6 class="mt-4 mb-4 text-base font-bold uppercase text-secondary-600">
        Proces
      </h6>
      <h2 class="mb-4 text-3xl font-semibold text-secondary-600">
        Nasz proces szukania niani jest szybki, sktuteczny i bezpieczny.
      </h2>
      <p class="text-lg font-normal leading-7 text-secondary-600">
        Morbi imperdiet felis at congue vulputate. Donec vitae viverra tellus,
        id consequat orci. Maecenas eget tincidunt leo.
      </p>
    </div>
    <div class="basis-full md:basis-5/12">
      <div class="mt-0 flex flex-nowrap md:mt-8 lg:mt-0">
        <div class="col-span-4">
          <div
            class="bg-white p-1 shadow-2xl"
            style="
              padding: 0.25rem !important;
              margin-top: 4rem !important;
              width: 160% !important;
            "
          >
            <img src="@assets/carousel/1.jpg" alt="..." />
          </div>
        </div>
        <div class="col-span-4">
          <div
            class="bg-white shadow-2xl"
            style="padding: 0.25rem !important; width: 160% !important"
          >
            <img src="@assets/carousel/1.jpg" alt="..." />
          </div>
        </div>
        <div class="relative col-span-4">
          <div
            class="float-right bg-white shadow-2xl"
            style="
              padding: 0.25rem !important;
              margin-top: 6rem !important;
              width: 160% !important;
            "
          >
            <img src="@assets/carousel/1.jpg" alt="..." />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-4 pt-8 pb-12 md:flex-row">
    <div class="basis-full md:basis-4/12">
      <div class="mb-4 flex items-center md:mb-8 xl:mb-12">
        <span class="mr-2 text-sm font-semibold text-secondary-600/50">1</span>
        <span class="h-[1px] w-full border-b border-secondary-600/25" />
      </div>
      <div class="mb-8 md:mb-2">
        <h3 class="mb-2 text-2xl font-bold text-secondary-600">Utwórz konto</h3>
        <p class="text-base font-normal text-secondary-600">
          Donec orci turpis, auctor a nisl vitae, rhoncus aliquet purus.
          Pellentesque placerat augue sed ipsum bibendum.
        </p>
      </div>
    </div>
    <div class="basis-full md:basis-4/12">
      <div class="mb-4 flex items-center md:mb-8 xl:mb-12">
        <span class="mr-2 text-sm font-semibold text-secondary-600/50">2</span>
        <span class="h-[1px] w-full border-b border-secondary-600/25" />
      </div>
      <div class="mb-8 md:mb-2">
        <h3 class="mb-2 text-2xl font-bold text-secondary-600">
          Wyszukaj dla siebie
        </h3>
        <p class="text-base font-normal text-secondary-600">
          In consequat molestie odio, semper pharetra nulla condimentum ac. In
          feugiat porta rhoncus. Nunc luctus consequat porttitor.
        </p>
      </div>
    </div>
    <div class="basis-full md:basis-4/12">
      <div class="mb-4 flex items-center md:mb-8 xl:mb-12">
        <span class="mr-2 text-sm font-semibold text-secondary-600/50">3</span>
        <span class="h-[1px] w-full border-b border-secondary-600/25" />
      </div>
      <div class="mb-8 md:mb-2">
        <h3 class="mb-2 text-2xl font-bold text-secondary-600">
          Nawiąż współpracę
        </h3>
        <p class="text-base font-normal text-secondary-600">
          Phasellus ultrices in libero ac dignissim. Vivamus mollis sem vitae
          pulvinar tincidunt.
        </p>
      </div>
    </div>
  </div>
</template>
