import LayoutDefault from '../layouts/LayoutBase.vue'
import LayoutSubpage from '../layouts/LayoutSubpage.vue'

const routes = {
  path: 'filters',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Filtry wyszukiwania'
      },
      components: {
        default: LayoutSubpage
      },
      children: [
        {
          path: '',
          name: 'filters',
          component: () => import('../views/filters/FiltersIndex.vue')
        }
      ]
    }
  ]
}

export default routes
