<template>
  <!-- <div :class="`loader spinner-${size}`" /> -->
  <div :class="`spinner-${size}`">
    <svg viewBox="0 0 100 100">
      <defs>
        <filter id="shadow">
          <feDropShadow
            dx="0"
            dy="0"
            stdDeviation="1.5"
            flood-color="#FFCD66"
          />
        </filter>
      </defs>
      <circle
        class="spinnerid"
        style="
          fill: transparent;
          stroke: #ffcd66;
          stroke-width: 7px;
          stroke-linecap: round;
          filter: url(#shadow);
        "
        cx="50"
        cy="50"
        r="45"
      />
      <g transform="matrix(0.8,0,0,0.8,0,0) translate(12,12)">
        <g transform="matrix(0.0702339,0,0,0.0702339,21.2581,21.0602)">
          <g
            transform="matrix(-0.032255,-5.55546,-5.55546,0.032255,411.396,12.7219)"
          >
            <path
              d="M-74.921,-75.361C-116.299,-75.361 -149.846,-41.822 -149.846,-0.441C-149.846,40.938 -116.299,74.486 -74.921,74.486C-33.549,74.487 0,40.939 0.001,-0.44C-0,-41.821 -33.549,-75.361 -74.921,-75.361"
              style="fill: rgb(255, 205, 102); fill-rule: nonzero"
            />
          </g>
          <g
            transform="matrix(-5.23298,-1.8655,-1.8655,5.23298,91.2876,190.619)"
          >
            <path
              d="M3.247,-35.268C13.763,-31.451 19.197,-19.831 15.377,-9.309C13.248,-3.445 8.689,0.837 3.247,2.815C0.002,-10.286 -6.744,-22.379 -16.328,-32.043C-10.997,-36.305 -3.638,-37.768 3.247,-35.268"
              style="fill: rgb(255, 205, 102); fill-rule: nonzero"
            />
          </g>
          <g
            transform="matrix(-4.85965,-2.69221,-2.69221,4.85965,53.9529,178.057)"
          >
            <path
              d="M-17.87,-35.551C-9.986,-24.457 -5.298,-11.427 -4.228,2.027C-8.737,2.873 -13.556,2.2 -17.87,-0.223C-27.625,-5.701 -31.091,-18.051 -25.615,-27.802C-23.723,-31.171 -21.011,-33.791 -17.87,-35.551"
              style="fill: rgb(251, 173, 49); fill-rule: nonzero"
            />
          </g>
          <g
            transform="matrix(-5.19359,1.97251,1.97251,5.19359,741.155,187.425)"
          >
            <path
              d="M-2.916,-34.89C3.746,-37.375 10.888,-36.13 16.214,-32.198C6.732,-22.369 0.136,-10.132 -2.916,3.074C-8.226,1.093 -12.678,-3.096 -14.816,-8.824C-18.727,-19.308 -13.405,-30.976 -2.916,-34.89"
              style="fill: rgb(255, 205, 102); fill-rule: nonzero"
            />
          </g>
          <g
            transform="matrix(-4.86789,2.67729,2.67729,4.86789,776.908,178.788)"
          >
            <path
              d="M17.954,-35.883C21.29,-34.076 24.159,-31.314 26.103,-27.732C31.437,-17.896 27.786,-5.602 17.954,-0.268C13.491,2.153 8.524,2.727 3.929,1.71C5.11,-11.792 9.935,-24.83 17.954,-35.883"
              style="fill: rgb(251, 173, 49); fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(5.55556,0,0,5.55556,242.431,317.705)">
            <path
              d="M0,-9.971C-2.064,-9.008 -3.538,-7.55 -4.13,-5.149C-4.57,-3.359 -3.536,-1.759 -2.064,-0.835C-0.709,0.02 1.11,0.314 2.657,-0.125C4.541,-0.657 6.112,-2.376 6.105,-4.272C6.097,-5.889 6.094,-7.299 4.895,-8.802C3.973,-9.957 0.673,-10.285 0,-9.971"
              style="fill: rgb(0, 57, 81); fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(5.55556,0,0,5.55556,589.447,309.45)">
            <path
              d="M0,-7.738C-1.056,-6.39 -1.883,-4.719 -1.87,-3.096C-1.853,-1.203 -0.265,0.505 1.628,1.013C3.178,1.433 4.991,1.117 6.34,0.248C7.801,-0.694 8.813,-2.312 8.352,-4.088C7.732,-6.486 6.244,-7.923 4.169,-8.866C3.491,-9.171 0.914,-8.903 0,-7.738"
              style="fill: rgb(0, 57, 81); fill-rule: nonzero"
            />
          </g>
          <g
            transform="matrix(-0.204667,-5.55178,-5.55178,0.204667,412.571,302.951)"
          >
            <path
              d="M-40.583,-42.104C-60.466,-42.103 -76.584,-23.921 -76.584,-1.494C-76.585,20.931 -60.465,39.115 -40.583,39.112C-20.699,39.113 -4.576,20.933 -4.578,-1.496C-4.578,-23.922 -20.696,-42.101 -40.583,-42.104"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(5.55556,0,0,5.55556,554.119,497.621)">
            <path
              d="M0,4.432C-0.46,3.716 -1.239,3.293 -2.027,3.32C-2.801,3.35 -3.47,3.819 -3.859,4.608C-7.553,12.092 -13.572,16.946 -20.518,18.106C-20.403,13.861 -20.319,9.604 -20.366,5.354C-17.969,4.844 -15.022,3.573 -13.834,1.604C-11.713,-1.9 -10.235,-6.117 -10.738,-9.971C-11.32,-14.474 -15.598,-18.058 -20.244,-18.729C-24.049,-19.275 -28.277,-17.999 -31.229,-15.546C-34.431,-12.876 -36.372,-8.745 -34.76,-4.643C-32.674,0.664 -28.91,3.63 -23.995,5.3C-23.948,9.644 -24.073,13.998 -24.19,18.325C-30.863,17.997 -37.087,13.494 -41.15,5.999C-41.58,5.215 -42.275,4.774 -43.061,4.804C-43.82,4.831 -44.551,5.298 -44.964,6.028C-45.418,6.821 -45.416,7.764 -44.955,8.611C-39.688,18.329 -31.392,23.707 -22.195,23.368L-21.657,23.343C-12.956,22.817 -4.614,16.544 0.108,6.973C0.533,6.118 0.493,5.191 0,4.432"
              style="fill: rgb(0, 57, 81); fill-rule: nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SpinnerAtom',
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (value: string) => {
        return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
      }
    }
  }
})
</script>

<style lang="postcss">
.spinner-xl {
  width: 96px;
  height: 96px;
}
.spinner-lg {
  width: 80px;
  height: 80px;
}
.spinner-md {
  width: 64px;
  height: 64px;
}
.spinner-sm {
  width: 48px;
  height: 48px;
}
.spinner-xs {
  width: 32px;
  height: 32px;
}
@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

.spinnerid {
  transform-origin: center;
  animation-name: animation;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier;
  animation-iteration-count: infinite;
}
</style>
