<template>
  <div class="mt-1 mb-3 flex w-48 justify-between p-1">
    <btn
      id="previous"
      size="xs"
      icon="angleLeft"
      icon-only
      :outline="outline"
      color="secondary"
      data-test="btnLeft"
      @click="$emit('previous')"
    />
    <span
      class="cursor-pointer select-none font-bold"
      :class="`text-${textColor}`"
      data-test="date"
      @click="$emit('date')"
    >
      {{ text }}
    </span>
    <btn
      id="next"
      size="xs"
      icon="angleRightSolid"
      icon-only
      :outline="outline"
      color="secondary"
      data-test="btnRight"
      @click="$emit('next')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Btn from '@atoms/Btn'

export default defineComponent({
  name: 'FormDateHeaderMolecule',
  components: {
    Btn
  },
  props: {
    text: {
      type: String,
      default: '',
      required: true
    },
    outline: {
      type: Boolean
    },
    textColor: {
      type: String,
      default: 'secondary-800'
    }
  },
  emits: ['previous', 'date', 'next']
})
</script>
