<template>
  <div class="rounded border border-gray-200 bg-white">
    <div
      v-for="(section, ids) in sections"
      :key="ids"
      class="mb-2 text-slate-700"
    >
      <portal-menu-label>
        {{ section.title }}
      </portal-menu-label>
      <portal-menu-item
        v-for="(item, idx) in section.arr"
        :key="idx"
        :item="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PortalMenuItem from '@portal/PortalMenuItem'
import PortalMenuLabel from '@portal/PortalMenuLabel'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SideMenu',
  components: {
    PortalMenuItem,
    PortalMenuLabel
  },
  props: {
    isParent: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { t } = useI18n()
    const sections = []

    let settings = []
    let activity = []
    if (props.isParent) {
      settings = [
        {
          title: t('sidebar.settings.profil'),
          route: { name: 'profil' },
          icon: 'userEditSolid'
        },
        {
          title: t('sidebar.settings.account'),
          route: { name: 'account' },
          icon: 'user'
        },
        {
          title: t('sidebar.settings.kids'),
          route: { name: 'kids' },
          icon: 'babySolid'
        },
        {
          title: t('sidebar.settings.filters'),
          route: { name: 'filters' },
          icon: 'slidersHSolid'
        }
      ]
      activity = [
        {
          title: t('sidebar.activity.myads'),
          route: { name: 'announcments' },
          icon: 'mapMarkedAltSolid'
        },
        {
          title: t('sidebar.activity.cooperations'),
          route: { name: 'deals' },
          icon: 'handshakeSolid'
        },
        {
          title: t('sidebar.activity.inbox'),
          route: { name: 'inbox' },
          icon: 'comments'
        },
        {
          title: t('sidebar.activity.notifications'),
          route: { name: 'notifications' },
          icon: 'bell'
        },
        {
          title: t('sidebar.activity.offers'),
          route: { name: 'offers' },
          icon: 'folderPlusSolid'
        },
        {
          title: t('sidebar.activity.favnannies'),
          route: { name: 'likes' },
          icon: 'thumbsUp'
        }
      ]
    } else {
      settings = [
        {
          title: t('sidebar.settings.profil'),
          route: { name: 'profil' },
          icon: 'userEditSolid'
        },
        {
          title: t('sidebar.settings.account'),
          route: { name: 'account' },
          icon: 'user'
        },
        {
          title: t('sidebar.settings.attrs'),
          route: { name: 'attributes' },
          icon: 'userTagSolid'
        },
        {
          title: t('sidebar.settings.availability'),
          route: { name: 'worktime' },
          icon: 'calendarAltSolid'
        },
        {
          title: t('sidebar.settings.filters'),
          route: { name: 'filters' },
          icon: 'slidersHSolid'
        }
      ]
      activity = [
        {
          title: t('sidebar.activity.cooperations'),
          route: { name: 'deals' },
          icon: 'handshakeSolid'
        },
        {
          title: t('sidebar.activity.inbox'),
          route: { name: 'inbox' },
          icon: 'comments'
        },
        {
          title: t('sidebar.activity.notifications'),
          route: { name: 'notifications' },
          icon: 'bell'
        },
        {
          title: t('sidebar.activity.offers'),
          route: { name: 'offers' },
          icon: 'folderPlusSolid'
        }
      ]
    }
    sections.push({ title: t('sidebar.sections.settings'), arr: settings })
    sections.push({ title: t('sidebar.sections.activity'), arr: activity })

    return {
      sections
    }
  }
})
</script>
