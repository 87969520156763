<template>
  <modal template-id="ModalAvailability" :overlay-close="false">
    <div>
      <modal-header
        template="horizontal"
        title="Wybierz interesujące Cię godziny"
        type="danger"
        :is-close="false"
        class="mb-4"
      />
      <div class="mb-1 grid grid-cols-5 gap-x-2">
        <span />
        <span
          v-for="time in [
            '06:00-12:00',
            '12:00-18:00',
            '18:00-00:00',
            '00:00-06:00'
          ]"
          class="mx-auto -ml-5 mt-3 h-12 rotate-45 whitespace-nowrap text-xs"
          :key="time"
          v-text="time"
        />
      </div>
      <div class="grid grid-cols-5">
        <div class="-mt-1 grid grid-cols-1 text-center">
          <span
            class="flex items-center justify-center"
            v-for="day in ['pon', 'wt', 'śr', 'czw', 'pt', 'sob', 'ndz']"
            :key="day"
            v-text="day"
          />
        </div>
        <div class="col-span-4 grid grid-cols-4 gap-2">
          <input
            v-for="option in data.options"
            :key="option.id"
            type="checkbox"
            :checked="options.includes(option.attribute_value)"
            class="h-8 w-full rounded-md border-gray-300 checked:bg-success-400 checked:text-success-500 focus:outline-0 focus:ring-0 focus:ring-offset-0"
            @input="changeOptions(option.attribute_value)"
          />
        </div>
      </div>
      <modal-footer
        type="danger"
        accept="Zaakceptuj"
        decline="Anuluj"
        @accept="accept"
        @decline="close"
      />
    </div>
  </modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useOverlayStore } from '@stores/overlay'
import Modal from '@molecules/Modal/Modal'
import ModalHeader from '@molecules/Modal/ModalHeader'
import ModalFooter from '@molecules/Modal/ModalFooter'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ModalAvailability',
  props: {
    data: {
      type: Object,
      required: true
    },
    payload: {
      type: Array,
      required: true
    }
  },
  emits: ['updateAvailability'],
  components: {
    Modal,
    ModalHeader,
    ModalFooter
  },
  setup(props, { emit }) {
    const options = ref(props.payload)
    const initOptions = ref([...props.payload])
    const useOverlay = useOverlayStore()
    const route = useRoute()

    const showSlideOver = () => {
      const isList = route.name === 'nannies' || route.name === 'jobs'
      if (window.innerWidth < 1024 && isList) {
        useOverlay.show('slideover')
      }
    }

    const accept = () => {
      initOptions.value = [...options.value]
      useOverlay.close()
      showSlideOver()
      emit('updateAvailability')
    }
    const close = () => {
      options.value = [...initOptions.value]
      useOverlay.close()
      showSlideOver()
    }

    const changeOptions = (val: string) => {
      const index = options.value.findIndex((el) => el === val)
      if (index === -1) {
        options.value.push(val)
      } else {
        options.value.splice(index, 1)
      }
    }

    return {
      accept,
      close,
      options,
      changeOptions
    }
  }
})
</script>
