import AbstractDB from '../AbstractDB'

export class Account extends AbstractDB {
  constructor() {
    super('account')
  }

  public getAddresses() {
    return this.get('addresses')
  }

  public getExpDate() {
    return this.get('payments/expiration')
  }

  public getPayments(limit = 10, page = 1) {
    return this.get(`payments/?limit=${limit}&page=${page}`)
  }

  public updateAddress(id: number, data: Record<string, unknown>) {
    return this.patch(`addresses/${id}`, data)
  }

  public createAdditional(data: Record<string, unknown>) {
    return this.post('addresses', data)
  }

  public changeAvatar(data: Record<string, unknown>) {
    return this.put('avatar', data)
  }

  public deleteAvatar() {
    return this.delete('avatar')
  }

  public changePassword(data: Record<string, unknown>) {
    return this.put('password', data)
  }

  public changePhone(data: Record<string, unknown>) {
    return this.patch('phone', data)
  }

  public getSubscription() {
    return this.get('subscribe')
  }

  public changeSubscription(data: Record<string, unknown>) {
    return this.put('subscribe', data)
  }

  public patchUser(data: Record<string, unknown>) {
    return this.patch('', data)
  }

  public delAddress(id: number) {
    return this.delete(`addresses/${id}`)
  }

  public updateDescription(data: Record<string, unknown>) {
    return this.patch('description', data)
  }

  public getLogs() {
    return this.get('logs')
  }

  public updateLogs(data: Record<string, unknown>) {
    return this.patch('logs', data)
  }

  public getReferences() {
    return this.get('references')
  }

  public addReferences(data: Record<string, unknown>) {
    return this.post('references', data)
  }

  public delReference(id: number) {
    return this.delete(`references/${id}`)
  }

  public getAllTags() {
    return this.get('tags')
  }

  public getUserTags() {
    // return this.get('tags/id')
  }

  public updateTags(data: Record<string, unknown>) {
    return this.patch('tags', data)
  }

  public updatePremium(data: Record<string, unknown>) {
    return this.patch('premium', data)
  }

  public updateBirth(data: Record<string, unknown>) {
    return this.patch('birth', data)
  }

  patchVisible(data: Record<string, unknown>) {
    return this.patch('visible', data)
  }

  public getEmail() {
    return this.get('email')
  }
}

const apiAccount = new Account()
export default apiAccount
