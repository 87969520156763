<template>
  <div
    v-show="useOverlay.template === templateId"
    class="modal fixed inset-0 z-40"
  >
    <div
      class="block min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:p-0"
    >
      <overlay :can-close="overlayClose" data-test="overlay" />
      <span
        class="hidden sm:inline-block sm:h-screen sm:align-middle"
        aria-hidden="true"
        >&#8203;</span
      >
      <transition
        enter-from-class="transitions opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-active-class="transitions ease-out duration-200"
        enter-to-class="transitions opacity-100 translate-y-0 sm:scale-100"
        leave-from-class="transitions opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="transitions ease-in duration-150"
        leave-to-class="transitions opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        @after-leave="afterLeave()"
      >
        <div
          v-if="useOverlay.showTemplate"
          class="inline-block translate-y-1/2 transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-5 lg:translate-x-1/2"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useOverlayStore } from '@stores/overlay'
import Overlay from '@atoms/Overlay'

export default defineComponent({
  name: 'ModalMolecule',
  components: {
    Overlay
  },
  props: {
    templateId: {
      type: String,
      default: 'modal'
    },
    overlayClose: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const useOverlay = useOverlayStore()
    const afterLeave = () => {
      useOverlay.updateTemplate('default')
    }
    return {
      useOverlay,
      afterLeave
    }
  }
})
</script>

<style lang="postcss" scoped>
@media (max-width: 1024px) {
  .modal {
    left: 312px;
  }
}
</style>
