import AbstractDB from '../AbstractDB'
class Posts extends AbstractDB {
  constructor() {
    super('posts')
  }

  public getPostsForLogged(limit = 5, page = 1) {
    return this.get(`all/logged?limit=${limit}&page=${page}`)
  }

  public getPostsForPublic(limit = 5, page = 1) {
    return this.get(`all/public?limit=${limit}&page=${page}`)
  }

  public getPostsByTagForLogged(tagId: string, limit = 5, page = 1) {
    return this.get(`tags/${tagId}/logged?limit=${limit}&page=${page}`)
  }

  public getPostsByTagForPublic(tagId: string, limit = 5, page = 1) {
    return this.get(`tags/${tagId}/public?limit=${limit}&page=${page}`)
  }

  public getPostsByCategoryForLogged(categoryId: string, limit = 5, page = 1) {
    return this.get(
      `categories/${categoryId}/logged?limit=${limit}&page=${page}`
    )
  }

  public getPostsByCategoryForPublic(categoryId: string, limit = 5, page = 1) {
    return this.get(
      `categories/${categoryId}/public?limit=${limit}&page=${page}`
    )
  }

  public getPostsByAuthorForLogged(authorId: string, limit = 5, page = 1) {
    return this.get(`authors/${authorId}/logged?limit=${limit}&page=${page}`)
  }

  public getPostsByAuthorForPublic(authorId: string, limit = 5, page = 1) {
    return this.get(`authors/${authorId}/public?limit=${limit}&page=${page}`)
  }

  public getAuthors() {
    return this.get('authors')
  }

  public getNewPosts() {
    return this.get('new')
  }
  public getNewPostsForLogged() {
    return this.get('new/logged')
  }
  public getOnePost(id: string) {
    return this.get(`${id}`)
  }
  public getComments(postId: string) {
    return this.get(`comments/${postId}`)
  }
  public postComment(data: Record<string, unknown>) {
    return this.post('comments', data)
  }

  public getMoreForParent(id: string) {
    return this.get(`more/parent/${id}`)
  }

  public getMoreForNanny(id: string) {
    return this.get(`more/nanny/${id}`)
  }

  public getMoreForPublic(id: string) {
    return this.get(`more/public/${id}`)
  }
}

const apiProducts = new Posts()
export default apiProducts
