<template>
  <div class="mb-2 md:mb-6">
    <div class="flex h-max flex-col border bg-white md:rounded-lg">
      <div class="flex px-3 pt-5 pb-2 md:px-6 md:pb-6 md:pt-8">
        <div class="mr-4 flex items-start md:mr-6">
          <icon
            v-if="icon.length > 0"
            :name="icon"
            size="custom"
            class="h-12 w-12 text-primary-500 md:h-20 md:w-20"
          />
        </div>
        <div>
          <h5
            class="mb-2.5 text-sm font-bold uppercase text-secondary-700 md:text-base"
          >
            <slot name="title" />
          </h5>
          <p class="mb-4 text-sm font-normal text-gray-500">
            <slot name="description" />
          </p>
        </div>
      </div>
      <router-link
        class="relative flex items-center justify-between border-t py-3 px-3 text-right text-base font-bold text-danger-600 hover:bg-gray-100 md:px-6"
        :to="path"
      >
        <slot name="router-name" />
        <icon name="angleRightSolid" size="4" class="inline text-warning-600" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'ProfileCard',
  components: {
    Icon
  },
  props: {
    icon: {
      type: String,
      default: 'user',
      required: true
    },
    path: {
      type: String,
      required: true
    }
  }
})
</script>
