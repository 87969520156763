<template>
  <component
    :is="component"
    :disabled="disabled"
    v-bind="componentAttrs"
    class="inline-flex items-center justify-center whitespace-nowrap font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="{
      'flex-row-reverse': iconRight,
      'w-full': block,
      'disabled-true': disabled,
      [`color-${groupColor}-${groupOutline} size-${groupSize} rounded-${groupRounded} icononly-${groupSize}-${iconOnly} icononly-${groupSize}-${iconOnly}-${rounded}`]:
        group,
      [`color-${color}-${outline} outline-${outline} size-${size} rounded-${rounded}-${size} icononly-${size}-${iconOnly} icononly-${size}-${iconOnly}-${rounded} disabled-single-${disabled}`]:
        !group,
      transparent: transparent
    }"
  >
    <icon
      v-if="icon.length > 0"
      :name="icon"
      :class="
        group
          ? `icon-${groupSize}-${iconOnly} icon-${groupSize}-${iconOnly}-${iconRight} fill-current`
          : `icon-${size}-${iconOnly} icon-${size}-${iconOnly}-${iconRight} fill-current`
      "
    />
    <span v-if="!iconOnly" class="select-none"
      ><slot>{{ text }}</slot></span
    >
  </component>
</template>

<script lang="ts">
import { defineComponent, computed, inject } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'BtnAtom',
  components: { Icon },
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (v: string) =>
        [
          'primary',
          'secondary',
          'success',
          'danger',
          'info',
          'warning',
          'facebook',
          'google'
        ].includes(v)
    },
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v)
    },
    type: {
      type: String,
      default: 'button',
      validator: (v: string) => ['button', 'submit'].includes(v)
    },
    rounded: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    iconRight: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    block: {
      type: Boolean
    },
    to: {
      type: [String, Object],
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const groupSize = inject('size', 'md')
    let groupColor = inject('color', 'primary')
    const groupRounded = inject('rounded', false)
    const groupOutline = inject('outline', false)
    const group = inject('group', false)

    const component = computed(() => {
      if (props.to) return 'router-link'
      return 'button'
    })

    if (props.active) groupColor = 'primary'

    const componentAttrs = computed(() => {
      if (props.to) {
        return {
          to: props.to
        }
      }
      return {
        type: props.type
      }
    })

    return {
      component,
      componentAttrs,
      groupSize,
      groupColor,
      groupRounded,
      groupOutline,
      group
    }
  }
})
</script>

<style scoped lang="postcss">
/* rounded */
.rounded-false-xs {
  @apply rounded;
}
.rounded-false-sm,
.rounded-false-md,
.rounded-false-lg,
.rounded-false-xl {
  @apply rounded-md;
}
.rounded-true-xs,
.rounded-true-sm,
.rounded-true-md,
.rounded-true-lg,
.rounded-true-xl {
  @apply rounded-full;
}
.rounded-true:first-child {
  @apply rounded-l-full;
}
.rounded-true:last-child {
  @apply rounded-r-full;
}
.rounded-false:first-child {
  @apply rounded-l-lg;
}
.rounded-false:last-child {
  @apply rounded-r-lg;
}

/* colors */
.outline-true {
  @apply border bg-white hover:bg-opacity-40;
}
/* colors */

.color-primary-false {
  @apply border-primary-600 bg-primary-600 hover:bg-primary-500 focus:ring-primary-500;
}
.color-primary-true {
  @apply border-primary-600 text-primary-700 text-opacity-80 hover:bg-primary-100  hover:text-opacity-100 focus:ring-primary-500;
}

.disabled-true {
  @apply pointer-events-none cursor-default text-opacity-50 hover:bg-opacity-0 hover:text-opacity-50 !important;
}
/* single button - change border color when disabled */
.disabled-single-true {
  @apply border-opacity-20 !important;
}

.color-secondary-false {
  @apply border border-secondary-500 bg-secondary-600 hover:bg-secondary-700 focus:ring-secondary-500;
}
.color-secondary-true {
  @apply border border-secondary-600/30 text-secondary-800 text-opacity-80 hover:bg-gray-100  hover:text-opacity-100 focus:ring-secondary-500;
}

.color-success-false {
  @apply border-success-700 bg-success-600 hover:bg-success-700 focus:ring-success-500;
}
.color-success-true {
  @apply border-success-700 text-success-800 text-opacity-80 hover:bg-success-100  hover:text-opacity-100 focus:ring-success-500;
}

.color-warning-false {
  @apply border-warning-700 bg-warning-600 hover:bg-warning-700 focus:ring-warning-500;
}

.color-warning-true {
  @apply border-warning-700 text-warning-800 text-opacity-80 hover:bg-warning-100  hover:text-opacity-100 focus:ring-warning-500;
}

.color-danger-false {
  @apply border-danger-700 bg-danger-600 hover:bg-danger-700 focus:ring-danger-500;
}

.color-danger-true {
  @apply border-danger-700 text-danger-800 text-opacity-80 hover:bg-danger-100 hover:bg-opacity-30 hover:text-opacity-100 focus:ring-danger-500;
}

.color-info-false {
  @apply border-info-700 bg-info-600 hover:bg-info-700 focus:ring-info-500;
}

.color-info-true {
  @apply border-info-700 text-info-800 text-opacity-80 hover:bg-info-100  hover:text-opacity-100 focus:ring-info-500;
}

.color-facebook-false {
  @apply border-facebook-700 bg-facebook-600 hover:bg-facebook-700 focus:ring-facebook-500;
}

.color-facebook-true {
  @apply border-facebook-700 text-facebook-800 text-opacity-80 hover:bg-facebook-100 hover:bg-opacity-30 hover:text-opacity-100 focus:ring-facebook-500;
}

.color-google-false {
  @apply border-google-600 bg-google-500 hover:bg-google-600 focus:ring-google-400;
}

.color-google-true {
  @apply border-google-700 text-google-800 text-opacity-80 hover:bg-google-100 hover:bg-opacity-30 hover:text-opacity-100 focus:ring-google-500;
}

/* border left */
.border-l-false-false {
  @apply border-l border-white border-opacity-30;
}
.border-l-false-true {
  @apply border-l border-opacity-20;
}
.color-primary-true.rounded-r-false-true,
.color-secondary-true.rounded-r-false-true,
.color-info-true.rounded-r-false-true,
.color-warning-true.rounded-r-false-true,
.color-danger-true.rounded-r-false-true,
.color-success-true.rounded-r-false-true {
  @apply rounded-lg;
}
/* size */
.size-xs {
  @apply text-xs;
}
.size-sm {
  @apply text-sm leading-4;
}
.size-md {
  @apply text-sm;
}
.size-lg {
  @apply text-base;
}
.size-xl {
  @apply text-base;
}
.icononly-xs-true {
  @apply p-1;
}
.icononly-sm-true {
  @apply p-1.5;
}
.icononly-md-true {
  @apply p-2;
}
.icononly-lg-true {
  @apply p-2.5;
}
.icononly-xl-true {
  @apply p-3;
}
.icononly-xs-false-true {
  @apply px-3 py-1.5;
}
.icononly-sm-false-true {
  @apply px-3.5 py-2;
}
.icononly-md-false-true {
  @apply px-4 py-2;
}
.icononly-lg-false-true {
  @apply px-5 py-2;
}
.icononly-xl-false-true {
  @apply px-6 py-3;
}

.icononly-xs-false-false {
  @apply px-2.5 py-1.5;
}
.icononly-sm-false-false {
  @apply px-3 py-2;
}
.icononly-md-false-false {
  @apply px-4 py-2;
}
.icononly-lg-false-false {
  @apply px-4 py-2;
}
.icononly-xl-false-false {
  @apply px-6 py-3;
}
.icon-xs-false-true {
  @apply ml-2 -mr-0.5 h-3 w-3;
}
.icon-sm-false-true {
  @apply ml-2 -mr-0.5 h-3.5 w-3.5;
}
.icon-md-false-true {
  @apply ml-2 -mr-1 h-4 w-4;
}
.icon-lg-false-true {
  @apply ml-3 -mr-1 h-5 w-5;
}
.icon-xl-false-true {
  @apply ml-3 -mr-1 h-6 w-6;
}
.icon-xs-false-false {
  @apply -ml-0.5 mr-2 h-3 w-3;
}
.icon-sm-false-false {
  @apply -ml-0.5 mr-2 h-3.5 w-3.5;
}
.icon-md-false-false {
  @apply -ml-1 mr-2 h-4 w-4;
}
.icon-lg-false-false {
  @apply -ml-1 mr-3 h-5 w-5;
}
.icon-xl-false-false {
  @apply -ml-1 mr-3 h-6 w-6;
}
.icon-xs-true {
  @apply h-3 w-3;
}
.icon-sm-true {
  @apply h-3.5 w-3.5;
}
.icon-md-true {
  @apply h-4 w-4;
}
.icon-lg-true {
  @apply h-5 w-5;
}
.icon-xl-true {
  @apply h-6 w-6;
}
.transparent {
  @apply border-transparent bg-transparent shadow-none hover:bg-opacity-10;
}
.transparent.outline-true {
  @apply hover:bg-opacity-100;
}
</style>
