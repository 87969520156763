<template>
  <button
    class="relative inline-flex rounded-md bg-white text-secondary-400 hover:text-secondary-500 focus:outline-none focus:ring-2 focus:ring-secondary-300 focus:ring-offset-2"
  >
    <span class="sr-only">Close</span>
    <icon data-test="icon" name="timesSolid" :size="size" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'
export default defineComponent({
  name: 'CloseAtom',
  components: { Icon },
  props: {
    size: {
      type: String,
      default: '6'
    }
  }
})
</script>
