import LayoutDefault from '../layouts/LayoutBase.vue'
import LayoutSubpage from '../layouts/LayoutSubpage.vue'

const routes = {
  path: 'worktime',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: false
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Dostępność'
      },
      components: {
        default: LayoutSubpage
      },
      children: [
        {
          path: '',
          name: 'worktime',
          component: () => import('../views/worktime/WorktimeIndex.vue')
        }
      ]
    }
  ]
}

export default routes
