export default {
  details: {
    backlink: 'Ustawienia konta',
    title: 'Szczegóły płatności',
    subtitle: 'Szczegóły dotyczące transakcji',
    status: {
      new: 'Płatność rozpoczęta',
      completed: 'Gratulacje, Twoje zamówienie zostało opłacone!',
      pending: 'Płatność jest przetwarzana, sprawdź później',
      failed: 'Płatność nie powiodła się',
      canceled: 'Autoryzacja płatności zakonczona niepowodzeniem',
      none: 'Płatność nie istnieje lub transakcja została dodana przez panel admininstratora'
    },
    table: {
      date: 'Data zakupu',
      status: 'Status płatności',
      product: 'Wykupiona opcja członkostwa',
      price: 'Cena zakupu',
      payuId: 'Identyfikator payU'
    },
    noData: 'Brak danych!'
  }
}
