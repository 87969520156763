<template>
  <div class="mt-4 flex justify-center gap-4 md:flex-row md:pt-8">
    <div class="mt-6 basis-full text-center md:mt-0 md:basis-6/12">
      <h6 class="mb-4 text-base font-bold uppercase text-secondary-600">
        Wpisy
      </h6>
      <h2 class="mb-4 text-3xl font-semibold text-secondary-600">Nasz blog</h2>
    </div>
  </div>
  <div
    class="grid grid-cols-4 gap-6 py-6 text-secondary-600 md:grid-cols-12"
    v-if="newPosts.length > 0"
  >
    <div
      v-for="post in newPosts"
      :key="post.post_title"
      class="col-span-4 mb-8 text-center"
    >
      <img
        class="mx-auto mb-4 rounded-lg"
        :src="`${path}${post.photo_src}`"
        alt="..."
      />
      <router-link
        :to="`/blog/${post.id}`"
        class="mb-2 text-lg font-semibold uppercase"
      >
        {{ post.post_title }}
      </router-link>
      <div
        class="mb-4 mt-3 text-base font-normal text-secondary-500 line-clamp-3"
      >
        {{ post.post_description }}
      </div>
      <router-link :to="`/blog/${post.id}`">
        <btn outline color="secondary" text="Czytaj wpis" size="sm" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useAuthStore } from '@stores/auth'
import Btn from '@atoms/Btn'

import apiPosts from '@db/Posts'
import { Post } from '@db/Posts/interfaces'

export default defineComponent({
  components: {
    Btn
  },
  setup() {
    const newPosts = ref([] as Post[])
    const useAuth = useAuthStore()
    const id = useAuth.getUser?.id
    const path = import.meta.env.VITE_AWS_BUCKET

    const getNewPosts = async () => {
      const data = await apiPosts[id ? 'getNewPostsForLogged' : 'getNewPosts']()
      newPosts.value = data.data
    }

    onMounted(async () => {
      await getNewPosts()
    })
    return {
      getNewPosts,
      newPosts,
      path
    }
  }
})
</script>
