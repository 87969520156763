import LayoutDefault from '../layouts/LayoutBase.vue'
import LayoutSubpage from '../layouts/LayoutSubpage.vue'
const routes = {
  path: '/deals',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Współprace'
      },
      components: {
        default: LayoutSubpage
      },
      children: [
        {
          path: '',
          name: 'deals',
          component: () => import('../views/deals/DealsIndex.vue')
        },
        {
          path: 'view/:reviewId',
          name: 'dealsView',
          component: () => import('../views/deals/DealsView.vue')
        },
        {
          path: 'add/:dealId',
          name: 'addReview',
          component: () => import('../views/deals/DealsAddReview.vue')
        }
      ]
    }
  ]
}

export default routes
