<template>
  <div class="w-full">
    <span
      v-if="
        data.notification_type === 'nanny-invite' ||
        data.notification_type === 'nanny-invite-accept' ||
        data.notification_type === 'nanny-invite-reject'
      "
    >
      Niania
    </span>
    <span v-else> Rodzic </span>
    <span class="font-semibold">
      {{ data.notification_user_username }}
    </span>
    <span
      v-if="
        data.notification_type === 'nanny-invite' ||
        data.notification_type === 'parent-invite'
      "
    >
      zaprasza Cię do rozmowy o
    </span>
    <span
      v-else-if="
        data.notification_type === 'nanny-invite-accept' ||
        data.notification_type === 'parent-invite-accept'
      "
    >
      akceptuje Twoją propozycję rozmowy o
    </span>
    <span v-else> odrzuca Twoją propozycję rozmowy o </span>
    <span>
      <span class="font-semibold">{{ data.notification_item_title }}</span
      >.
    </span>
    <span
      v-if="
        data.notification_type === 'nanny-invite-accept' ||
        data.notification_type === 'parent-invite-accept'
      "
    >
      Możecie teraz wysyłać do siebie wiadomości.
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotificationInvites',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
})
</script>
