<template>
  <transition :name="position === 'top' ? 'slide-fade' : 'slide-fade-bottom'">
    <div
      v-if="showToast"
      class="pointer-events-auto mb-2 w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      :class="{
        'max-w-sm overflow-hidden':
          template === 'simple' ||
          template === 'condensed' ||
          template === 'action',
        'flex max-w-md': template === 'reply',
        'flex max-w-md divide-x divide-secondary-200':
          template === 'splitreply',
        'max-w-sm': template === 'buttons'
      }"
    >
      <div
        class="p-3"
        :class="{
          'w-0 flex-1': template === 'reply',
          'flex w-0 flex-1 items-center': template === 'splitreply'
        }"
      >
        <div
          :class="{
            ['flex items-start']:
              template === 'simple' ||
              template === 'action' ||
              template === 'reply' ||
              template === 'buttons',
            ['flex items-center']: template === 'condensed',
            ['w-full']: template === 'splitreply'
          }"
        >
          <!-- avatar or icon column -->
          <div
            v-if="avatar.length > 0 || icon.length > 0"
            class="flex-shrink-0"
            :class="{ 'pt-0.5': avatar.length > 0 }"
          >
            <div
              v-if="icon.length > 0"
              class="inline-flex h-10 w-10 items-center justify-center"
            >
              <icon :name="icon" class="h-7 w-7" :class="`text-${type}-500`" />
            </div>
            <avatar
              v-if="avatar.length > 0"
              :alt="title"
              size="sm"
              :path="avatar"
            />
          </div>
          <!-- content -->
          <div
            :class="{
              ['ml-3 w-0 flex-1']:
                template === 'simple' ||
                template === 'action' ||
                template === 'reply' ||
                template === 'buttons',
              ['pt-0.5']: template === 'simple' || template === 'action',
              ['flex w-0 flex-1 justify-between']: template === 'condensed'
            }"
          >
            <p
              class="text-base font-semibold text-secondary-600"
              :class="{
                ['w-0 flex-1']: template === 'condensed'
              }"
            >
              {{ title }}
            </p>
            <p
              v-if="template !== 'condensed' && msg.length > 0"
              class="mt-1 text-sm text-secondary-500"
            >
              {{ msg }}
            </p>
            <toast-btn
              v-if="template === 'condensed' && accept"
              template="condensed"
              type="accept"
              :text="accept.title"
              @click="toastAction('accept')"
            />
            <div v-if="template === 'action'" class="mt-2">
              <toast-btn
                data-test="toastBtnAction"
                template="action"
                type="accept"
                :text="accept.title"
                @click="toastAction('accept')"
              />
              <toast-btn
                data-test="toastBtnAction"
                template="action"
                type="decline"
                :text="decline.title"
                @click="toastAction('decline')"
              />
            </div>
            <div v-if="template === 'buttons'" class="mt-4 flex">
              <btn
                data-test="toastBtn"
                :text="accept.title"
                size="sm"
                @click="toastAction('accept')"
              />
              <btn
                data-test="toastBtn"
                :text="decline.title"
                size="sm"
                color="secondary"
                :outline="true"
                class="ml-3"
                @click="toastAction('decline')"
              />
            </div>
          </div>
          <!-- close -->
          <div
            v-if="template !== 'reply' && template !== 'splitreply'"
            class="ml-4 flex flex-shrink-0"
          >
            <close @click="close" />
          </div>
        </div>
      </div>
      <!-- reply for single reply -->
      <div
        v-if="template === 'reply'"
        class="flex border-l border-secondary-200"
      >
        <toast-btn
          template="reply"
          type="accept"
          :text="accept.title"
          @click="toastAction('accept')"
        />
      </div>
      <!-- buttons for splitreply -->
      <div v-if="template === 'splitreply'" class="flex">
        <div class="flex flex-col divide-y divide-secondary-200">
          <div class="flex h-0 flex-1">
            <toast-btn
              template="splitreply"
              type="accept"
              :text="accept.title"
              @click="toastAction('accept')"
            />
          </div>
          <div class="flex h-0 flex-1">
            <toast-btn
              template="splitreply"
              type="decline"
              :text="decline.title"
              @click="toastAction('decline')"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToastStore } from '@stores/toast'
import Avatar from '@atoms/Avatar'
import Btn from '@atoms/Btn'
import Close from '@atoms/Close'
import Icon from '@atoms/Icon'
import ToastBtn from '@atoms/ToastBtn'

export default defineComponent({
  name: 'ToastMolecule',
  components: {
    Btn,
    Avatar,
    Close,
    ToastBtn,
    Icon
  },
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    title: {
      type: String,
      default: '',
      required: true
    },
    msg: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary',
      validator: (v: string) =>
        [
          'primary',
          'secondary',
          'success',
          'danger',
          'info',
          'warning'
        ].includes(v)
    },
    template: {
      type: String,
      default: 'simple',
      validator: (v: string) =>
        [
          'simple',
          'condensed',
          'action',
          'reply',
          'splitreply',
          'buttons'
        ].includes(v)
    },
    duration: {
      type: Number,
      default: 3500
    },
    avatar: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    accept: {
      type: Object,
      default: () => ({})
    },
    decline: {
      type: Object,
      default: () => ({})
    },
    position: {
      type: String,
      default: 'top',
      validator: (v: string) => ['top', 'bottom'].includes(v)
    }
  },
  setup(props) {
    const toast = useToastStore()
    const router = useRouter()
    const showToast = ref(false)
    onMounted(() => {
      showToast.value = true
      if (props.duration > 0) {
        setTimeout(() => {
          showToast.value = false
        }, props.duration)
      }
    })
    const close = () => {
      showToast.value = false
      setTimeout(() => {
        toast.remove(props.id)
      }, 200)
    }
    const toastAction = function (type: string) {
      const btn = type === 'accept' ? props.accept : props.decline
      if (btn.action === 'close') {
        close()
      } else if (btn.action === 'route') {
        router.push(`${btn.value}`)
        close()
      } else {
        close()
        const value:
          | 'remove'
          | 'add'
          | 'clear'
          | 'updateAccept'
          | 'updateDecline'
          | 'clearAction' = btn.value
        toast[`${value}`](btn.args)
      }
    }
    return {
      showToast,
      close,
      toastAction
    }
  }
})
</script>

<style scoped lang="postcss">
.slide-fade-enter-active,
.slide-fade-bottom-enter-active,
.slide-fade-leave-active,
.slide-fade-bottom-leave-active {
  transition: all 200ms cubic-bezier(0.17, 0.67, 0.17, 0.98);
}
.slide-fade-enter-from,
.slide-fade-bottom-leave-to {
  transform: translateY(20px);
}
.slide-fade-leave-to,
.slide-fade-bottom-enter-from {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
