<template>
  <div
    class="relative inline-flex h-12 w-12 cursor-pointer items-center justify-center whitespace-nowrap rounded-full text-secondary-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
  >
    <icon v-if="icon.length > 0" :name="icon" size="7" />
    <span
      v-if="counter > 0"
      class="absolute top-1 right-1 flex h-5 w-5 items-center justify-center rounded-full bg-danger-500 text-[11px] text-white ring-2 ring-white"
      >{{ counter > 99 ? '99' : counter }}</span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'BtnAtom',
  components: { Icon },
  props: {
    icon: {
      type: String,
      required: true,
      default: ''
    },
    counter: {
      type: Number,
      default: 4
    }
  }
})
</script>
