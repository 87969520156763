export default {
  profile: {
    back: 'Wróć do poprzedniej strony',
    invite: 'Zaproś do rozmowy',
    man: 'Mężczyzna',
    woman: 'Kobieta',
    age: 'lat',
    profileIs: 'Profil jest',
    active: 'aktywny',
    unActive: 'nieaktywny',
    addfavorite: 'Dodaj do ulubionych',
    removefavorite: 'Usuń z ulubionych',
    favorite: {
      success: {
        title: 'Sukces!',
        msg: 'Niania została dodana do ulubionych.'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Niania nie została dodana do ulubionych.'
      }
    },
    unfavorite: {
      success: {
        title: 'Sukces!',
        msg: 'Niania została usunięta z listy ulubionych.'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Niania nie została usunięta z listy ulubionych.'
      }
    },
    about: {
      title: 'O niani',
      subheading: 'Odległość od niani',
      description:
        'Dystans do miejsca zamieszkania niani to około {distance} km'
    },
    attrs: {
      title: 'Cechy',
      subheading: 'Cechy niani',
      description: 'Zapoznaj się z charakterystyką opiekunki.',
      noattrs: 'Ta niania nie dodała jeszcze swoich cech.'
    },
    worktime: {
      title: 'Godziny pracy',
      subheading: 'Godziny dostępności niani',
      description:
        'Poniższe godziny zostały zadeklarowane jako ważne do {date}. Pamiętaj o tym, że godziny dostępności niani mogą się zmienić.',
      noworktime: 'Niania nie dodała godzin pracy.'
    },
    reference: {
      title: 'Referencje',
      subheading: 'Referencje',
      description: 'Niania może dodać referencje dotyczące swojej współpracy.',
      noreference: 'Niania nie dodała żadnych referencji'
    },
    reviews: {
      subheading: 'Ocena współpracy',
      description: 'Po każdej zakończonej współpracy można ją ocenić.',
      noreviews: 'Niania nie otrzymała jescze żadnych ocen współpracy.'
    }
  },
  invite: {
    superscription: 'Wróć do poprzedniej strony',
    title: 'Zaproś do kontaktu',
    description: 'Zaproponuj niani rozmowę na temat wybranego ogłoszenia.',
    profileIs: 'Profil jest',
    active: 'aktywny',
    inactive: 'nieaktywny',
    checkProfileBtn: 'Zobacz profil',
    whichAd: 'O którym ogłoszeniu chcesz porozmawiać z nianią?',
    inviteBtn: 'Zaproś do rozmowy w sprawie ogłoszenia powyżej',
    inviteWithoutAdBtn: 'Zaproś do rozmowy bez ogłoszenia',
    inviteSuccess: {
      title: 'Sukces!',
      msg: 'Niania została zaproszona. Powiadomimy Ciebie jeśli zaakceptuje zaproszenie.'
    },
    inviteAlreadySentByParent: {
      title: 'Już zaproszono',
      msg: 'Niania została już zaproszona wcześniej do wybranego ogłoszenia.'
    },
    inviteAlreadySentByNanny: {
      title: 'Już zaproszono',
      msg: 'Zaproszenie do tego ogłoszenia już zostało do Ciebie wysłane. Zaakceptuj ofertę kontaktu i przejdź do rozmowy by omówić szczegóły współpracy.'
    }
  },
  nonannies: 'Nie znaleziono żadnej niani',
  age: 'lat',
  distance: '{distance} km od Twojej lokalizacji',
  unfavorite: {
    success: 'Niania została usunięta z listy ulubionych',
    fail: 'Niania nie została usunięta z listy ulubionych'
  },
  isfree: {
    title: 'Rozszerz możliwości swojego konta',
    msg: 'Tylko użytkownicy premium mogą zapraszać nianie do rozmowy'
  },
  premium: 'Przejdź na premium!',
  close: 'Zamknij',
  lastActivity: 'Ostatnio aktywny/a:'
}
