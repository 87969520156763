export default {
  superscription: 'Aktywność',
  title: 'Oferty kontaktu',
  description: 'Twoje zaproszenia i oferty kontaktu w sprawie ogłoszeń.',
  noOffers: 'Nie masz jeszcze dodanych ofert w:',
  all: 'Wszystkie',
  sent: 'Wysłane',
  received: 'Otrzymane',
  accepted: 'Zaakceptowane',
  rejected: 'Odrzucone',
  change: {
    success: {
      title: 'Sukces!',
      msg: 'Status zaproszenia został zmieniony'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'Status zaproszenia nie został zmieniony'
    }
  },
  remove: {
    success: {
      title: 'Sukces!',
      msg: 'Zaproszenie zostało usunięte'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'Zaproszenie nie zostało usunięte'
    }
  }
}
