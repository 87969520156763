import { defineStore } from 'pinia'
interface NavbarData {
  text?: string
  to?: string
  toText?: number | string
}
export const useGlobalsStore = defineStore('globals', {
  state: () => ({
    navbarData: {} as NavbarData,
    currentItem: {},
    backLinkAddress: {
      jobsAnnouncment: '',
      jobsProfile: '',
      inviteNanny: '',
      nannyProfile: ''
    },
    accountActiveTab: 1
  }),
  getters: {
    getNavbarData(state) {
      return state.navbarData
    },
    getCurrentItem(state) {
      return state.currentItem
    },
    getBacklinkAddress(state) {
      return state.backLinkAddress
    },
    getAccountActiveTab(state) {
      return state.accountActiveTab
    }
  },
  actions: {
    async updateNavbarData(val: object) {
      this.navbarData = val
    },
    async updateCurrentItem(val: object) {
      this.currentItem = val
    },
    async updateAccountActiveTab(val: number) {
      this.accountActiveTab = val
    }
  }
})
