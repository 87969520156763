<template>
  <div v-if="isMainFilterPage && shouldRender">
    <h5
      class="relative hidden rounded-t-lg border-x border-t bg-white py-4 px-8 text-xl text-secondary-500 lg:block"
    >
      {{ $t('sidebar.filters.searchFilters') }}
    </h5>
    <div v-if="isLoading">
      <attr-skeleton v-for="i in 6" :key="i" />
    </div>
    <div v-else>
      <attr-item-small
        v-for="filter in mainAttrs"
        :key="filter.id"
        :attr-type="isParent ? 'nanny' : 'ad'"
        :data="filter"
        :is-parent="isParent"
        :sidebar="sidebar"
        @change-value="updateAttrs($event, filter.id)"
        @update-availability="save"
      />
      <div
        class="overflow-hidden transition-all"
        :class="{
          'max-h-0': !isExpanded,
          'max-h-[9999px]': isExpanded,
          'lg:hidden': sidebar
        }"
      >
        <attr-item-small
          v-for="filter in additionalAttrs"
          :key="filter.id"
          :attr-type="isParent ? 'nanny' : 'ad'"
          :data="filter"
          :is-parent="isParent"
          :sidebar="sidebar"
          @change-value="updateAttrs($event, filter.id)"
          @update-availability="save"
        />
      </div>
    </div>
    <div
      v-if="additionalAttrs?.length > 0"
      class="w-full cursor-pointer border-x border-t bg-white pt-2 text-center text-sm font-bold text-secondary-500 lg:w-auto"
      :class="{
        'hidden lg:block': !sidebar,
        'pb-20 lg:pb-2': !isMobile,
        'px-4 pb-32 md:pb-44 lg:pb-2': isMobile
      }"
      @click="isExpanded = !isExpanded"
    >
      {{
        !isExpanded
          ? $t('sidebar.filters.showMore')
          : $t('sidebar.filters.showLess')
      }}
    </div>
    <div
      class="fixed bottom-0 w-full rounded-b-lg border-t border-gray-200 bg-white px-6 pt-4 pb-4 lg:static lg:w-auto lg:border"
      :class="{
        'hidden lg:block': !sidebar
      }"
    >
      <btn
        :text="$t('sidebar.filters.btn')"
        color="secondary"
        size="lg"
        :disabled="disabledBtn"
        @click="save"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useAuthStore } from '@stores/auth'
import { useFiltersStore } from '@stores/filters'
import { useRoute } from 'vue-router'
import { useMediaQuery } from '@vueuse/core'
import AttrItemSmall from '@portal/AttrItemSmall'
import AttrSkeleton from '@portal/AttrSkeleton'
import Btn from '@atoms/Btn'

import apiAds from '@db/Announcments'
import apiFilters from '@db/Filters'
import apiSearch from '@db/Search'
import {
  Attr,
  DataObject,
  SelectedItems,
  DataToSend
} from '@db/Filters/interfaces'

export default defineComponent({
  components: {
    AttrItemSmall,
    Btn,
    AttrSkeleton
  },
  props: {
    sidebar: Boolean
  },
  emits: ['close'],
  setup(props, { emit }) {
    const filterState = useFiltersStore()
    const disabledBtn = ref(true)
    const useAuth = useAuthStore()
    const isParent = useAuth.isParent
    const route = useRoute()
    const attrs = ref([] as Attr[])
    const allAttrs = ref([] as Attr[])
    const isLoading = ref(true)
    const state = ref([] as DataObject[])
    const valuesState = ref([] as DataToSend[])
    const isExpanded = ref(false)
    const isMobile = navigator.userAgentData?.mobile

    const isLargeScreen = useMediaQuery('(min-width: 1024px)')

    const shouldRender = computed(() => {
      return props.sidebar ? !isLargeScreen.value : isLargeScreen.value
    })

    const mainAttrs = ref()
    const additionalAttrs = ref()

    watch(
      () => route.name,
      async () => {
        if (isMainFilterPage.value) {
          isLoading.value = true
          await getAllData()
          disabledBtn.value = true
          await nextTick()
          isLoading.value = false
        } else {
          allAttrs.value = []
          attrs.value = []
          mainAttrs.value = []
          additionalAttrs.value = []
          state.value = []
          valuesState.value = []
        }
      }
    )

    const readAllVals = async () => {
      if (isParent) {
        const dVals = await apiFilters.readAllVals()
        attrs.value = dVals.data
      } else {
        const dVals = await apiSearch.readAdsFilter()
        attrs.value = dVals.data
      }
    }

    const addValsToState = () => {
      for (const el of state.value) {
        for (const item of attrs.value) {
          if (item.id === el.id) {
            el.values = item.values
          }
        }
      }
    }

    const readAllFilters = async () => {
      if (isParent) {
        const d = await apiFilters.readAllForParent()
        allAttrs.value = d.data
      } else {
        const d = await apiAds.readAllAttrs()
        allAttrs.value = d.data
      }
    }

    const readAllOptions = async () => {
      for (const el of allAttrs.value) {
        const dOpts = (await apiFilters.readAllOptions(el.id.toString())) as {
          data: SelectedItems[]
        }
        if (el.attribute_field_type === 'select') {
          dOpts.data.unshift({
            id: 0,
            attribute_value: '',
            attribute_option: ''
          })
        }
        const newStateElement = {
          ...el,
          options: [...dOpts.data]
        }
        state.value.push(newStateElement)
      }
    }

    const getAllData = async () => {
      await readAllVals()
      await readAllFilters()
      await readAllOptions()
      await addValsToState()
      if (isParent) {
        mainAttrs.value = state.value.filter(
          (a) => a.attribute_basic_nanny === 1
        )
        additionalAttrs.value = state.value.filter(
          (a) => a.attribute_basic_nanny !== 1
        )
      } else {
        mainAttrs.value = state.value.filter((a) => a.attribute_basic_ad === 1)
        additionalAttrs.value = state.value.filter(
          (a) => a.attribute_basic_ad !== 1
        )
      }
    }

    const updateAttrs = (
      data: { values: string[]; attribute_field_type: string },
      id: number
    ) => {
      const newEntry = ref({} as DataToSend)
      newEntry.value.id = id
      newEntry.value.attribute_field_type = data.attribute_field_type
      newEntry.value.values = data.values

      const index = valuesState.value.findIndex((x) => x.id === id)
      if (index === -1) {
        valuesState.value.push(newEntry.value)
      } else {
        valuesState.value[index] = newEntry.value
      }
      disabledBtn.value = false
    }

    onMounted(async () => {
      if (!isMainFilterPage.value) return
      isLoading.value = true
      await getAllData()
      isLoading.value = false
      await nextTick()
      disabledBtn.value = true
    })

    const save = async () => {
      valuesState.value.map((el) => {
        const { values } = el
        if (values && values[0] === '') {
          el.values = []
        }
      })

      const nannyForNowId = state.value.find(
        (el) => el.attribute_tag === 'NianiaNaJuż'
      )?.id
      const nannyForNowValue = valuesState.value.find(
        (el) => el.id === nannyForNowId
      )?.values

      if (nannyForNowValue) {
        if (nannyForNowValue!.length < 1) {
          const nannyAvaibilityId = state.value.find(
            (el) => el.attribute_tag === 'DostepnoscNaJuż'
          )?.id
          const nannyAvaibilityIndex = valuesState.value.findIndex(
            (el) => el.id === nannyAvaibilityId
          )
          valuesState.value[nannyAvaibilityIndex].values = []
        }
      }

      if (isParent) {
        await apiFilters.updateFilters({ ...valuesState.value })
      } else {
        await apiSearch.updateAdsFilter({ ...valuesState.value })
      }
      filterState.change()
      disabledBtn.value = true
      emit('close')
    }

    const showFilters = computed(
      () => !isParent || (isParent && route.name !== 'jobsAd')
    )
    const isMainFilterPage = computed(
      () => route.name === 'jobs' || route.name === 'nannies'
    )

    return {
      disabledBtn,
      isParent,
      save,
      isLoading,
      updateAttrs,
      state,
      valuesState,
      showFilters,
      mainAttrs,
      additionalAttrs,
      isExpanded,
      isMainFilterPage,
      shouldRender,
      isMobile
    }
  }
})
</script>
