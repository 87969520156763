<template>
  <div class="w-screen bg-[#242b35]">
    <div class="mx-auto h-full px-4 lg:max-w-7xl lg:px-6">
      <div
        class="flex flex-col gap-x-6 gap-y-6 pb-3 pt-8 font-normal text-white opacity-80 md:flex-row"
      >
        <div
          class="flex basis-full flex-col items-center justify-center md:basis-4/12"
        >
          <div class="mb-3 text-sm font-semibold uppercase opacity-75">
            {{ $t('footer.aboutapp.title') }}
          </div>
          <ul
            class="flex flex-row flex-wrap gap-4 text-sm leading-8 text-primary-500"
          >
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.aboutapp.link1') }}
              </router-link>
            </li>
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.aboutapp.link2') }}
              </router-link>
            </li>
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.aboutapp.link3') }}
              </router-link>
            </li>
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.aboutapp.link4') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div
          class="flex basis-full flex-col items-center justify-center md:basis-4/12"
        >
          <div class="mb-3 text-sm font-semibold uppercase opacity-75">
            {{ $t('footer.forparent.title') }}
          </div>
          <ul class="flex flex-row gap-4 text-sm leading-8 text-primary-500">
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.forparent.link1') }}
              </router-link>
            </li>
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.forparent.link2') }}
              </router-link>
            </li>
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.forparent.link3') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div
          class="flex basis-full flex-col items-center justify-center md:basis-4/12"
        >
          <div class="mb-3 text-sm font-semibold uppercase opacity-75">
            {{ $t('footer.fornanny.title') }}
          </div>
          <ul class="flex flex-row gap-4 text-sm leading-8 text-primary-500">
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.fornanny.link1') }}
              </router-link>
            </li>
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.fornanny.link2') }}
              </router-link>
            </li>
            <li>
              <router-link
                class="hover:text-primary-500 hover:underline"
                to="/"
              >
                {{ $t('footer.fornanny.link3') }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="flex w-full flex-col justify-between gap-x-6 gap-y-2 pb-5 font-normal text-white opacity-80"
      >
        <div
          class="col-span-12 my-3 border-b border-solid border-white opacity-20"
        />
        <div class="flex flex-col justify-center gap-4 md:flex-row">
          <div class="basis-full text-center md:basis-5/12 lg:text-left">
            <span class="opacity-75">{{ $t('footer.needinfo') }} - </span>
            <a href="tel:+48XXXXXXXXX" class="text-primary-500"
              >+48 XXX XXX XXX</a
            >
          </div>
          <div
            class="mx-0 flex basis-full justify-center md:mx-6 md:basis-2/12 lg:col-span-2"
          >
            <logo />
          </div>
          <div
            class="flex basis-full items-start justify-center md:basis-5/12 md:justify-end lg:col-span-5"
          >
            <ul
              class="text-normal flex gap-4 text-primary-500 first-letter:gap-3"
            >
              <li>
                <router-link
                  class="hover:text-primary-500 hover:underline"
                  to="/blog"
                >
                  {{ $t('footer.bottommenu.link1') }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="hover:text-primary-500 hover:underline"
                  to="/privacy"
                >
                  {{ $t('footer.bottommenu.link2') }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="hover:text-primary-500 hover:underline"
                  to="/rules"
                >
                  {{ $t('footer.bottommenu.link3') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-span-12 text-center text-sm font-light opacity-50">
          2022 &copy; znananiania.pl. {{ $t('footer.copy') }}.
        </div>
        <div class="col-span-12 text-center text-xs font-light opacity-50">
          version {{ ver }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Logo from '@atoms/Logo'
import { version } from '../../../../package.json'

export default defineComponent({
  name: 'BtnAtom',
  components: { Logo },
  setup() {
    const ver = version
    return {
      ver
    }
  }
})
</script>
