import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Axios } from './Axios'

class AbstractDB {
  tableName: string
  endPath: string
  api: Promise<AxiosInstance>

  constructor(tableName: string) {
    this.tableName = tableName
    const axios = new Axios()
    this.api = axios.getHttp()
    this.endPath = ''
  }

  getTableName() {
    return this.tableName
  }

  protected async post(
    path: string,
    obj: Record<string, unknown>,
    confg?: AxiosRequestConfig
  ) {
    this.setPathName(path)
    return (await this.api).post(`${this.tableName}${this.endPath}`, obj, confg)
  }

  protected async get(path: string, confg?: AxiosRequestConfig) {
    this.setPathName(path)
    return (await this.api).get(`${this.tableName}${this.endPath}`, confg)
  }

  protected async put(
    path: string,
    obj: Record<string, unknown>,
    confg?: AxiosRequestConfig
  ) {
    this.setPathName(path)
    return (await this.api).put(`${this.tableName}${this.endPath}`, obj, confg)
  }

  protected async patch(
    path: string,
    obj: Record<string, unknown>,
    confg?: AxiosRequestConfig
  ) {
    this.setPathName(path)
    return (await this.api).patch(
      `${this.tableName}${this.endPath}`,
      obj,
      confg
    )
  }

  protected async delete(path: string, confg?: AxiosRequestConfig) {
    this.setPathName(path)
    return (await this.api).delete(`${this.tableName}${this.endPath}`, confg)
  }

  private setPathName(path: string): void {
    this.endPath = path.length > 0 ? `/${path}` : ''
  }
}

export default AbstractDB
