<template>
  <textarea
    v-bind="$attrs"
    v-model="model"
    :name="fieldName"
    :id="fieldName"
    :disabled="disabled"
    :required="required"
    rows="3"
    class="block w-full rounded-md border shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
    :class="{
      'px-1 py-1 text-xs': size === 'xs' || fieldSize === 'xs',
      'px-2 py-1 text-sm': size === 'sm' || fieldSize === 'sm',
      'text-md px-3 py-2': size === 'md' || fieldSize === 'md',
      'px-4 py-3 text-lg': size === 'lg' || fieldSize === 'lg',
      'border-secondary-300 focus:border-primary-500 focus:ring-primary-500':
        !v.$error,
      'border-danger-500 focus:border-danger-500 focus:ring-red-500': v.$error
    }"
    @blur="v.$touch"
  />
</template>

<script lang="ts">
import Validation from '@quarks/Validation'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormTextareaAtom',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v)
    },
    required: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { v, fieldSize, fieldName, useValidation } = Validation()
    const model = useValidation(props, emit) as unknown as string

    return {
      model,
      v,
      fieldSize,
      fieldName
    }
  }
})
</script>
