import AbstractDB from '../AbstractDB'

export class Hashtags extends AbstractDB {
  constructor() {
    super('hashtags')
  }
  public readAll() {
    return this.get('')
  }
  public readAllById(id: string) {
    return this.get(id)
  }
  public readOptions(id: number) {
    return this.get(`values/${id}`)
  }
  public updateHashtags(data: Record<string, unknown>) {
    return this.patch('values', data)
  }
}

const apiHashtags = new Hashtags()
export default apiHashtags
