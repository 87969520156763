import AbstractDB from '../AbstractDB'

export class Search extends AbstractDB {
  constructor() {
    super('search')
  }
  public readAllNannies(
    limit = 6,
    page = 1,
    distance = 5,
    sort_type = 'asc',
    sort = 'distance'
  ) {
    return this.get(
      `nannies?limit=${limit}&page=${page}&distance=${distance}&sort_type=${sort_type}&sort=${sort}`
    )
  }
  public readAllAds(
    limit = 6,
    page = 1,
    distance = 5,
    sort_type = 'asc',
    sort = 'distance'
  ) {
    return this.get(
      `announcements?limit=${limit}&page=${page}&distance=${distance}&sort_type=${sort_type}&sort=${sort}`
    )
  }
  public readAdsFilter() {
    return this.get('announcements/filters')
  }
  public updateAdsFilter(data: Record<string, unknown>) {
    return this.patch('announcements/filters', data)
  }
}

const apiSearch = new Search()
export default apiSearch
