<script lang="ts">
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'FormLegendAtom',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return () =>
      h(
        'legend',
        {
          class: 'font-medium text-secondary-700 text-lg mb-4'
        },
        props.text
      )
  }
})
</script>
