<template>
  <popper
    arrow
    :tooltip-class="tooltipClass"
    :show="display"
    :offset-skid="offsetSkid"
    :placement="placement"
  >
    <slot />
    <template #content>
      <div class="rounded-md bg-white">
        <slot name="content" />
      </div>
    </template>
  </popper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Popper from '@atoms/Popper'

export default defineComponent({
  name: 'DropdownMolecule',
  components: {
    Popper
  },
  props: {
    placement: {
      type: String,
      default: 'bottom',
      validator: function (value: string) {
        return [
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'right',
          'right-start',
          'right-end',
          'left',
          'left-start',
          'left-end'
        ].includes(value)
      }
    },
    tooltipClass: {
      type: String,
      default:
        'relative shadow-lg ring-1 ring-black ring-opacity-5 rounded-md bg-white z-20'
    },
    display: {
      type: Boolean,
      default: true
    },
    offsetSkid: {
      type: String,
      default: '0'
    }
  }
})
</script>
