<template>
  <div class="w-full">
    <span class="font-semibold">
      {{ data.notification_user_username }}
    </span>
    <span v-if="data.notification_type === 'parent-deal-review'">
      ocenił/a Twoją pracę przy
    </span>
    <span v-else> ocenił/a współpracę z Tobą przy </span>
    <span class="font-semibold">
      {{ data.notification_item_title }}
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotificationReviews',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
})
</script>
