export default {
  main: {
    title: 'Ustawienia konta',
    description:
      'Tutaj znajdują się wszystkie najważniesze ustawienia dotyczące konta. Możesz je aktualizować i przeglądać.',
    infoText: 'Moje Konto'
  },
  membership: {
    title: 'Członkostwo',
    description: 'Opcje członkostwa',
    back: 'Wróć do poprzedniej strony',
    testimonial: {
      title: 'Po aktywacji konta Premium',
      parent:
        'odblokowuje się możliwość publikacji ogłoszeń oraz nawiązywania kontaktu z Nianią.',
      nanny: 'odblokowuje się możliwość nawiązania kontaktu z Rodzicem.'
    }
  },
  tabs: {
    tab1: 'Ustawienia',
    tab2: 'Adresy',
    tab3: 'Płatności'
  },
  settings: {
    title: 'USTAWIENIA OSOBISTE',
    description:
      'Zadbaj o to by uzupełnić swój profil niezbędnymi dla nas informacjami.'
  },
  avatar: {
    mainTitle: 'Mój avatar',
    text: 'Avatar jest to Twoje zdjecie profilowe, które widzą inni użytkownicy Znanej Niani.',
    btnGo: 'Zmień swoje zdjęcie',
    title: 'Ustawienia avatara',
    description: 'Maksymalny rozmiar zdjęcia: 5 MB.',
    otherProvider:
      'Logowałeś/łaś się za pośrednictwem {provider}. Jeśli chcesz zmienić awatar - zrób to za pośrednictwem tego portalu.',
    btn: {
      change: 'Zmień avatar',
      accept: 'Akceptuj',
      delete: 'Usuń',
      decline: 'Anuluj',
      reset: 'Reset'
    },
    back: 'Moje konto',
    toast: {
      success: {
        title: 'Sukces!',
        msg: 'Avatar został zmieniony'
      },
      fail: {
        title: 'Coś poszło nie tak!',
        msg: 'Avatar nie został zmieniony'
      }
    }
  },
  password: {
    mainTitle: 'Zmiana hasła',
    mainText:
      'Hasło jest Twoją osobistą rzecz, której nie możesz udostępniać. Hasło powinno być zmieniane regularnie.',
    btnGo: 'Zmień swoje hasło',
    title: 'Ustawienia hasła',
    description:
      'Hasło jest Twoją osobistą rzecz, której nie możesz udostępniać. Hasło powinno być zmieniane regularnie.',
    back: 'Moje konto',
    toast: {
      success: {
        title: 'Sukces!',
        msg: 'Hasło zostało zmienione'
      },
      fail: {
        title: 'Coś poszło nie tak!',
        msg: 'Hasło nie zostało zmienione'
      }
    }
  },
  phone: {
    mainTitle: 'Numer telefonu',
    mainText:
      'Numer telefonu wykorzystujemy do bezpośredniego kontaktu i informacji wysyłanych za SMS.',
    btnText: 'Podaj numer telefonu',
    title: 'Numer telefonu',
    description:
      'Podaj numer swojego numeru kontaktowego. Numer telefonu wykorzystujemy do bezpośredniego kontaktu i informacji wysyłanych za pomocą SMS.',
    back: 'Moje konto',
    update: {
      success: {
        title: 'Sukces!',
        msg: 'Numer telefonu został zapisany'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Numer telefonu nie został zapisany'
      }
    }
  },
  subscription: {
    update: {
      success: {
        title: 'Sukces!',
        msg: 'Ustawienia subskrypcji zostały zmienione'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Ustawienia subskrypcji nie zostały zmienione'
      }
    },
    mainTitle: 'Subskrypcja',
    mainText:
      'Ustawienie ważnych informacji technicznych i marketingowych wysyłanych do Ciebie.',
    btnText: 'Ustaw subskrypcje',
    title: 'Ustawienie subskrypcji',
    description:
      'Dzięki ustawieniu subkrypcji będziesz od Nas otrzymywać ciekaw informacje na tematy związane z działalności Znanej Niani.',
    back: 'Moje konto'
  },
  logs: {
    mainTitle: 'Powiadomienia',
    mainText:
      'Ustawienie systemowych powiadomień mailowych wysyłanych do Ciebie.',
    btnText: 'Ustaw powiadomienie',
    title: 'Ustawienie powiadomień',
    description:
      'Dzięki ustawieniu powiadomień otrzymasz tylko maile z wybranych rodzajów aktywności.',
    back: 'Moje konto',
    success: {
      title: 'Sukces!',
      msg: 'Zmiana ustawień powiadomień powiodła się'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'Zmiana ustawień powiadomień nie powiodła się'
    }
  },
  tags: {
    mainTitle: 'Hashtagi',
    mainText: 'Wybierz hastagi którego najlepiej Cię opisują i #dajsieznalezc',
    btnText: 'Ustaw hashtagi',
    title: 'Hashtagi',
    description: 'Hashtagi pozwalają na lepsze dopsowanie niań i ogłoszeń',
    back: 'Wróć do poprzedniej strony',
    noTags: 'Brak tagów',
    save: 'Zapisz zmiany',
    toasts: {
      update: {
        success: 'Dane zostały zmienione',
        fail: 'Dane nie zostały zmienione'
      }
    }
  },
  references: {
    mainTitle: 'Referencje',
    mainText:
      'Możesz przesłac referencje, które potwierdzą Twoje kwalifikacje.',
    btnText: 'Edytuj referencje',
    title: 'Ustawienie referencji',
    description: 'Referencje pozwolą zachęcić rodziców do współpracy',
    back: 'Moje konto',
    btn: 'Prześlij referencje',
    yourReferences: 'Twoje referencje',
    remove: 'Usuń',
    referenceName: 'Nazwa referencji',
    referenceFile: 'Plik referencji',
    update: {
      success: {
        title: 'Sukces!',
        msg: 'Referencja została dodana! Będzie widoczna po zaakceptowaniu przez administratora'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Referencja nie została dodana'
      }
    },
    delete: {
      success: {
        title: 'Sukces!',
        msg: 'Referencja została usunięta'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Referencja nie została usunięta'
      }
    },
    upload: {
      fail: {
        title: 'Coś poszło nie tak',
        msg: 'Plik przekracza dopuszczalny rozmiar (5mb)'
      }
    },
    noReferences: 'Nie dodano jeszcze żadnych referencji',
    unconfirmed: 'Oczekujące na zatwierdzenie:'
  },
  visible: {
    mainTitle: 'Widoczność w wyszukiwarce',
    mainText:
      'Możesz określić czy Twój profil jest widoczny w wyszikiwarcy, lub czy chcesz go czasowo wyłączyć.',
    btnText: 'Ustaw widoczność',
    title: 'Widoczność w wyszukiwarce',
    description:
      'Możesz określić czy Twój profil jest widoczny w wyszukiwarce, lub czy chcesz go czasowo wyłączyć.',
    back: 'Moje konto',
    success: {
      title: 'Sukces!',
      msg: 'Status widoczności został zmieniony'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'Status widoczności nie został zmieniony'
    },
    isfree: {
      title: 'Rozszerz możliwości swojego konta',
      msg: 'Tylko użytkownicy premium mogą zmienić status na widoczny'
    },
    premium: 'Przejdź na premium!',
    close: 'Zamknij'
  },
  birth: {
    mainTitle: 'Data urodzenia i płeć',
    mainText:
      'Data urodzenia jest ważna, ponieważ z serwisu mogą korzystać jedynie osoby pełnoletnie.',
    btnText: 'Zmień datę urodzenia i płeć',
    title: 'Twoja data urodzenia i płeć',
    description:
      'Data urodzenia jest ważna, ponieważ z serwisu mogą korzystać jedynie osoby pełnoletnie.',
    back: 'Moje konto',
    toast: {
      success: {
        title: 'Sukces!',
        msg: 'Dane zostały zmienione'
      },
      fail: {
        title: 'Coś poszło nie tak!',
        msg: 'Dane nie zostały zmienione'
      }
    }
  },
  address: {
    main: {
      title: 'Adres podstawowy',
      description:
        'Twój adres zameldowania, używany do rozliczeń i fakturowania.',
      back: 'Ustawienia',
      noAddress:
        'Aktualnie nie masz dodanego adresu podstawowego, który wymagany jest do korzystania z serwisu.',
      createAddress: 'Utwórz adres podstawowy',
      otherTitle: 'Adres dodatkowy',
      otherSubTitle: 'Twoje pozostałe adresy',
      editAddressButton: 'Edytuj adres podstawowy',
      whyAddress1: 'Dowiedz się więcej na temat tego',
      whyAddress2: 'dlaczego potrzebujemy Twój adres'
    },
    additional: {
      title: 'Adres dodatkowy',
      description: 'Twój adres dodatkowy to adres zamieszkania.',
      createAddress: 'Utwórz adres dodatkowy',
      deleteAddress: 'Usuń',
      editAddressButton: 'Edytuj adres dodatkowy',
      noAddress: 'Nie masz dodatkowego adresu',
      back: 'Moje konto'
    },
    update: {
      toasts: {
        success: {
          title: 'Sukces!',
          msg: 'Adres został zmieniony'
        },
        fail: {
          title: 'Coś poszło nie tak',
          msg: `Wprowadzono nieprawidłowy adres.
          Proszę sprawdzić poprawność adresu.`,
          btn: 'Rozumiem'
        }
      }
    },
    create: {
      toasts: {
        success: {
          title: 'Sukces!',
          msg: 'Adres został utworzony'
        },
        fail: {
          title: 'Coś poszło nie tak',
          msg: `Wprowadzono nieprawidłowy adres.
          Proszę sprawdzić poprawność adresu.`,
          btn: 'Rozumiem'
        }
      }
    }
  },
  payments: {
    title: 'OPCJE CZŁONKOSTWA',
    list: 'Lista płatności',
    payment1: 'Data transakcji: ',
    payment2: 'Kwota zakupu: ',
    payment3: ' PLN',
    description:
      'Jeśli potrzebujesz możesz tutaj powiększyć możliwości Twojego konta i skorzystać z konta PREMIUM lub zobaczyć listę dokonanych płatności',
    premiumActive: 'Gratulacje! Korzystasz z konta PREMIUM!',
    premiumExpires: 'Ważność Twojego konta PREMIUM kończy się za ',
    premiumExpiresEnd: 'dni.',
    basicActive:
      'Aktualnie korzystasz z darmowego dostępu. Masz ograniczone możliwości.',
    knowMore1: 'Dowiedz się więcej na temat tego',
    knowMore2: 'co oferuje konto PREMIUM',
    knowMore3:
      'Jeśli potrzebujesz informacji na temat deaktywacji i usunięcia konta',
    knowMore4: 'kliknij tutaj',
    changePlan: 'Zmień plan.',
    noPayment: 'Nie masz jeszcze żadnych płatności.',
    details: 'Szczegóły',
    premiumByAdmin:
      'Status konta premium został ustawiony w panelu administracyjnym. Skontaktuj się z administratorem w celu uzyskania dodatkowych informacji.'
  },
  email: {
    title: 'Adres email na który zarejestrowane jest konto:'
  }
}
