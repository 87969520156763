import LayoutDefault from '../layouts/LayoutBase.vue'
import HomeIndex from '../views/home/HomeIndex.vue'
const routes = {
  path: '',
  component: LayoutDefault,
  meta: {
    forLogged: false,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '/',
      name: 'home',
      component: HomeIndex
    },
    {
      path: '/for-nanny',
      name: 'fornanny',
      component: () => import('../views/page/PageForNanny.vue')
    },
    {
      path: '/for-parent',
      name: 'forparent',
      component: () => import('../views/page/PageForParent.vue')
    },
    {
      path: '/mission',
      name: 'mission',
      component: () => import('../views/page/PageMission.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/page/PageContact.vue')
    },
    {
      path: '/rules',
      name: 'rules',
      component: () => import('../views/page/PageRules.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('../views/page/PagePrivacy.vue')
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('../views/page/PageFaq.vue')
    },
    {
      path: '/about-us',
      name: 'O nas',
      component: () => import('../views/page/PageAboutUs.vue')
    },
    {
      path: '/how-it-works',
      name: 'Jak to działa',
      component: () => import('../views/page/PageHowItWorks.vue')
    },
    {
      path: '/membership',
      name: 'Członkostwo',
      component: () => import('../views/page/PageMembership.vue')
    }
  ]
}

export default routes
