import { defineStore } from 'pinia'

export const useFiltersStore = defineStore('filters', {
  state: () => ({
    changed: 1,
    page: 1,
    distance: 30,
    sort_type: 'asc',
    sort: 'distance'
  }),
  getters: {
    isChanged(state) {
      return state.changed
    }
  },
  actions: {
    change() {
      this.changed++
    }
  }
})
