/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'
import AbstractDB from '../AbstractDB'
import { LoginRes } from './interfaces'
class Auth extends AbstractDB {
  constructor() {
    super('auth')
  }

  public async getUser(
    token: string,
    refresh: string
  ): Promise<AxiosResponse<unknown>> {
    ;(await this.api).defaults.headers.common['x-auth-token'] = token
    ;(await this.api).defaults.headers.common['x-refresh-token'] = refresh
    return await this.get('')
  }

  public postLogin(
    user_email: string,
    user_password: string
  ): Promise<AxiosResponse<LoginRes>> {
    return this.post('', { user_email, user_password })
  }

  public postInviteRegister(inviteId: string, obj: any) {
    return this.post(`register/${inviteId}`, obj)
  }

  public postRegister(obj: any) {
    return this.post('register', obj)
  }

  public async getToken(
    token: string,
    refresh: string
  ): Promise<AxiosResponse<unknown>> {
    ;(await this.api).defaults.headers.common['x-auth-token'] = token
    ;(await this.api).defaults.headers.common['x-refresh-token'] = refresh
    return await this.get('token')
  }

  public postRecover(obj: any) {
    return this.post('recover', obj)
  }

  public getRecover(recoverKey: any, userId: any) {
    return this.get(`recover/${recoverKey}/${userId}`)
  }

  public putPassword(recoverKey: any, userId: any, obj: any) {
    return this.put(`password/${recoverKey}/${userId}`, obj)
  }

  public getActivation(recoverKey: any, userId: any) {
    return this.get(`activation/${recoverKey}/${userId}`)
  }

  public getOAuth(provider: string, queryParams: string) {
    return this.get(`${provider}?${queryParams}`)
  }

  public getProvider() {
    return this.get('email/provider')
  }
}
const apiAuth = new Auth()
export default apiAuth
