export default {
  read: 'Czytaj również',
  comments: {
    header: 'Komentarze',
    created: 'Napisany',
    noComments: 'Brak komentarzy',
    add: 'Dodaj komentarz',
    send: 'Wyślij',
    reset: 'Anuluj',
    noLogged: 'Załóż konto lub zaloguj się',
    info1: 'Jesteś aktualnie niezalogowany/a. Jeśli masz już konto możesz się',
    info2: 'zalogować.',
    info3: 'Jeśli nie to zapraszamy Cię do',
    info4: 'rejestracji konta.',
    info5: 'Tylko zalogowani użytkownicy mogą widzieć i dodawać komentarze.',
    toast: {
      add: {
        success: {
          title: 'Komentarz został dodany!',
          msg: 'Będzie widoczny po zaakceptowaniu przez administratora'
        },
        fail: {
          title: 'Coś poszło nie tak',
          msg: 'Komentarz nie został dodany'
        }
      },
      get: {
        fail: {
          title: 'Coś poszło nie tak',
          msg: 'Nie udało się pobrać komentarzy'
        }
      }
    }
  }
}
