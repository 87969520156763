<template>
  <div
    class="flex items-center"
    :class="{
      ['flex-shrink-0']: !isVertical,
      ['flex-col']: isVertical
    }"
  >
    <img
      class="block w-auto"
      src="@assets/logo.png"
      alt="Logo"
      :class="{
        ['h-6']: size === 'xs',
        ['h-7']: size === 'sm',
        ['h-12']: size === 'md',
        ['h-20']: size === 'lg',
        ['h-28']: size === 'xl',
        ['h-[160px] md:h-auto']: size === 'custom',
        ['mr-1.5']: slogan && !isVertical
      }"
    />
    <span
      v-if="slogan"
      class="hidden font-light"
      :class="{
        [`sm:block`]: !isVertical,
        [`text-base`]: size === 'xs',
        [`text-lg`]: size === 'sm',
        [`text-[28px]`]: size === 'md',
        [`text-4xl`]: size === 'lg',
        [`text-5xl`]: size === 'xl',
        [`text-${colorLight} dark:text-${colorDark}`]: true
      }"
    >
      znana <span class="font-bold">niania</span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LogoAtom',
  props: {
    slogan: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md',
      validator: (value: string) => {
        return ['xs', 'sm', 'md', 'lg', 'xl', 'custom'].indexOf(value) !== -1
      }
    },
    colorLight: {
      type: String,
      default: 'secondary-500'
    },
    colorDark: {
      type: String,
      default: 'white'
    },
    isVertical: {
      type: Boolean,
      default: false
    }
  }
})
</script>
