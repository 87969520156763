/* eslint-disable @typescript-eslint/no-explicit-any */
import { toRefs, watch, nextTick, onBeforeUnmount, reactive } from 'vue'
import { createPopper } from '@popperjs/core/lib/popper-lite.js'
import {
  preventOverflow,
  flip,
  offset,
  arrow,
  computeStyles
} from '@popperjs/core/lib/modifiers/index.js'

const toInt = (x: string) => parseInt(x, 10)

interface pop {
  arrowPadding: any
  emit: any
  locked: any
  offsetDistance: any
  offsetSkid: any
  placement: any
  popperNode: any
  triggerNode: any
}

export default function usePopper({
  arrowPadding,
  emit,
  locked,
  offsetDistance,
  offsetSkid,
  placement,
  popperNode,
  triggerNode
}: pop) {
  const state = reactive({
    isOpen: false,
    popperInstance: null as any
  })

  // Enable or disable event listeners to optimize performance.
  const setPopperEventListeners = (enabled: any) => {
    state.popperInstance?.setOptions((options: any) => ({
      ...options,
      modifiers: [...options.modifiers, { name: 'eventListeners', enabled }]
    }))
  }

  const enablePopperEventListeners = () => setPopperEventListeners(true)
  const disablePopperEventListeners = () => setPopperEventListeners(false)

  const close = () => {
    if (!state.isOpen) {
      return
    }

    state.isOpen = false
    emit('close:popper')
  }

  const open = () => {
    if (state.isOpen) {
      return
    }

    state.isOpen = true
    emit('open:popper')
  }

  // When isOpen or placement change
  watch([() => state.isOpen, placement], async ([isOpen]) => {
    if (isOpen) {
      await initializePopper()
      enablePopperEventListeners()
    } else {
      disablePopperEventListeners()
    }
  })

  // When trigerr's position is change
  watch(offsetSkid, () => {
    state.popperInstance?.destroy()
    initializePopper()
  })

  const initializePopper = async () => {
    await nextTick()
    state.popperInstance = createPopper(triggerNode.value, popperNode.value, {
      placement: placement.value,
      modifiers: [
        preventOverflow,
        flip,
        {
          name: 'flip',
          enabled: !locked.value
        },
        arrow,
        {
          name: 'arrow',
          options: {
            padding: toInt(arrowPadding.value)
          }
        },
        offset,
        {
          name: 'offset',
          options: {
            offset: [toInt(offsetSkid.value), toInt(offsetDistance.value)]
          }
        },
        computeStyles,
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
            adaptive: false
          }
        }
      ]
    })

    // Update its position
    state.popperInstance.update()
  }

  onBeforeUnmount(() => {
    state.popperInstance?.destroy()
  })

  return {
    ...toRefs(state),
    open,
    close
  }
}
