export default {
  title: 'Ulubione',
  description: 'Polubione profile',
  superscription: 'Aktywność',
  nolikes: 'Nie dodałeś jeszcze żadnej niani do ulubionych.',
  completed: 'Profil uzupełniony',
  incomplete: 'Profil nieuzupełniony',
  age: 'lat',
  distance: 'mieszka w odległości',
  profile: 'Zobacz profil',
  invite: 'Zaproś do rozmowy',
  remove: 'Usuń z ulubionych'
}
