export default {
  giveJobBtn: 'Zaproponuj pracę',
  makeArchive: 'Zarchiwizuj',
  chatIs1: 'Rozmowa jest ',
  chatIs2: 'aktywna,  ',
  chatIs3: 'nieaktywna,  ',
  ad: 'dotyczy ogłoszenia ',
  removed: 'które zostało usunięte',
  roomCreated: 'Utworzono ',
  giveJob: {
    success: {
      title: 'Sukces!',
      msg: 'Propozycja pracy wysłana pomyślnie'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'nie udało się wysłać propozycji pracy'
    }
  },
  archive: {
    backlink: 'Aktywności',
    title: 'Zarchiwizuj rozmowę',
    msg: 'Czy na pewno chcesz zarchiwizować rozmowę z użytkownikiem ',
    msg2: ' dotyczącą ogłoszenia ',
    accept: 'Zarchiwizuj',
    decline: 'Nie',
    back: 'Wróć do listy rozmów',
    backDesktop: 'Wróć do rozmowy'
  }
}
