import LayoutDefault from '../layouts/LayoutBase.vue'
import LayoutSubpage from '../layouts/LayoutSubpage.vue'

const routes = {
  path: 'likes',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: false,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Ulubione nianie'
      },
      components: {
        default: LayoutSubpage
      },
      children: [
        {
          path: '',
          name: 'likes',
          component: () => import('../views/likes/LikesIndex.vue')
        }
      ]
    }
  ]
}

export default routes
