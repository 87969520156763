<template>
  <label
    :for="fieldName"
    class="block text-sm font-medium"
    :class="{
      'text-danger-500': v.$error,
      'text-secondary-700': !v.$error
    }"
    >{{ text }}</label
  >
</template>

<script lang="ts">
import Validation from '@quarks/Validation'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormLabelAtom',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { v, fieldName } = Validation()
    return {
      v,
      fieldName
    }
  }
})
</script>
