<template>
  <select
    v-model="model"
    :name="fieldName"
    :id="fieldName"
    :disabled="disabled"
    :required="required"
    class="mt-1 block w-full rounded-md border-secondary-300 py-2 pl-3 pr-10 text-base focus:outline-none sm:text-sm"
    :class="{
      'px-1 py-1 text-xs': size === 'xs' || fieldSize === 'xs',
      'px-2 py-1 text-sm': size === 'sm' || fieldSize === 'sm',
      'text-md px-3 py-2': size === 'md' || fieldSize === 'md',
      'px-4 py-3 text-lg': size === 'lg' || fieldSize === 'lg',
      'border-secondary-300 focus:border-primary-500 focus:ring-primary-500':
        !v.$error,
      'border-danger-500 focus:border-danger-500 focus:ring-red-500': v.$error
    }"
    @blur="v.$touch"
  >
    <option v-if="defaultOption.value" hidden :value="defaultOption.value">
      {{ defaultOption.text }}
    </option>
    <option
      v-for="(item, index) in items"
      :key="index"
      :value="item[itemValue]"
    >
      {{ item[itemText] }}
    </option>
  </select>
</template>

<script lang="ts">
import Validation from '@quarks/Validation'
import { defineComponent, PropType } from 'vue'

interface selectItem {
  [value: string]: string | number
}

export default defineComponent({
  name: 'FormSelectAtom',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    items: {
      type: Array as PropType<selectItem[]>,
      required: true,
      default: () => []
    },
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v)
    },
    required: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    defaultOption: {
      type: Object,
      default: () => ({
        value: '',
        text: ''
      })
    }
  },
  setup(props, { emit }) {
    const { v, fieldSize, fieldName, useValidation } = Validation()
    const model = useValidation(props, emit)
    return {
      model,
      v,
      fieldSize,
      fieldName
    }
  }
})
</script>
