<script lang="ts">
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'FormGroupAtom',
  props: {
    template: {
      type: String,
      default: 'vertical',
      validator: (v: string) => ['horizontal', 'vertical'].includes(v)
    }
  },
  setup(props, { slots }) {
    return () =>
      h(
        'div',
        {
          class: [
            'grid gap-8',
            props.template === 'horizontal' ? 'grid-flow-col' : 'grid-flow-row'
          ]
        },
        slots
      )
  }
})
</script>
