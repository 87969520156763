import LayoutDefault from '../layouts/LayoutBase.vue'
import ProfilIndex from '../views/profil/ProfilIndex.vue'

const routes = {
  path: 'profil',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      name: 'profil',
      meta: {
        title: 'Profil'
      },
      component: ProfilIndex
    }
  ]
}

export default routes
