<template>
  <div
    v-if="!isHidden"
    class="w-full border-l border-r border-t border-gray-200 bg-white py-4 px-8 first:rounded-t-lg last:rounded-b-lg last:border-b hover:bg-success-100"
    :class="{
      'border-none': isHashtag,
      'px-0': isHashtag,
      'py-0': isHashtag,
      'rounded-none': isHashtag
    }"
  >
    <form-fieldset class="mb-0">
      <form-field :name="data.id.toString()">
        <template v-if="!isHashtag">
          <form-legend
            v-if="attrType === 'nanny'"
            :text="
              data.attribute_name_nanny +
              ' ' +
              `${
                data.attribute_edit == 2
                  ? '(Pole wypełniane przez administratora serwisu)'
                  : ''
              }`
            "
            class="flex w-full flex-col text-base font-bold !text-secondary-500"
          />
          <form-legend
            v-else-if="attrType === 'ad'"
            :text="data.attribute_name_ad"
            class="flex w-full flex-col text-base font-bold !text-secondary-500"
          />
          <form-legend
            v-else
            :text="data.attribute_name_baby"
            class="flex w-full flex-col text-base font-bold !text-secondary-500"
          />
        </template>
        <div>
          <form-text
            v-if="data.attribute_field_type === 'textarea'"
            v-model="payload[0]"
            :disabled="isDisabled && isNanny"
          />
          <form-select
            v-else-if="data.attribute_field_type === 'select'"
            v-model="payload[0]"
            :items="data.options"
            :disabled="isDisabled && isNanny"
            item-text="attribute_option"
            item-value="attribute_value"
          />
          <div
            v-else-if="
              data.attribute_field_type === 'radio' &&
              (typeof payload[0] === 'string' ||
                typeof payload[0] === 'undefined')
            "
          >
            <form-radio
              v-for="item in data.options"
              :key="item.id"
              v-model="payload[0]"
              :disabled="isDisabled && isNanny"
              :name="item.id"
              :option="item.attribute_value"
              :label="item.attribute_option"
              class="p-2"
            />
          </div>
          <div
            v-else-if="
              data.attribute_field_type === 'checkbox' &&
              (typeof payload[0] === 'string' ||
                typeof payload[0] === 'undefined')
            "
          >
            <form-radio
              v-for="item in data.options"
              :key="item.id"
              v-model="payload[0]"
              :disabled="isDisabled && isNanny"
              :option="item.attribute_value"
              :label="item.attribute_option"
              class="p-2"
            />
          </div>
          <form-currency
            v-else-if="data.attribute_field_type === 'price'"
            v-model="payload[0]"
            :options="{ currency: 'PLN' }"
            :disabled="isDisabled && isNanny"
          />
          <div
            v-else-if="
              data.attribute_field_type === 'multiselect' && !isNannyForNow
            "
            class="grid grid-cols-2 md:grid-cols-4 md:gap-x-4"
          >
            <div
              v-for="item in data.options"
              :key="item.id"
              class="flex items-center gap-2 py-1"
            >
              <input
                :id="item.id"
                v-model="payload"
                :name="item.id"
                :disabled="isDisabled && isNanny"
                type="checkbox"
                :value="item.attribute_value"
                class="flex h-4 w-4 items-center rounded border-secondary-300 text-secondary-400 focus:ring-secondary-400"
              />
              <form-label
                class="cursor-pointer"
                :text="item.attribute_option"
                :for="item.id"
              />
            </div>
          </div>
          <div
            v-else-if="
              data.attribute_field_type === 'multiselect' && isNannyForNow
            "
          >
            <div v-for="item in data.options" :key="item.id" class="gap-2 py-1">
              <div class="flex space-x-2">
                <input
                  :id="item.id"
                  v-model="payload"
                  :name="item.id"
                  type="checkbox"
                  :value="item.attribute_value"
                  class="flex h-4 w-4 items-center rounded border-secondary-300 text-secondary-400 focus:ring-secondary-400"
                  @input="openAvailabilityModal"
                />
                <form-label
                  class="cursor-pointer"
                  :text="item.attribute_option"
                  :for="item.id"
                />
              </div>
              <span
                v-if="payload.length > 0"
                class="cursor-pointer text-xs text-danger-500"
                @click="useOverlay.show('ModalAvailability')"
              >
                Edytuj godziny dostępności
              </span>
            </div>
          </div>
        </div>
      </form-field>
    </form-fieldset>
  </div>
  <modal-availability
    v-else
    :data="data"
    :payload="payload"
    @updatePayload="payload = $event"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import FormField from '@atoms/Form/FormField'
import FormFieldset from '@atoms/Form/FormFieldset'
import FormText from '@atoms/Form/FormText'
import FormRadio from '@atoms/Form/FormRadio'
import FormCurrency from '@atoms/Form/FormCurrency'
import FormSelect from '@atoms/Form/FormSelect'
import FormLabel from '@atoms/Form/FormLabel'
import FormLegend from '@atoms/Form/FormLegend'
import ModalAvailability from '@portal/ModalAvailability'
import { useAuthStore } from '@stores/auth'
import { useOverlayStore } from '@stores/overlay'

type Payload = string | number
export default defineComponent({
  components: {
    FormText,
    FormCurrency,
    FormSelect,
    FormRadio,
    FormFieldset,
    FormField,
    FormLabel,
    FormLegend,
    ModalAvailability
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    attrType: {
      type: String,
      required: true,
      validator: (v: string) => ['nanny', 'baby', 'ad'].includes(v)
    },
    isHashtag: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changeValue'],
  setup(props, context) {
    const useOverlay = useOverlayStore()
    const isHidden = props.data.attribute_tag === 'DostepnoscNaJuż'
    const isNannyForNow = props.data.attribute_tag === 'NianiaNaJuż'
    const payload = ref([] as Payload[])
    const useAuth = useAuthStore()
    const isNanny = useAuth.isNanny

    watch(
      () => [...payload.value],
      () => {
        const toSend = {
          values: payload.value,
          attribute_field_type: props.data.attribute_field_type
        }
        context.emit('changeValue', toSend)
      }
    )

    const setInitialPayload = () => {
      if (props.data.values) {
        if (
          props.data.attribute_field_type === 'multiselect' ||
          props.data.attribute_field_type === 'checkbox' ||
          props.data.attribute_field_type === 'select' ||
          props.data.attribute_field_type === 'radio'
        ) {
          const values = props.data.values.split(',')
          for (let i = 0; i < props.data.options.length; i++) {
            if (values.includes(props.data.options[i].attribute_option)) {
              payload.value.push(props.data.options[i].attribute_value)
            }
          }
        } else {
          payload.value[0] = props.data.values
        }
      } else payload.value = []
    }

    setInitialPayload()

    const isDisabled = computed(() => props.data.attribute_edit === 2)

    const openAvailabilityModal = (v: Event) => {
      if ((v.target as HTMLInputElement).checked) {
        useOverlay.show('ModalAvailability')
      }
    }

    return {
      payload,
      isDisabled,
      isNanny,
      openAvailabilityModal,
      isHidden,
      isNannyForNow,
      useOverlay
    }
  }
})
</script>
