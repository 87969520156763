<template>
  <router-link :to="to" :class="btnClasses">
    <slot />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
const elStyles = {
  default:
    'relative inline-flex w-full sm:w-auto items-center justify-center font-medium  border border-transparent transition duration-150 ease-in-out focus:outline-none focus:ring-0 shadow-sm rounded-md px-8 py-3 md:py-4 md:text-lg md:px-10  text-base',
  primary:
    'bg-primary-600 !text-white hover:bg-primary-700 focus:ring-primary-500',
  light:
    'bg-secondary-600 !text-white hover:bg-secondary700 focus:ring-secondary-500'
}
export default defineComponent({
  name: 'HomeHeroBtn',
  props: {
    light: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: null
    },
    text: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    let btnClasses = props.light
      ? `${elStyles.default} ${elStyles.light}`
      : `${elStyles.default} ${elStyles.primary}`
    return {
      btnClasses
    }
  }
})
</script>
