export default {
  main: {
    title: 'Powiadomienia',
    description: 'Wszystkie Twoje powiadomienia',
    superscription: 'Aktywność'
  },
  noNotification: {
    title: 'Brak powiadomień',
    description: 'Nie masz jeszcze żadnych powiadomień na swoim koncie'
  },
  toast: {
    success: {
      title: 'Sukces!',
      msg: 'Powiadomienie zostało usunięte'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'Powiadomienie nie zostało usunięte'
    }
  }
}
