<template>
  <transition v-bind="opacity.opacityTransitionClasses">
    <div
      v-if="overlay.isOverlay"
      class="fixed inset-0 z-0"
      @click="hideOverlay()"
    >
      <div class="absolute inset-0 bg-secondary-500 opacity-75" />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useOverlayStore } from '@stores/overlay'
import useOpacityQuark from '@quarks/OpacityQuark'

export default defineComponent({
  name: 'OverlayAtom',
  props: {
    canClose: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const opacity = useOpacityQuark()
    const overlay = useOverlayStore()
    const hideOverlay = () => {
      if (props.canClose) {
        overlay.updateShowTemplate(false)
        overlay.updateShow(false)
      }
    }
    return {
      opacity,
      overlay,
      hideOverlay
    }
  }
})
</script>
