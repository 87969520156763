<script lang="ts">
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'FormFieldsetAtom',
  setup(props, { slots }) {
    return () =>
      h(
        'fieldset',
        {
          class: 'flex flex-col mb-4'
        },
        slots
      )
  }
})
</script>
