export default {
  superscription: 'Ustawienia',
  title: 'Moje cechy',
  description: 'Moje cechy pomagają dopasować ogłoszenia.',
  matching: 'Zgodność:',
  toast: {
    success: {
      title: 'Sukces!',
      msg: 'Cechy zostały zaktualizowane'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'Aktualizacja cech nieudana. Sprónuj ponownie później'
    }
  }
}
