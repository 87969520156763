<template>
  <div class="relative min-w-[260px] max-w-[320px] pb-1">
    <div v-if="isLoading" class="flex justify-center py-4">
      <spinner size="xs" />
    </div>
    <div v-if="!isLoading">
      <portal-notification
        v-for="notification in notifications"
        :key="notification.id"
        :data="notification"
        :close="false"
        @read="read(notification)"
      />
    </div>
    <router-link
      to="/notifications"
      class="relative flex items-center justify-center border-l-3 border-transparent px-3.5 py-2.5 text-sm font-bold text-secondary-700 hover:bg-gray-100 hover:text-warning-600"
    >
      <icon name="bell" size="4" class="mr-2.5" />
      {{ $t('navbar.allnotifications') }}
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import Icon from '@atoms/Icon'
import Spinner from '@atoms/Spinner'
import PortalNotification from '@portal/PortalNotification'

import { useRouter } from 'vue-router'

import apiNotifications from '@db/Notifications'
import { Notification } from '@db/Notifications/interfaces'

export default defineComponent({
  components: {
    Icon,
    Spinner,
    PortalNotification
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['read'],
  setup(_, { emit }) {
    const isLoading = ref(true)
    const notifications = ref([] as Notification[])

    const router = useRouter()

    onMounted(async () => {
      const data = await apiNotifications.readAllNew(5)
      notifications.value = data.data.rows
      isLoading.value = false
    })

    const read = async (notification: Notification) => {
      const { notification_item_collection: type, id } = notification
      const item = notifications.value.find((el) => el.id === id)
      if (item?.notification_is_watched === 2) {
        await apiNotifications.readNotification(id)
        emit('read')
        const data = await apiNotifications.readAllNew(5)
        notifications.value = data.data.rows
      }
      switch (type) {
        case 'invites':
          router.push('/offers')
          break
        case 'deals':
          router.push('/deals')
          break
        case 'reviews':
          router.push('/deals')
          break
        case 'messages':
          router.push('/inbox')
          break
        default:
          break
      }
    }

    return { notifications, isLoading, read }
  }
})
</script>
