<template>
  <profile-introduce-card
    v-if="step === 0"
    class="col-span-2 md:col-span-1"
    icon="searchSolid"
    @click="changeType('find')"
  >
    <template #title>{{
      isParent ? 'Znajdź nianię' : 'Znajdź pracę'
    }}</template>
    <template #description>{{
      isParent
        ? 'Kliknij tutaj aby w kilku krokach zdefiniować jakiej niani szukasz.'
        : 'Kliknij tutaj aby w kilku krokach zdefiniować jakiej pracy szukasz.'
    }}</template>
  </profile-introduce-card>
  <profile-introduce-card
    v-if="step === 0"
    class="col-span-2 md:col-span-1"
    icon="mapMarkedAltSolid"
    @click="changeType('ad')"
  >
    <template #title>{{
      isParent ? `Dodaj ogłoszenie` : 'Daj się znaleźć'
    }}</template>
    <template #description>{{
      isParent
        ? 'Podaj kilka informacji i dodaj nowe ogłoszenie dla niań.'
        : 'Uzupełnij swój profil, aby ułatwić rodzicom znalezienie Ciebie.'
    }}</template>
  </profile-introduce-card>
  <!-- <div class="mb-2 md:mb-6">
    <div class="flex h-max flex-col border bg-white md:rounded-lg">
      <div class="flex px-3 pt-5 pb-4 md:px-6 md:pb-6 md:pt-6">fdsfsdf</div>
    </div>
  </div> -->
  <div class="col-span-2" v-if="!isLoading && step > 0">
    <div class="mb-2 md:mb-6">
      <div class="flex h-max flex-col border bg-white md:rounded-lg">
        <div class="flex px-3 pt-5 pb-4 md:px-6 md:pb-6 md:pt-6">
          <div class="w-full">
            <profile-introduce-header v-if="step === 1">
              <template #title>{{
                isParent
                  ? 'Szukam niani na...'
                  : 'Wybierz interesujące Cię godziny'
              }}</template>
              <template #description>{{
                isParent
                  ? 'Tutaj opis po co wybieramy tego godziny'
                  : 'Tutaj opis po co wybieramy tego godziny'
              }}</template>
            </profile-introduce-header>
            <profile-introduce-time v-if="step === 1">
              <div
                v-for="option in optionsAvailbity"
                :key="option.id"
                class="flex w-full justify-center"
              >
                <input
                  :checked="
                    optionsAvailbityValues.values.includes(
                      option.attribute_value
                    )
                  "
                  type="checkbox"
                  class="h-8 w-8 rounded-full border-gray-300 checked:bg-success-400 checked:text-success-500 hover:cursor-pointer hover:bg-success-100 focus:outline-0 focus:ring-0 focus:ring-offset-0 md:h-12 md:w-12 lg:h-16 lg:w-16"
                  @input="changeOptions(option.attribute_value, availbilityId)"
                />
              </div>
            </profile-introduce-time>
            <profile-introduce-header v-if="step === 2">
              <template #title>{{
                isParent ? 'Ile dzieci' : 'Ile dzieci ma być objętych opieką?'
              }}</template>
              <template #description>{{
                isParent
                  ? 'Ile dzieci'
                  : 'Liczba dzieci, którymi mogę się zaopiekować (zaznacz wszystkie możliwe opcje)'
              }}</template>
            </profile-introduce-header>
            <profile-introduce-checkboxes
              v-if="step === 2"
              :data="howManyChildren"
              class="hover:bg-transparent"
              isHashtag
              :attr-type="!isParent ? 'nanny' : 'ad'"
              @change-value="updateAttrs($event, howManyChildren.id)"
            />
            <div
              v-if="step === 1 || step === 2"
              class="mt-8 flex justify-between border-t border-t-secondary-600/20 pt-4"
            >
              <div>
                <btn
                  color="secondary"
                  size="lg"
                  outline
                  text="Wróc"
                  @click="step--"
                />
              </div>
              <div>
                <btn
                  color="secondary"
                  size="lg"
                  text="Przejdź dalej"
                  @click="step++"
                />
              </div>
            </div>
            <profile-introduce-header v-if="step === 3">
              <template #title>{{
                isParent
                  ? 'Co powinna obejmowac opieka?'
                  : 'Co może obejmowac opieka?'
              }}</template>
              <template #description>{{
                isParent
                  ? 'Co powinna obejmowac opieka?'
                  : 'Co powinna obejmowac opieka?'
              }}</template>
            </profile-introduce-header>
            <profile-introduce-checkboxes
              v-if="step === 3"
              :data="careIncludes"
              class="hover:bg-transparent"
              isHashtag
              :attr-type="!isParent ? 'nanny' : 'ad'"
              @change-value="updateAttrs($event, careIncludes.id)"
            />
            <div
              v-if="step === 3"
              class="mt-8 flex justify-between border-t border-t-secondary-600/20 pt-4"
            >
              <div>
                <btn
                  color="secondary"
                  size="lg"
                  outline
                  text="Wróc"
                  @click="step--"
                />
              </div>
              <div v-if="isParent">
                <btn
                  v-if="type === 'find'"
                  color="secondary"
                  size="lg"
                  text="Szukaj niani"
                  @click="save"
                />
                <btn
                  v-if="type === 'ad'"
                  color="secondary"
                  size="lg"
                  text="Przejdź dalej"
                  @click="step++"
                />
              </div>
              <div v-else>
                <btn
                  v-if="type === 'find'"
                  color="secondary"
                  size="lg"
                  text="Szukaj pracy"
                  @click="save"
                />
                <btn
                  v-if="type === 'ad'"
                  color="secondary"
                  size="lg"
                  text="Zapisz cechy"
                  @click="save"
                />
              </div>
            </div>
            <profile-introduce-header v-if="step === 4">
              <template #title>Podaj tytuł ogłoszenia</template>
              <template #description>Podaj tytuł ogłoszenia</template>
            </profile-introduce-header>
            <div v-if="step === 4">
              <form-field name="ad_title" class="mb-6" :v="v.adTitle">
                <form-text
                  v-model="adTitle"
                  :placeholder="`${$t('ads.add.adTitle')}...`"
                />
                <form-valid
                  check="required"
                  :text="$t('ads.add.adTitleRequired')"
                />
              </form-field>
            </div>

            <div
              v-if="step === 4"
              class="mt-8 flex justify-between border-t border-t-secondary-600/20 pt-4"
            >
              <div>
                <btn
                  color="secondary"
                  size="lg"
                  outline
                  text="Wróc"
                  @click="step--"
                />
              </div>
              <div>
                <btn
                  color="secondary"
                  size="lg"
                  text="Dodaj ogłoszenie"
                  @click="save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!isLoading">
    <attr-item
      v-for="(attr, id) in state"
      :key="id"
      class="hidden"
      :data="attr"
      :attr-type="!isParent ? 'nanny' : 'ad'"
      @change-value="updateAttrs($event, attr.id)"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import FormField from '@atoms/Form/FormField'
import FormText from '@atoms/Form/FormText'
import Btn from '@atoms/Btn'
import FormValid from '@atoms/Form/FormValid'
// import Close from '@atoms/Close'

import AttrItem from '@portal/AttrItem'
import ProfileIntroduceCard from '@portal/ProfileIntroduceCard'
import ProfileIntroduceTime from '@portal/ProfileIntroduceTime'
import ProfileIntroduceHeader from '@portal/ProfileIntroduceHeader'
import ProfileIntroduceCheckboxes from '@portal/ProfileIntroduceCheckboxes'
import apiFilters from '@db/Filters'
import apiAds from '@db/Announcments'
import apiSearch from '@db/Search'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import {
  Attr,
  DataObject,
  SelectedItems,
  DataToSend
} from '@db/Filters/interfaces'
import { useAuthStore } from '@stores/auth'

export default defineComponent({
  name: 'ProfileIntroduce',
  emits: ['close'],
  components: {
    Btn,
    AttrItem,
    // Close,
    FormField,
    FormText,
    FormValid,
    ProfileIntroduceCard,
    ProfileIntroduceTime,
    ProfileIntroduceHeader,
    ProfileIntroduceCheckboxes
  },
  setup(_, { emit }) {
    const step = ref(0)
    const type = ref('')
    const howManyChildren = ref()
    const careIncludes = ref()
    const availbilityId = ref()
    const optionsAvailbity = ref()
    const optionsAvailbityValues = ref()
    const payload = ref([] as string[])
    const adTitle = ref()
    const isLoading = ref(true)

    const useAuth = useAuthStore()
    const isParent = useAuth.isParent
    const router = useRouter()
    const attrs = ref([] as Attr[])
    const attrsIds = ref([] as number[])
    const allAttrs = ref([] as Attr[])
    const state = ref([] as DataObject[])
    const valuesState = ref([] as DataToSend[])

    const changeType = (text: string) => {
      type.value = text
      step.value++
    }

    const readAllVals = async () => {
      if (isParent) {
        const dVals = await apiFilters.readAllVals()
        attrs.value = dVals.data
      } else {
        const dVals = await apiSearch.readAdsFilter()
        attrs.value = dVals.data
      }
    }

    const addValsToState = () => {
      for (const el of state.value) {
        for (const item of attrs.value) {
          if (item.id === el.id) {
            el.values = item.values
          }
        }
      }
    }

    const readAllFilters = async () => {
      if (isParent) {
        const d = await apiFilters.readAllForParent()
        allAttrs.value = d.data
      } else {
        const d = await apiAds.readAllAttrs()
        allAttrs.value = d.data
      }
      for (const item of attrs.value) {
        attrsIds.value.push(item.id)
      }
      return attrsIds
    }

    const readAllOptions = async () => {
      for (const el of allAttrs.value) {
        const dOpts = (await apiFilters.readAllOptions(el.id.toString())) as {
          data: SelectedItems[]
        }
        const newStateElement = {
          ...el,
          options: [...dOpts.data]
        }
        state.value.push(newStateElement)
      }
    }

    const getAllData = async () => {
      await readAllVals()
      await readAllFilters()
      await readAllOptions()
      addValsToState()
      isLoading.value = false
    }

    onMounted(async () => {
      await getAllData()
      const howManyChildrenId = state.value.find(
        (el) => el.attribute_tag === 'IleDzieci'
      )?.id
      howManyChildren.value = state.value.find(
        (el) => el.id === howManyChildrenId
      )

      const careIncludesId = state.value.find(
        (el) => el.attribute_tag === 'OpiekaObejmuje'
      )?.id
      careIncludes.value = state.value.find((el) => el.id === careIncludesId)

      availbilityId.value = state.value.find(
        (el) => el.attribute_tag === 'DostepnoscNaJuż'
      )?.id
      optionsAvailbityValues.value = valuesState.value.find(
        (el) => el.id === availbilityId.value
      )
      optionsAvailbity.value = state.value.find(
        (el) => el.id === availbilityId.value
      )?.options
    })
    const changeOptions = (val: string, id: string | number) => {
      const d = valuesState.value.findIndex((el) => el.id === id)
      const index = valuesState.value[d].values!.findIndex(
        (el: string) => el === val
      )
      if (index === -1) {
        valuesState.value[d].values!.push(val)
      } else {
        valuesState.value[d].values!.splice(index, 1)
      }
    }
    const updateAttrs = (
      data: { values: string[]; attribute_field_type: string },
      id: number
    ) => {
      const newEntry = ref({} as DataToSend)
      newEntry.value.id = id
      newEntry.value.attribute_field_type = data.attribute_field_type
      newEntry.value.values = data.values

      const index = valuesState.value.findIndex((x) => x.id === id)
      if (index === -1) {
        valuesState.value.push(newEntry.value)
      } else {
        valuesState.value[index] = newEntry.value
      }
    }

    const save = async () => {
      if (isParent) {
        // zaznaczenie nianianajuz
        const nannyForNowId = state.value.find(
          (el) => el.attribute_tag === 'NianiaNaJuż'
        )!.id
        updateAttrs(
          {
            values: [`${nannyForNowId}-1`],
            attribute_field_type: 'multiselect'
          },
          nannyForNowId
        )

        if (type.value === 'find') {
          await apiFilters.updateFilters({ ...valuesState.value }).then(() => {
            router.push('/nannies')
          })
        } else {
          v.value.$touch()
          if (v.value.$errors.length === 0) {
            await apiAds
              .addAd({
                ad_title: adTitle.value
              })
              .then((res) => {
                // TODO update nowododanego ogłoszenia - dodanie zaznaczonych cech jak w AnnouncmentsUpdate
                emit('close')
                router.push(`/announcments/update/${res.data[0]}`)
              })
          }
        }
      } else {
        if (type.value === 'find') {
          await apiSearch.updateAdsFilter({ ...valuesState.value }).then(() => {
            router.push('/jobs')
          })
        } else {
          // todo update atrybutów, przekierowanie na /attributes
        }
      }
    }

    const rules = {
      adTitle: {
        required
      }
    }

    const v = useVuelidate(rules, { adTitle })

    const title = computed(() => {
      switch (step.value) {
        case 0:
          return 'Jak możemy Ci pomóc?'
        case 1:
          return isParent
            ? 'Szukam niani na...'
            : 'Wybierz interesujące Cię godziny'
        case 2:
          return isParent
            ? 'Ile dzieci ma być objętych opieką?'
            : 'Liczba dzieci, którymi mogę się zaopiekować (zaznacz wszystkie możliwe opcje)'
        case 3:
          return isParent
            ? 'Co powinna obejmowac opieka?'
            : 'Co może obejmować opieka?'
        case 4:
          return 'Podaj tytuł ogłoszenia'
        default:
          return 'Test'
      }
    })
    return {
      step,
      type,
      changeType,
      optionsAvailbity,
      optionsAvailbityValues,
      title,
      changeOptions,
      payload,
      state,
      updateAttrs,
      isParent,
      isLoading,
      availbilityId,
      howManyChildren,
      careIncludes,
      adTitle,
      v,
      save
    }
  }
})
</script>
