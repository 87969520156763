<template>
  <h5
    class="mb-2 text-sm font-bold uppercase text-secondary-700 md:whitespace-nowrap md:text-base"
  >
    <slot name="title" />
  </h5>
  <p class="mb-4 text-sm font-normal text-gray-500">
    <slot name="description" />
  </p>
</template>
