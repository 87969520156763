import AbstractDB from '../AbstractDB'

export class Filters extends AbstractDB {
  constructor() {
    super('filters')
  }
  public readAllVals() {
    return this.get('')
  }
  public updateFilters(data: Record<string, unknown>) {
    return this.patch('values', data)
  }
  public readAllValsKids(id: string) {
    return this.get(`kids/${id}`)
  }
  //ads:
  public readAllAds() {
    return this.get('announcements/all')
  }
  public readAllValsAds() {
    return this.get('announcements')
  }
  // values & options for attributes
  public readAllOptions(id: string) {
    return this.get(`values/${id}`)
  }
  public readAllForNanny() {
    return this.get('all/nanny')
  }
  public readAllForParent() {
    return this.get('all/parent')
  }
}

const ApiFilters = new Filters()
export default ApiFilters
