import LayoutDefault from '../layouts/LayoutBase.vue'
import LayoutSubpage from '../layouts/LayoutSubpage.vue'

const routes = {
  path: 'account',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Moje konto'
      },
      components: {
        default: LayoutSubpage
      },
      children: [
        {
          path: '',
          name: 'account',
          component: () => import('../views/account/AccountIndex.vue')
        },
        {
          path: 'avatar',
          name: 'Avatar',
          meta: {
            title: 'Zmiana avatara'
          },
          component: () => import('../views/account/Avatar.vue')
        },
        {
          path: 'password',
          name: 'Password',
          meta: {
            title: 'Zmiana hasła'
          },
          component: () => import('../views/account/Password.vue')
        },
        {
          path: 'phone',
          name: 'Phone',
          meta: {
            title: 'Zmiana numeru telefonu'
          },
          component: () => import('../views/account/Phone.vue')
        },
        {
          path: 'subscription',
          name: 'Subscription',
          meta: {
            title: 'Subskrypcja'
          },
          component: () => import('../views/account/Subscription.vue')
        },
        {
          path: 'logs',
          name: 'Logs',
          meta: {
            title: 'Powiadomienia'
          },
          component: () => import('../views/account/Logs.vue')
        },
        {
          path: 'references',
          name: 'References',
          meta: {
            title: 'Referencje',
            forLogged: true,
            forNanny: true,
            forParent: false
          },
          component: () => import('../views/account/References.vue')
        },
        {
          path: 'tags',
          name: 'Tagi',
          meta: {
            title: 'Hashtagi'
          },
          component: () => import('../views/account/Hashtags.vue')
        },
        {
          path: 'visible',
          name: 'Visible',
          meta: {
            title: 'Widoczność',
            forLogged: true,
            forNanny: true,
            forParent: false
          },
          component: () => import('../views/account/Visible.vue')
        },
        {
          path: 'birth',
          name: 'Birth',
          meta: {
            title: 'Data urodzenia',
            forLogged: true,
            forNanny: true,
            forParent: false
          },
          component: () => import('../views/account/Birth.vue')
        },
        {
          path: 'createmain',
          name: 'CreateMain',
          meta: {
            title: 'Utwórz główny adres'
          },
          component: () => import('../views/account/AddressMainCreate.vue')
        },
        {
          path: 'updatemain',
          name: 'UpdateMain',
          meta: {
            title: 'Edytuj główny adres'
          },
          component: () => import('../views/account/AddressMainUpdate.vue')
        },
        {
          path: 'createadditional',
          name: 'CreateAdditional',
          meta: {
            title: 'Utwórz dodatkowy adres'
          },
          component: () =>
            import('../views/account/AddressAdditionalCreate.vue')
        },
        {
          path: 'updateadditional',
          name: 'UpdateAdditional',
          meta: {
            title: 'Edytuj dodatkowy adres'
          },
          component: () =>
            import('../views/account/AddressAdditionalUpdate.vue')
        },
        {
          path: 'membership',
          name: 'Membership',
          meta: {
            title: 'Członkostwo'
          },
          component: () => import('../views/account/Membership.vue')
        },
        {
          path: 'payments',
          name: 'Payments',
          meta: {
            title: 'Płatności'
          },
          component: () => import('../views/payments/Summary.vue')
        },
        {
          path: 'payments/:orderID',
          name: 'PaymentsID',
          meta: {
            title: 'Płatności'
          },
          component: () => import('../views/payments/Summary.vue')
        }
      ]
    }
  ]
}

export default routes
