import { defineStore } from 'pinia'

export const useSidenavStore = defineStore('sidenav', {
  state: () => ({
    open: false,
    mobile: false,
    horizontal: 'left'
  }),
  getters: {
    isOpen(state) {
      return state.open
    },
    isHorizontal(state) {
      return state.horizontal
    },
    isMobile(state) {
      return state.mobile
    }
  },
  actions: {
    change() {
      this.open = this.open !== true
    },
    close() {
      this.open = false
      this.horizontal = 'left'
    },
    setMobile() {
      this.mobile = true
    },
    setHorizontal() {
      this.horizontal = 'right'
    },
    setDesktop() {
      this.mobile = false
    }
  }
})
