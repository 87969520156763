export default {
  profil: 'Profil',
  nannies: 'Znajdź nianie',
  jobs: 'Znajdź pracę',
  ads: 'Moje ogłoszenia',
  deals: 'Moje współprace',
  nologged: {
    fornanny: 'Dla niani',
    forparent: 'Dla rodzica',
    login: 'Zaloguj',
    register: 'Załóż konto'
  },
  logout: {
    label: 'Wylogowanie',
    title: 'Wyloguj'
  },
  allinbox: 'Wszystkie wiadomości',
  allnotifications: 'Wszystkie powiadomienia'
}
