export default {
  title: 'Kontakt',
  addressdata: 'Dane adresowe',
  topicdata: 'Temat i czas oczekiwanego kontaktu',
  btn: 'Wyślij',
  terms: {
    pre: 'Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z ( np. realizacją zgłoszenia). Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem /am poinformowany /a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest ....'
  },
  firstname: 'Imię',
  lastname: 'Nazwisko',
  phone: 'Telefon',
  email: 'Adres email',
  topic: 'Jaki masz temat?',
  how: 'Preferowana forma kontaktu',
  when: 'Czas',
  speed: 'Szybkość kontaktu',
  question: 'Jeśli chcesz - opisz sprawę',
  toast: {
    success: {
      title: 'Dziękujemy za kontakt',
      msg: 'E-mail został wysłany'
    },
    fail: {
      title: 'Coś poszło nie tak',
      msg: 'E-mail nie został wysłany'
    }
  }
}
