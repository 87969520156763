export default {
  superscription: 'Aktywność',
  title: 'Współprace',
  description: 'Wszystkie Twoje współprace.',
  noCooperate: {
    title: 'Ojej ;(',
    description: 'Nie masz jeszcze rozpoczętych współprac.',
    btnParent: 'Szukaj niani',
    btnNanny: 'Znajdź pracę'
  },
  noReview: {
    title: 'Brak oceny',
    description: 'Ocena współpracy nie została jeszcze wystawiona.'
  },
  noReviewVisible: {
    title: 'Ocena wystawiona',
    description:
      'Aby zobaczyć wystawioną Tobie ocenę - wyraź swoją opinię na temat współpracy.'
  },
  noAddReview: {
    title: 'Brak oceny',
    description: 'Nie dodałeś/aś jeszcze oceny współpracy.',
    addReview: 'Dodaj ocenę'
  },
  dealsView: {
    title: 'Oceny współpracy',
    back: 'Wróć do poprzedniej strony',
    description: 'To są Twoje oceny współpracy z ',
    descriptionAds: ' dla ogłoszenia ',
    reviewRecived: 'Ocena otrzymana:',
    reviewSent: 'Ocena wystawiona:'
  },
  error: {
    title: 'Brak informacji o współpracy.'
  },
  addReview: {
    superscription: 'Moje oceny',
    title: 'Dodaj ocene',
    description: 'Pamiętaj aby wystawić wiarygodną ocenę. ',
    reviewLabel: 'Wybierz ocene',
    addReviewBtn: 'Dodaj ocene',
    reviewCommentLabel: 'Dodaj komentarz',
    addReviewRequired: 'Ocena jest wymagana',
    toastSuccess: {
      title: 'Sukces!',
      msg: 'Ocena została dodana'
    },
    toastFail: {
      title: 'Coś poszło nie tak',
      msg: 'Ocena nie została dodana'
    },
    addDescriptionRequired: 'Komentarz do oceny jest wymagany',
    sendMsg: 'Ocena już została wystawiona'
  }
}
