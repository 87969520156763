import LayoutDefault from '../layouts/LayoutBase.vue'
import LayoutSubpage from '../layouts/LayoutSubpage.vue'

const routes = {
  path: '/announcments',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: false,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Ogłoszenia'
      },
      components: {
        default: LayoutSubpage
      },
      children: [
        {
          path: '',
          name: 'announcments',
          component: () => import('../views/announcments/AnnouncmentsIndex.vue')
        },
        {
          path: 'update/:adId',
          name: 'adsUpdate',
          component: () =>
            import('../views/announcments/AnnouncmentsUpdate.vue')
        },
        {
          path: 'add',
          name: 'adsAdd',
          component: () => import('../views/announcments/AnnouncmentsAdd.vue')
        }
      ]
    }
  ]
}

export default routes
