<template>
  <div class="mb-8 flex">
    <div v-for="(item, id) in items" :key="id">
      <router-link
        v-if="id !== items.length - 1"
        :to="item.url"
        class="text-md font-normal text-secondary-500 hover:text-secondary-700 hover:underline"
      >
        {{ item.title }}
      </router-link>
      <span v-if="id !== items.length - 1" class="mr-2 ml-1 text-gray-500">
        /
      </span>
      <span v-else class="text-gray-500">
        {{ item.title }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {
    fixTo: {
      type: Array as PropType<{ title: unknown; url: string }[]>,
      default: () => []
    }
  },
  setup(props) {
    const route = useRoute()
    const items = ref(
      route.matched.map((x) => {
        return { title: x.meta.title, url: x.path }
      })
    )
    items.value.splice(0, 2)
    items.value.unshift({ title: 'Strona główna', url: '/' })
    if (items.value[items.value.length - 1].title === undefined)
      items.value.pop()
    if (props.fixTo.length > 0) items.value = [...props.fixTo]

    return {
      items
    }
  }
})
</script>
