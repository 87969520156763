import { useAuthStore } from '@stores/auth'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export const tryAutoLogin = async () => {
  const useAuth = useAuthStore()
  if (useAuth.token === '') {
    await useAuth.tryAutoLogin()
  }
}

export const checkAuthenticated = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const useAuth = useAuthStore()
  if (useAuth.isAuthenticated) {
    // zalogowany
    if (to.meta.forUnlogged) {
      next('/')
    }
    if (to.path.includes('auth') && to.name !== 'authLogout') {
      // auth bez wylogowania
      next()
    } else {
      // poza logwaniem - użytkownik może wejść wszędzie
      // następuje sprawdzenie typu dla dostępu do określonych widoków
      if (useAuth.isNanny) {
        // niania zalogowana
        if (to.meta.forNanny) {
          if (to.meta.forPremium && !useAuth.isPremium) {
            return next('/account/membership')
          }
          return next()
        } else next('/')
      } else if (useAuth.isParent) {
        // rodzic zalogowany
        if (to.meta.forParent) {
          if (to.meta.forPremium && !useAuth.isPremium) {
            return next('/account/membership')
          }
          return next()
        } else next('/')
      } else {
        // member - nie zdefiniowano jeszcze typu
        if (to.name === 'onboarding' || to.name === 'authLogout') {
          // routing jest onboardingiem lub wylogowaniem
          next()
        } else {
          // routing nie jest onboardingiem ani wylogowaniem
          next('/onboarding')
        }
      }
    }
  } else {
    // niezalogowany
    if (to.meta.forLogged) {
      next('/auth')
    } else {
      next()
    }
  }
}
