<template>
  <span
    v-if="v.$errors[0] && check === v.$errors[0].$validator && !success"
    class="inline-flex text-xs text-danger-500"
    v-html="text"
  />
  <span
    v-else-if="!v.$errors[0] && success && v.$dirty"
    class="inline-flex text-xs text-success-500"
    v-html="text"
  />
  <span
    v-else-if="!v.$dirty && !success && !check"
    class="inline-flex text-xs text-secondary-500"
    v-html="text"
  />
</template>

<script lang="ts">
import Validation from '@quarks/Validation'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormValidAtom',
  props: {
    text: {
      type: String,
      default: ''
    },
    success: {
      type: Boolean
    },
    check: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { v, fieldSize, fieldName } = Validation()
    return { v, fieldSize, fieldName }
  }
})
</script>
