export default {
  backlink: 'Wróć do poprzedniej strony',
  isfree: {
    title: 'Rozszerz możliwości swojego konta',
    msg: 'Tylko użytkownicy premium mogą aplikować do pracy'
  },
  premium: 'Przejdź na premium!',
  close: 'Zamknij',
  active: {
    activeText: 'Ogłoszenie jest ',
    active: 'aktywne',
    inactive: 'nieaktywne',
    created: ', dodano: ',
    createdForSystemAd: 'Dodano: '
  },
  noJobs: {
    title: 'Nie znaleziono ogłoszenia.',
    description: 'Spróbuj zmienic filtry wyszukiwania',
    btn: 'Szukaj ogłoszenia'
  },
  apply: {
    backlink: 'Wróć do poprzedniej strony',
    routerLink: 'Zobacz Ogłoszenie',
    title: 'Aplikuj do pracy',
    description: 'Zaproponuj rodzicowi rozmowę na temat wybranego ogłoszenia.',
    applyJob: 'Aplikuj do pracy',
    sentByNannyMsg:
      'Aplikacja dla tego ogłoszenia już została przez Ciebie wysłana. Poczekaj aż rodzic zaakceptuje Twoją ofertę.',
    sentByParentMsg:
      'Zaproszenie do tego ogłoszenia już zostało do Ciebie wysłane. Zaakceptuj ofertę kontaktu i przejdź do rozmowy by omówić szczegóły współpracy.',
    toast: {
      success: {
        title: 'Sukces!',
        msg: 'Aplikacja do współpracy została wysłana'
      },
      alreadySent: {
        title: 'Już wysłano!',
        msg: 'Aplikacja do współpracy została już przez Ciebie wysłana. Poczekaj aż rodzic zaakceptuje Twoją ofertę.'
      },
      fail: 'Coś poszło nie tak',
      failMsg: 'Coś poszło nie tak. Spróbuj wysłac aplikację jeszcze raz.'
    }
  },
  map: {
    title: 'Odległość od rodzica',
    description: 'Dystans do miejsca opieki to '
  },
  attr: {
    subheadingAttr: 'Cechy ogłoszenia',
    title: 'Brak cech',
    description: 'To ogłoszenie nie posiada żadnych cech'
  },
  kid: {
    genderBoy: 'Chłopiec',
    genderGirl: 'Dziewczynka',
    subheadingKid: 'Profile dzieci',
    title: 'Brak profilu dzieci',
    description: 'Niestety rodzic nie udostępnił informacji o dzieciach.',
    created: 'Data urodzenia: ',
    btn: 'Zobacz profil dziecka'
  },
  profile: {
    back: 'Wróć',
    profileIs: 'Profil jest',
    active: 'aktywny',
    unActive: 'nieaktywny',
    general: {
      title: 'Informacje ogólne',
      subheading: 'Dzieci',
      nokids: 'Rodzic nie udostępnił informacji o dzieciach.',
      girl: 'Dziewczynka',
      boy: 'Chłopiec',
      birth: 'data urodzenia:',
      babyProfile: 'Zobacz profil dziecka'
    },
    reviews: {
      title: 'Oceny współpracy',
      subheading: 'Oceny współpracy',
      noreviews: 'Rodzic nie otrzymał jeszcze żadnej oceny.'
    },
    hashtags: {
      title: 'Aktywności',
      subheading: 'Aktywności rodzica',
      noHashtags: 'Rodzic nie dodał jeszcze żadnych aktywności.'
    }
  },
  kidProfile: {
    back: 'Wróć',
    female: 'dziewczynka',
    male: 'chłopiec',
    birthDate: 'data urodzenia',
    kidAttributes: 'Cechy dziecka',
    noAccess:
      'Dane dziecka dostępne są po zaakceptowaniu współpracy przez rodzica'
  },
  description: {
    title: 'Opis ogłoszenia'
  }
}
