<template>
  <div class="border-l border-r border-t border-gray-200 bg-white pb-4">
    <div class="max-w-sm animate-pulse">
      <div class="my-4 mx-8 h-2.5 w-48 rounded-full bg-gray-200"></div>
      <div class="mx-8 mb-2.5 h-2 max-w-[120px] rounded-full bg-gray-200"></div>
      <div class="mx-8 mb-2.5 h-2 max-w-[150px] rounded-full bg-gray-200"></div>
      <div class="mx-8 mb-2.5 h-2 max-w-[140px] rounded-full bg-gray-200"></div>
      <div class="mx-8 mb-2.5 h-2 max-w-[120px] rounded-full bg-gray-200"></div>
      <div class="mx-8 h-2 max-w-[160px] rounded-full bg-gray-200"></div>
    </div>
  </div>
</template>
