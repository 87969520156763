<template>
  <div
    class="inline-flex w-full cursor-pointer items-center justify-between border border-b-0 border-solid border-gray-200 py-2 px-4 text-secondary first:rounded-t-lg last:border-b hover:bg-gray-100 hover:text-black md:py-4 md:px-6"
    :class="{
      'bg-white': isRead,
      'bg-gray-50': !isRead
    }"
    @click="read"
  >
    <div class="mr-2 flex w-full max-w-full text-sm">
      <icon
        size="12"
        name="envelopeOpenTextSolid"
        data-test="icon"
        class="pr-4 text-primary-600"
      />
      <notification-invites
        v-if="data.notification_item_collection === 'invites'"
        :data="data"
        class="self-center"
      />
      <notification-deals
        v-else-if="data.notification_item_collection === 'deals'"
        :data="data"
        class="self-center"
      />
      <notification-reviews
        v-else-if="data.notification_item_collection === 'reviews'"
        :data="data"
        class="self-center"
      />
      <notification-messages
        v-else-if="data.notification_item_collection === 'messages'"
        :data="data"
        class="self-center"
      />
      <notification-ads
        v-else-if="data.notification_item_collection === 'ads'"
        :data="data"
        class="self-center"
      />
      <notification-account
        v-else-if="data.notification_item_collection === 'account'"
        :data="data"
        class="self-center"
      />
      <span v-else>error</span>
    </div>
    <Close v-if="close" size="5" class="bg-inherit" @click="$emit('remove')" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import Icon from '@atoms/Icon'
import NotificationDeals from '@portal/PortalNotification/NotificationDeals'
import NotificationReviews from '@portal/PortalNotification/NotificationReviews'
import NotificationInvites from '@portal/PortalNotification/NotificationInvites'
import NotificationMessages from '@portal/PortalNotification/NotificationMessages'
import NotificationAds from '@portal/PortalNotification/NotificationAds'
import NotificationAccount from '@portal/PortalNotification/NotificationAccount'
import Close from '@atoms/Close'

export default defineComponent({
  name: 'PortalNotification',
  components: {
    Icon,
    NotificationDeals,
    NotificationReviews,
    NotificationInvites,
    NotificationMessages,
    NotificationAds,
    NotificationAccount,
    Close
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    close: {
      type: Boolean,
      default: true
    }
  },
  emits: ['remove', 'read'],
  setup(props, { emit }) {
    const isRead = ref(props.data.notification_is_watched === 1)
    const read = () => {
      isRead.value = true
      emit('read')
    }
    return {
      isRead,
      read
    }
  }
})
</script>
