import AbstractDB from '../AbstractDB'

export class Ads extends AbstractDB {
  constructor() {
    super('announcements')
  }

  public readAll() {
    return this.get('')
  }

  public readAllActive() {
    return this.get('all/active')
  }

  public readAllAttrs() {
    return this.get('attributes/all')
  }

  public readAllForAds() {
    return this.get('all/attributes')
  }

  public readAllVals() {
    return this.get('nannies/attributes')
  }

  public getAd(id: string) {
    return this.get(`${id}`)
  }

  public getAdsAttrs(id: string) {
    return this.get(`attributes/${id}`)
  }

  public getAdsKids(id: string) {
    return this.get(`babies/${id}`)
  }

  public updateData(id: string, data: Record<string, unknown>) {
    return this.patch(`${id}`, data)
  }
  public updateAttrs(id: string, data: Record<string, unknown>) {
    return this.patch(`attributes/${id}`, data)
  }

  public updateStatus(id: string, data: Record<string, unknown>) {
    return this.patch(`status/${id}`, data)
  }

  public updateKids(id: string, data: Record<string, unknown>) {
    return this.patch(`babies/${id}`, data)
  }

  public addAd(data: Record<string, unknown>) {
    return this.post('', data)
  }
  public deleteAd(id: string) {
    return this.delete(`${id}`)
  }
}

const apiAds = new Ads()
export default apiAds
