<template>
  <div class="w-full">
    <span
      v-if="
        data.notification_type === 'nanny-deal-invite' ||
        data.notification_type === 'nanny-deal-accept' ||
        data.notification_type === 'nanny-deal-reject'
      "
    >
      Niania
    </span>
    <span v-else>Rodzic</span>
    <span class="px-1 font-semibold">
      {{ data.notification_user_username }}
    </span>
    <span
      v-if="
        data.notification_type === 'nanny-deal-invite' ||
        data.notification_type === 'parent-deal-invite'
      "
    >
      zaprasza Cię do współpracy przy ogłoszeniu
    </span>
    <span
      v-else-if="
        data.notification_type === 'nanny-deal-accept' ||
        data.notification_type === 'parent-deal-accept'
      "
    >
      akceptuje Twoją propozycję współpracy w ramach ogłoszenia
    </span>
    <span v-else>
      odrzuca Twoją propozycję współpracy w ramach ogłoszenia
    </span>
    <span class="pl-1 font-semibold">
      {{ data.notification_item_title }}
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotificationDeals',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
})
</script>
