<template>
  <div
    class="grid"
    :class="{
      'grid-flow-row auto-rows-auto gap-3': template === 'vertical',
      'auto-cols-max grid-flow-col gap-3': template === 'horizontal',
      'grid-flow-row auto-rows-auto gap-2':
        template === 'radio' || template === 'checkbox' || template === 'toggle'
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'

export default defineComponent({
  name: 'FormFieldAtom',
  props: {
    name: {
      type: String,
      default: '',
      required: true
    },
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v)
    },
    template: {
      type: String,
      default: 'vertical',
      validator: (v: string) =>
        ['horizontal', 'vertical', 'checkbox', 'radio', 'toggle'].includes(v)
    },
    v: {
      type: Object,
      default: () => ({
        $invalid: false,
        $error: false,
        $touch: () => {
          return false
        }
      })
    }
  },
  setup(props) {
    provide('name', props.name)
    provide('size', props.size)
    provide('v', props.v)
  }
})
</script>
