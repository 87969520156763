<template>
  <router-link v-slot="{ href, navigate, isActive }" custom :to="item.route">
    <a
      class="relative flex items-center border-l-3 border-transparent px-3.5 font-bold hover:bg-gray-100 hover:text-warning-600"
      :class="[
        isActive ? 'border-warning-600 bg-gray-100 text-warning-600' : '',
        size === 'sm' ? 'py-2.5 text-sm' : 'py-3'
      ]"
      :href="href"
      @click="navigate"
    >
      <icon v-if="item.icon" :name="item.icon" size="4" class="mr-2.5" />
      {{ item.title }}

      <icon name="angleRightSolid" size="3" class="absolute right-2.5 mr-2.5" />
    </a>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'

export default defineComponent({
  components: { Icon },
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['sm', 'md'].includes(v)
    },
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: [String, Boolean],
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  }
})
</script>
