import LayoutDefault from '../layouts/LayoutBase.vue'
import LayoutFilter from '../layouts/LayoutFilter.vue'

const routes = {
  path: 'jobs',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Znajdź pracę'
      },
      components: {
        default: LayoutFilter
      },
      children: [
        {
          path: '',
          name: 'jobs',
          meta: {
            title: 'Ogłoszenie',
            forLogged: true,
            forNanny: true,
            forParent: false
          },
          component: () => import('../views/jobs/JobsIndex.vue')
        },
        {
          path: 'announcment/:adId',
          name: 'jobsAd',
          meta: {
            title: 'Ogłoszenie'
          },
          component: () => import('../views/jobs/JobsAd.vue')
        },
        {
          path: 'apply/:adId',
          name: 'jobsApply',
          meta: {
            title: 'Ogłoszenie',
            forPremium: true,
            forLogged: true,
            forNanny: true,
            forParent: false
          },
          component: () => import('../views/jobs/JobsApply.vue')
        },
        {
          path: 'profile/:parentID',
          name: 'profile',
          meta: {
            title: 'Profil rodzica',
            forLogged: true,
            forNanny: true,
            forParent: false
          },
          component: () => import('../views/jobs/JobsProfile.vue')
        },
        {
          path: 'profile/:parentID/kid/:kidID',
          name: 'profileKid',
          component: () => import('../views/kids/KidProfile.vue')
        }
      ]
    }
  ]
}

export default routes
