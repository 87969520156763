export default {
  fields: {
    userName: 'Nazwa użytkownika',
    userPassword: 'Hasło',
    userPasswordRepeat: 'Powtórz hasło',
    userPasswordOld: 'Aktualne hasło',
    userEmail: 'Email',
    userFirstname: 'Imię',
    userLastname: 'Nazwisko',
    userGenderMale: 'Mężczyzna',
    userGenderFemale: 'Kobieta',
    userType: 'Typ konta'
  },
  buttons: {
    login: 'Zaloguj się',
    register: 'Załóż konto',
    recover: 'Przypomnij hasło',
    newpassword: 'Ustaw hasło'
  },
  validations: {
    userFirstname: {
      required: 'Podaj proszę jak masz na imię',
      minLength: 'Imię powinno składać się z co najmniej {minLength} znaków.',
      maxLength: 'Imię może składać się z najwyżej {minLength} znaków.'
    },
    userLastname: {
      required: 'Podaj proszę jak się nazywasz',
      minLength:
        'Nazwisko powinno składać się z co najmniej {minLength} znaków.',
      maxLength: 'Nazwisko może składać się z najwyżej {minLength} znaków.'
    },
    userPassword: {
      required: 'Podaj hasło',
      minLength: 'Hasło powinno składać się z co najmniej {minLength} znaków.',
      maxLength: 'Hasło może składać się z najwyżej {maxLength} znaków.',
      sameAs: 'Powtórz prawidłowo podane hasło.'
    },
    userPasswordRepeat: {
      required: 'Powtórzenie hasła jest wymagane',
      sameAs: 'Powtórz prawidłowo podane hasło.'
    },
    userPasswordOld: {
      info: 'To jest hasło, z którego aktualnie korzystasz.',
      required: 'Podaj hasło',
      minLength: 'Hasło powinno składać się z co najmniej {minLength} znaków.',
      maxLength: 'Hasło może składać się z najwyżej {maxLength} znaków.'
    },
    userEmail: {
      info: 'Podaj adres na który wyślemy mail z kluczem aktywacyjnym konta.',
      inforecover:
        'Wpisz adres email podany podczas rejestracji konta na który wyślemy mail z kluczem aktywacyjnym nowego hasła.',
      email: 'To nie jest poprawny email.',
      reserved: 'Podany adres email jest już wykorzystywany przez inne konto.',
      required: 'Podaj adres email.'
    },
    terms: {
      required: 'Musisz zaakceptować warunki korzystania.'
    }
  },
  alerts: {
    registerSuccess:
      'Konto zostało założone! Link do aktywacji Twojego konta został wysłany na wskazany adres email.',
    recoverSuccess:
      'Link do aktywacji nowego hasła został wysłany na Twój adres email.',
    wrongData: 'Wystąpił błąd odczytu wysłanych danych. Spróbuj jeszcze raz.',
    wrongPassword: 'Podaj poprawne hasło i nazwę użytkownika.',
    wrongUserName: 'Taki użytkownik nie istnieje.',
    wrongEmail: 'Taki adres email nie istnieje.',
    wrongStatus: 'Konto jest nieaktywne lub zostało zablokowane.',
    newpassSuccess:
      'Hasło zostało zmienione! Link do jego aktualizacji został wysłany na Twój adres email.',
    newpassExpired: 'Link aktywacyjny dla zmiany hasła wygasł.',
    wrongNewpass: 'Zmiana hasła nie powiodła się.',
    wrongOldpass: 'Podane aktualne hasło jest nieprawidłowe.',
    changeData: 'Dane zostały zmienione.',
    activationSuccess: 'Aktywacja konta zakończona sukcesem.',
    wrongActivation: 'Aktywacja konta zakończona niepowodzeniem.'
  },
  login: {
    title: 'Logowanie',
    info: {
      pre: 'Możesz zalogować się za pomocą portalu społecznościowego',
      postpre: 'danymi podanymi podczas rejestracji. Nie masz jeszcze konta?',
      url: '/auth/register',
      action: 'Zarejestruj się'
    },
    recover: {
      title: 'Nie pamiętasz swojego hasła?',
      url: '/auth/recover'
    }
  },
  register: {
    title: 'Nowe konto',
    info: {
      pre: 'Możesz zarejestrować się za pomocą portalu społecznościowego',
      postpre:
        'Wypełnij formularz rejestracji konta. Otrzymasz link do jego aktywacji. Masz już konto?',

      url: '/auth',
      action: 'Zaloguj się'
    },
    terms: {
      pre: 'Zgadzam się z ',
      url: '/',
      action: 'warunkami korzystania'
    }
  },
  recover: {
    title: 'Przypomnij hasło',
    info: {
      pre: 'Wpisz email, którego użyłaś(eś) do rejestracji konta. Otrzymasz link do ustawienie nowego hasła. Kliknęłaś(ąłeś) już w otrzymany link?',
      url: '/auth',
      action: 'Zaloguj się'
    }
  },
  activation: {
    title: 'Aktywacja konta',
    info: {
      pre: 'Twoje konto jest już aktywne!',
      action: 'Zaloguj się'
    }
  },
  newpassword: {
    title: 'Ustaw nowe hasło',
    info: {
      pre: 'Zmień teraz hasło, aby odzyskać dostęp do konta, a następnie',
      url: '/auth',
      action: 'zaloguj się'
    },
    recover: {
      msg: 'Prawdopodbnie upłyneło zbyt dużo czas od chwili gdy otrzymałeś link aktywacyjny, lub już został on już wykorzystany. Możesz ',
      title: 'wysłać nowy link aktywacyjny',
      url: '/auth/recover'
    }
  }
}
