<template>
  <div
    class="aside-menu relative flex h-screen w-[288px] flex-col overflow-y-auto bg-white px-0 pb-10"
  >
    <div class="flex h-16 flex-shrink-0 items-center px-4">
      <img
        class="mr-2 block h-12 w-auto cursor-pointer"
        src="@assets/logo.png"
        alt="Znana Niania"
      />
      <div>
        <span class="block text-2xl font-light text-secondary-500">
          znana <span class="font-bold">niania</span>
        </span>
        <div v-if="isLogged" class="text-xs font-light">
          {{ isParent ? $t('sidebar.parent') : $t('sidebar.nanny') }}
        </div>
      </div>
    </div>
    <div
      v-if="isLogged"
      class="mt-2 flex justify-between border-t border-b border-solid border-gray-200 bg-gray-100 px-4 py-2 text-slate-700"
    >
      <div class="text-sm font-bold">
        {{ username }}
      </div>
      <router-link class="text-sm font-bold text-warning-600" to="/auth/logout">
        {{ $t('sidebar.logout') }}
      </router-link>
    </div>
    <div
      v-for="(section, ids) in sections"
      :key="ids"
      class="mt-2 text-slate-700"
    >
      <portal-menu-label>{{ section.title }}</portal-menu-label>
      <portal-menu-item
        v-for="(item, idx) in section.arr"
        :key="idx"
        :item="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAuthStore } from '@stores/auth'
import PortalMenuItem from '@portal/PortalMenuItem'
import PortalMenuLabel from '@portal/PortalMenuLabel'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'PortalSidebar',
  components: {
    PortalMenuItem,
    PortalMenuLabel
  },
  setup() {
    const { t } = useI18n()
    const useAuth = useAuthStore()
    const isLogged = useAuth.isAuthenticated
    const isParent = useAuth.isParent
    const username = useAuth.getUserFullname
    let list = []
    if (isLogged) {
      if (isParent) {
        list = [
          {
            title: t('sidebar.profil'),
            route: { name: 'profil' },
            icon: 'user'
          },
          {
            title: t('sidebar.findnanny'),
            route: { name: 'nannies' },
            icon: 'searchSolid'
          }
        ]
      } else {
        list = [
          {
            title: t('sidebar.profil'),
            route: { name: 'profil' },
            icon: 'user'
          },
          {
            title: t('sidebar.findwork'),
            route: { name: 'jobs' },
            icon: 'searchSolid'
          }
        ]
      }
    } else {
      list = [
        {
          title: t('sidebar.forparent'),
          route: { name: 'forparent' },
          icon: false
        },
        {
          title: t('sidebar.fornanny'),
          route: { name: 'fornanny' },
          icon: false
        }
      ]
    }

    const sections = [{ title: t('sidebar.sections.main'), arr: list }]

    if (isLogged) {
      let settings = []
      let activity = []
      if (isParent) {
        settings = [
          {
            title: t('sidebar.settings.account'),
            route: { name: 'account' },
            icon: 'user'
          },
          {
            title: t('sidebar.settings.kids'),
            route: { name: 'kids' },
            icon: 'babySolid'
          },
          {
            title: t('sidebar.settings.filters'),
            route: { name: 'filters' },
            icon: 'slidersHSolid'
          }
        ]
        activity = [
          {
            title: t('sidebar.activity.myads'),
            route: { name: 'announcments' },
            icon: 'mapMarkedAltSolid'
          },
          {
            title: t('sidebar.activity.cooperations'),
            route: { name: 'deals' },
            icon: 'handshakeSolid'
          },
          {
            title: t('sidebar.activity.inbox'),
            route: { name: 'inbox' },
            icon: 'comments'
          },
          {
            title: t('sidebar.activity.notifications'),
            route: { name: 'notifications' },
            icon: 'bell'
          },
          {
            title: t('sidebar.activity.offers'),
            route: { name: 'offers' },
            icon: 'folderPlusSolid'
          },
          {
            title: t('sidebar.activity.favnannies'),
            route: { name: 'likes' },
            icon: 'thumbsUp'
          }
        ]
      } else {
        settings = [
          {
            title: t('sidebar.settings.account'),
            route: { name: 'account' },
            icon: 'user'
          },
          {
            title: t('sidebar.settings.attrs'),
            route: { name: 'attributes' },
            icon: 'userTagSolid'
          },
          {
            title: t('sidebar.settings.availability'),
            route: { name: 'worktime' },
            icon: 'calendarAltSolid'
          },
          {
            title: t('sidebar.settings.filters'),
            route: { name: 'filters' },
            icon: 'slidersHSolid'
          }
        ]
        activity = [
          {
            title: t('sidebar.activity.cooperations'),
            route: { name: 'deals' },
            icon: 'handshakeSolid'
          },
          {
            title: t('sidebar.activity.inbox'),
            route: { name: 'inbox' },
            icon: 'comments'
          },
          {
            title: t('sidebar.activity.notifications'),
            route: { name: 'notifications' },
            icon: 'bell'
          },
          {
            title: t('sidebar.activity.offers'),
            route: { name: 'offers' },
            icon: 'folderPlusSolid'
          }
        ]
      }
      sections.push({ title: t('sidebar.sections.settings'), arr: settings })
      sections.push({ title: t('sidebar.sections.activity'), arr: activity })
    }

    return {
      sections,
      isParent,
      isLogged,
      username
    }
  }
})
</script>

<style scoped>
.aside-menu::-webkit-scrollbar {
  position: absolute;
  width: 6px;
}
.aside-menu::-webkit-scrollbar-thumb {
  background-color: rgb(214, 211, 209, 1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
</style>
