export default {
  superscription: 'Ustawienia',
  title: 'Moje dzieci',
  subtitle: 'dodane profile dzieci',
  descriptionPt1:
    'Ogłoszenia o poszukiwaniu niani zawsze przypisane są do dziecka. ',
  descriptionLink: 'Dowiedz się więcej ',
  descriptionPt2:
    'o tym dlaczego to takie ważne i jak dbamy o bezpieczeństwo na naszym portalu. ',
  birthdayGirl: 'dziewczynka, data urodzenia:',
  birthdayBoy: 'chłopiec, data urodzenia:',
  addNew: {
    title: 'Dodaj profil dziecka',
    description:
      'Dodanie profilu dziecka, który zawiera tylko podstawowe dane, jest niezbędne aby utworzyć ogłoszenie.',
    btnText: 'Dodaj profil dziecka'
  },
  noKids: 'Brak uzupełnionych profili dzieci',
  add: {
    title: 'Dodaj profil dziecka',
    description:
      'Dodaj profil swojego dziecka. Po dodaniu można go uzupełnić o dodatkowe informacje.',
    superscription: 'Moje dzieci',
    button: 'Dodaj profil dziecka',
    typeKidName: 'Podaj imię dziecka:',
    namePlaceholder: 'Imię dziecka',
    nameRequired: 'Wpisz imię dziecka',
    male: 'chłopiec',
    female: 'dziewczynka',
    kidGender: 'Płeć dziecka:',
    genderRequired: 'Wybierz płeć dziecka',
    birthday: 'Data urodzenia:',
    birthdayRequired: 'Wybierz datę urodzenia',
    birthdayValid: 'Wpisz poprawną datę urodzenia'
  },
  delete: {
    title: 'Usuwanie profil dziecka',
    superscription: 'Moje dzieci',
    description: 'Czy na pewno chcesz usunąć profil dziecka',
    btnConfirm: 'Tak, usuń'
  },
  update: {
    noPermissions: 'Nie masz uprawnień do edycji profilu dziecka.',
    superscription: 'Moje dzieci',
    birthday: 'data urodzenia',
    male: 'chłopiec',
    female: 'dziewczynka',
    profileSubheading: 'Dane profilowe dziecka',
    typeKidName: 'Podaj imię dziecka:',
    required: 'To pole musi mieć zdefiniowaną wartość',
    typeBirthday: 'Data urodzenia:',
    typeGender: 'Płeć dziecka:',
    dateRequiredFormat: 'Wymagany format to YYYY-MM-DD',
    kidProfileStatus: 'Status profilu dziecka:',
    kidDescription: 'Opis dziecka:',
    saveProfileBtn: 'Zapisz dane profilu',
    toastProfileSuccess: {
      title: 'Sukces!',
      msg: 'Profil został zaktualizowany'
    },
    toastProfileError: {
      title: 'Coś poszło nie tak',
      msg: 'Wystąpił błąd podczas aktualizacji profilu'
    },
    attributesSubheading: 'Zdefiniuj cechy dziecka',
    saveAttributesBtn: 'Zapisz cechy profilu dziecka',
    toastAttributesSuccess: {
      title: 'Sukces!',
      msg: 'Cechy profilu dziecka zostały zaktualizowane'
    },
    toastAttributesError: {
      title: 'Coś poszło nie tak',
      msg: 'Wystąpił błąd podczas aktualizacji cech profilu dziecka'
    }
  }
}
